import React, { useEffect, useReducer } from 'react';
import ReactApexChart from 'react-apexcharts';
import { invertedBondYieldHistory } from '../../../../common/api/search';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        series: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const InvertedBond = (props) => {
  const [{ loading, series }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const { timeline } = props;

  const options = {
    chart: {
      id: 'line-datetime',
      type: 'line',
      height: 350,
      width: '100%',
      zoom: {
        autoScaleYaxis: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: series?.tradingdate.map((item) => {
        const newItem = new Date(item);
        newItem.setDate(newItem.getDate() + 1);
        return newItem.getTime();
      }),
      tickAmount: 14,
      type: 'datetime',
    },
    yaxis: [
      {
        labels: {
          formatter: function (value) {
            return value.toFixed(2);
          }
        }
      },
      {
        opposite: true,
        labels: {
          formatter: function (value) {
            return value.toFixed(2);
          }
        }
      },
      
    ],
    stroke: {
      width: 2,
      curve: 'straight',
    },
    colors: ['#2B7FFF', '#EA9127'],
  };

  const seriesData = [
    {
      name: 'VNINDEX',
      data: series?.VNINDEX,
      type: 'line',
    },
    {
        name: 'VN10Y',
        data: series?.VN10Y,
        type: 'line',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await invertedBondYieldHistory(timeline, 'line');
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: res.data,
        });
      } catch (err) {}
    };
    fetchData();
  }, [timeline]);

  function formatdate(inputDateStr) {
    if(!inputDateStr) return '';
    var inputDate = new Date(inputDateStr);
    var year = inputDate.getFullYear();
    var month = inputDate.getMonth() + 1;
    var day = inputDate.getDate();
    return (day < 10 ? '0' : '') + day + '-' + (month < 10 ? '0' : '') + month + '-' + year;
  }

  return (
    <div className="xl:w-1/3">
      <div className='text-lg font-semibold my-2 text-center'>Đường lãi suất trái phiếu đảo ngược ({ formatdate(series?.tradingdate[series?.tradingdate.length - 1]) })</div>
      <div id="chart" className="w-full">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div id="chart-timeline" className="w-full">
            <ReactApexChart
              options={options}
              series={seriesData}
              type="line"
              height={370}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default InvertedBond;
