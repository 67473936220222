import { connect } from "react-redux";
import BarCharts from "../../../recharts/BarCharts";

const OverView = (props) => {
  return(
    <div className="w-full Overview">
      <div className="">
        <div className="bottom-border pb-6  ">
          <span className="font-bold text-base">
            Top cổ phiếu có ảnh hưởng tới chỉ số index
          </span>
        </div>
        <div className="mt-8">
          <BarCharts />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return{}
}

export default connect(mapStateToProps, {

})(OverView)