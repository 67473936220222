import { createSlice } from '@reduxjs/toolkit';
import { registerUser, userLogin, userLogout, userLoginWithGoogle } from './authAction';

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {
	loading: false,
	userInfo, // for user object
	userToken, // for storing the JWT
	error: null,
	success: false, // for monitoring the registration process.
};


const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {},
	extraReducers: {
		// register user
		[registerUser.pending]: (state) => {
			state.loading = true;
			state.error = null;
		},
		[registerUser.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.success = true; // registration successful
			state.userInfo = payload.userInfo;
			state.userToken = payload.userToken;
			state.error = null;
		},
		[registerUser.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},
		// login user
		[userLogin.pending]: (state) => {
			state.loading = true;
			state.error = null;
		},
		[userLogin.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.userInfo = payload.userInfo;
			state.userToken = payload.userToken;
			state.error = null;
			state.success = true;
		},
		[userLogin.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},
		// user Logout
		[userLogout.fulfilled]: (state) => {
			//reset initial state
			state.loading = false;
			state.userInfo = null;
			state.userToken = null;
			state.error = null;
			state.success = false;
		},
		// login with google:
		[userLoginWithGoogle.pending]: (state) => {
			state.loading = true;
			state.error = null;
		},
		[userLoginWithGoogle.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.userInfo = payload.userInfo;
			state.userToken = payload.userToken;
			state.error = null;
			state.success = true;
		},
		[userLoginWithGoogle.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},
	},
});

export default authSlice.reducer;
