import { useOutletContext, useSearchParams } from 'react-router-dom';
import PB from '../../recharts/tools/PB';
import PE from '../../recharts/tools/PE';
import '../Overview.css';
const MainAnalysis = () => {
  const [tickerDefault] = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const ticker = searchParams.get('ticker') ?? tickerDefault;
  return (
    <div className="px-4 w-full lg:px-20 xl:px-0">
      <div className="w-full xl:w-2/3">
        <div className="min-[450px]:flex justify-between my-12">
          <div className="title-chart"> Phân tích chính </div>
        </div>
      </div>
      <div className="xl:flex justify-between">
        <div className="w-full xl:w-1/2 xl:mr-5">
          <div className="text-base font-extrabold">P/E</div>
          <div>
            <PE context={[ticker]} />
          </div>
        </div>
        <div className="w-full xl:w-1/2 xl:ml-5 mt-4 xl:mt-0">
          <div className="text-base font-extrabold">P/B</div>
          <div>
            <PB context={[ticker]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainAnalysis;
