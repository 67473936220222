import { useState, useEffect } from "react";
import { AiOutlineDownload, AiOutlineClose, AiFillCloseCircle, AiFillSave, AiFillStar } from "react-icons/ai";
import { Select, Space } from 'antd';
import { BiSolidFilterAlt, BiRefresh } from "react-icons/bi";
import { Input, Tabs } from 'antd';
import AddConditionModal from "./components/AddConditionModal";
import PopoverButton from "./components/PopoverButton";
import ResultTable from "./components/ResultTable";
import AddToCategoryModal from "./components/AddToCategoryModal";

const FilterPage = (props) => {
    const listSampleFilter = [
        'Lợi thế cạnh tranh cao',
        'DN - 6 chỉ số quan trọng',
        'Bank - 7 chỉ số quan trọng',
        'Tiền đè chết người',
        'Xác chết sống dậy',
        'Cổ tức cao',
        'Dòng tiền mạnh',
        'Xây dựng nhà máy dự án mới'
    ]

    const addSampleFilter = (el, index) => {
        return (
            <div className="flex px-4 py-[10px] cursor-pointer justify-between" key={index}>
                <div className="text-[13px]">{el}</div>
                <PopoverButton />
                {/* <div className=""><BsThreeDotsVertical /></div> */}
            </div>
        )
    }

    const items = [
        {
            key: '1',
            label: 'Kết quả',
            children: <ResultTable />,
        },
        {
            key: '2',
            label: 'Thống kê',
            children: 'Content of Tab Pane 2',
        },
        {
            key: '3',
            label: 'Cơ bản',
            children: 'Content of Tab Pane 3',
        },
    ];

    const OperationsSlot = {
        right:
            <div className="flex gap-4">
                <AddToCategoryModal />
                <div className="flex gap-2 px-4 py-3 bg-[#1AAF76] text-white font-bold rounded cursor-pointer">
                    <AiOutlineDownload size={24} />
                    Xuất dữ liệu
                </div>
            </div>,
    };

    const onChangeTabs = (key) => {
        console.log(key);
    };

    return (
        <div className="filter-page bg-[#F6FAFF] p-10">
            <div className="container mx-auto">
                <h1 className="text-[32px] font-extrabold">Bộ lọc cổ phiếu </h1>
                <div className="mt-6 flex bg-white border-0 rounded shadow-lg">
                    <div className="border-r-[1px]">
                        <div className="flex p-4 gap-4">
                            <div className="border px-4 py-3 rounded cursor-pointer">Bộ lọc đã lưu</div>
                            <div className="border px-4 py-3 rounded font-bold bg-[#1AAF76] text-white cursor-pointer">Bộ lọc mẫu</div>
                        </div>
                        <div className="px-4 py-[10px] font-bold">
                            Bộ lọc mẫu
                        </div>
                        <div className="">
                            {listSampleFilter.map((el, index) => {
                                return addSampleFilter(el, index)
                            })}
                        </div>
                    </div>
                    <div className="flex flex-col p-4 gap-4 flex-auto">
                        <div className="flex justify-between">
                            <div className="text-[24px] font-bold">
                                Điều kiện lọc
                            </div>
                            {/* <div className="flex items-center px-4 py-3 gap-2 bg-[#1AAF76] text-white rounded cursor-pointer">
                                <AiOutlinePlus size={24}/>
                                <span className="font-bold">Thêm điều kiện</span>
                            </div> */}
                            <AddConditionModal />
                        </div>
                        <div className="list-filter flex flex-col gap-4">
                            {/* Niêm yết sàn item */}
                            <div className="flex gap-4 h-14 items-center h-[56px]">
                                <div className="flex rounded bg-[#F6FAFF] h-full flex-auto items-center px-4 gap-4">
                                    <div className="min-w-[280px]">Niêm yết trên sàn:</div>
                                    <div className="flex flex-auto gap-2">
                                        {
                                            ['HOSE', 'HNX', 'UPCOM'].map((el, index) => {
                                                return <div className="flex gap-2 items-center px-2 py-1 rounded" style={{ "background": "rgba(26, 175, 118, 0.15)" }} key={index}>
                                                    {el}
                                                    <AiFillCloseCircle className="cursor-pointer" size={18} />
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className="">1690 mã</div>
                                </div>
                                <div className="text-red cursor-pointer"><AiOutlineClose size={24} /></div>
                            </div>
                            {/* Giá hiện tại cắt lên đường trung bình: */}
                            <div className="flex gap-4 h-14 items-center h-[56px]">
                                <div className="flex rounded bg-[#F6FAFF] h-full flex-auto items-center px-4 gap-4">
                                    <div className="min-w-[280px]">Giá hiện tại cắt lên đường trung bình:</div>
                                    <div className="flex-auto">
                                        <Select
                                            defaultValue="SMA(5)"
                                            style={{ width: 180 }}
                                            size={'large'}
                                            options={[
                                                { value: 'SMA(1)', label: 'SMA(1)' },
                                                { value: 'SMA(2)', label: 'SMA(2)' },
                                                { value: 'SMA(3)', label: 'SMA(3)' },
                                                { value: 'SMA(4)', label: 'SMA(4)' },
                                                { value: 'SMA(5)', label: 'SMA(5)' },
                                            ]}
                                        />
                                    </div>
                                    <div className="">155 mã</div>
                                </div>
                                <div className="text-red cursor-pointer"><AiOutlineClose size={24} /></div>
                            </div>
                            {/* P/E */}
                            <div className="flex gap-4 h-14 items-center h-[56px]">
                                <div className="flex rounded bg-[#F6FAFF] h-full flex-auto items-center px-4 gap-4">
                                    <div className="min-w-[280px]">P/E:</div>
                                    <div className="flex-auto">
                                        <Select
                                            defaultValue="10"
                                            style={{ width: 180 }}
                                            size={'large'}
                                            options={[
                                                { value: '10', label: '10' },
                                                { value: '20', label: '20' },
                                                { value: '30', label: '30' },
                                                { value: '40', label: '40' },
                                                { value: '50', label: '50' },
                                                { value: '60', label: '60' },
                                            ]}
                                        />
                                    </div>
                                    <div className="">549 mã</div>
                                </div>
                                <div className="text-red cursor-pointer"><AiOutlineClose size={24} /></div>
                            </div>
                            {/* Thị giá vốn */}
                            <div className="flex gap-4 h-14 items-center h-[56px]">
                                <div className="flex rounded bg-[#F6FAFF] h-full flex-auto items-center px-4 gap-4">
                                    <div className="min-w-[280px]">P/E:</div>
                                    <div className="flex-auto flex gap-2 items-center">
                                        <Select
                                            defaultValue="10"
                                            style={{ width: 180 }}
                                            size={'large'}
                                            options={[
                                                { value: '10', label: '10' },
                                                { value: '20', label: '20' },
                                                { value: '30', label: '30' },
                                                { value: '40', label: '40' },
                                                { value: '50', label: '50' },
                                                { value: '60', label: '60' },
                                            ]}
                                        />
                                        -
                                        <Select
                                            defaultValue="10"
                                            style={{ width: 180 }}
                                            size={'large'}
                                            options={[
                                                { value: '10', label: '10' },
                                                { value: '20', label: '20' },
                                                { value: '30', label: '30' },
                                                { value: '40', label: '40' },
                                                { value: '50', label: '50' },
                                                { value: '60', label: '60' },
                                            ]}
                                        />
                                    </div>
                                    <div className="">549 mã</div>
                                </div>
                                <div className="text-red cursor-pointer"><AiOutlineClose size={24} /></div>
                            </div>
                            {/* KLTB 5 phiên */}
                            <div className="flex gap-4 h-14 items-center h-[56px]">
                                <div className="flex rounded bg-[#F6FAFF] h-full flex-auto items-center px-4 gap-4">
                                    <div className="min-w-[280px]">KLTB 5 phiên:</div>
                                    <div className="flex-auto">
                                        <Select
                                            defaultValue="100000"
                                            style={{ width: 180 }}
                                            size={'large'}
                                            options={[
                                                { value: '100000', label: '100000' },
                                                { value: '200000', label: '200000' },
                                                { value: '300000', label: '300000' },
                                                { value: '400000', label: '400000' },
                                                { value: '500000', label: '500000' },
                                                { value: '600000', label: '600000' },
                                            ]}
                                        />
                                    </div>
                                    <div className="">549 mã</div>
                                </div>
                                <div className="text-red cursor-pointer"><AiOutlineClose size={24} /></div>
                            </div>
                        </div>
                        <div className="filter-action flex justify-between">
                            <div className="flex items-center gap-4">
                                <div className=""><Input placeholder="Nhập tên bộ lọc muốn lưu" size="large" /></div>
                                <div className="px-4 py-3 border cursor-pointer flex font-bold gap-2 text-white bg-[#1AAF76] rounded">
                                    <AiFillSave size={24} />
                                    Lưu bộ loc
                                </div>
                            </div>
                            <div className="flex gap-4">
                                <div className="px-4 py-3 border cursor-pointer flex font-bold gap-2 rounded">
                                    <BiRefresh size={24} />
                                    Đặt lại
                                </div>
                                <div className="px-4 py-3 border cursor-pointer flex font-bold gap-2 text-white bg-[#2B7FFF] rounded">
                                    <BiSolidFilterAlt size={24} />
                                    Lọc dữ liệu
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-10">
                    <div className="flex justify-between">
                        <div className="flex w-full">
                            <Tabs tabBarExtraContent={OperationsSlot.right} defaultActiveKey="1" items={items} onChange={onChangeTabs} className="w-full" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterPage;