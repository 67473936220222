import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ColumnChartMonth = (props) => {
  const { data } = props;
  let chartData = [];
  let dates = [];
  for (let i = 0; i < data.length; i++) {
    chartData = [
      ...chartData,
      data[i].trading_value !== 0 ? data[i].trading_value : 0.0001,
    ];
    let d = data[i].tradingdate.slice(3, 10);
    dates = [...dates, d];
  }
  const series = [
    {
      name: 'Giá trị giao dịch',
      data: chartData,
    },
  ];
  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: -900000000000,
              to: 0,
              color: '#d72f2f',
            },
            {
              from: 0,
              to: 900000000000,
              color: '#1aaf76',
            },
          ],
        },
        borderRadius: 4,
        columnWidth: '95%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      opposite: true,
      crosshairs: {
        show: false,
      },
      labels: {
        formatter: function (val) {
          return (val >= 1 || val <= -1) ? val.toFixed(2) + 'T' : (val*1000).toFixed(0) + ' triệu';
        },
      },
    },
    xaxis: {
      labels: {
        show: true,
        formatter: function (val) {
          if (typeof val === 'string') {
            val = val.slice(0, 2);
            if (val[0] === '0') val = val.slice(1, 2);
            return 'T' + val;
          } else {
            return 'T' + (val + 1);
          }
        },
      },
      categories: dates,
    },
    grid: {
      show: false,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      x: {
        formatter: function (val) {
          return 'T' + val;
        },
      },
    },
  };

  return (
    <div id="chart" className="w-full">
      {false ? (
        <div>Loading...</div>
      ) : (
        <div id="chart-column" className="w-full">
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={150}
            width="100%"
          />
        </div>
      )}
    </div>
  );
};
export default ColumnChartMonth;
