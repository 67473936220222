import { callApiget } from "../../../common/fetch"
import { baseURL2 } from '../../../common/const';

// const server_url = process.env.REACT_APP_API_URL
const server_url = baseURL2 +'/'

export const getLogoTop = (tickers , type) => {
  return dispatch => {
    let url = server_url + `stock/stock_logo`

    const params = new URLSearchParams()
  
    params.append('tickers', tickers)
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: type,
          payload: res.data.data
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}

export const getTopStockBuyProp = (mode) => {
  return dispatch => {
    let url = server_url + `analytics/top_stock_buy_prop/` + mode

    const params = new URLSearchParams()
  
    params.append('k', 10)
    params.append('volume', 100000)
    params.append('capital', 1)
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-TOP-PROP-BUY',
          payload: res.data.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}

export const getTopStockSellProp = (mode) => {
  return dispatch => {
    let url = server_url + `analytics/top_stock_sell_prop/` + mode

    const params = new URLSearchParams()
  
    params.append('k', 10)
    params.append('volume', 100000)
    params.append('capital', 1)
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-TOP-PROP-SELL',
          payload: res.data.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}

export const getTopStockBuyFund = (mode) => {
  return dispatch => {
    let url = server_url + `analytics/top_stock_buy_fund_by_vol/` + mode

    const params = new URLSearchParams()
  
    params.append('k', 10)
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-TOP-FUND-BUY',
          payload: res.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}

export const getTopStockSellFund = (mode) => {
  return dispatch => {
    let url = server_url + `analytics/top_stock_sell_fund_by_vol/` + mode

    const params = new URLSearchParams()
  
    params.append('k', 10)
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-TOP-FUND-SELL',
          payload: res.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}