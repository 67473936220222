import axios from 'axios';
import { baseURL2 } from '../const';

const axiosInstance = axios.create({
  baseURL: baseURL2,
});
export default axiosInstance;

export async function autocompleteSearch(query) {
  try {
    const res = await axiosInstance.get(
      `/search/autocomplete_search?query=${query}&k=10`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function rrgStatisticsByStocks(rrg, time, win, mode = 'daily') {
  try {
    const res = await axiosInstance.get(
      `statistics/rrg_statistics_by_stocks?stocks=${rrg}&window=${win}&timeframe=${time}&type=line&is_default=true&mode=${mode}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function rrgStatisticsBySector(rrg, time, win, mode = 'daily') {
  try {
    const res = await axiosInstance.get(
      `statistics/rrg_statistics_by_sector?sector_ids=${rrg}&window=${win}&timeframe=${time}&level=3&type=line&is_default=true&mode=${mode}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function autocompleteSearchSector(query) {
  try {
    const res = await axiosInstance.get(
      `/search/autocomplete_search_sector?query=${query}&k=10`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function sectorInfoById(id) {
  try {
    const res = await axiosInstance.get(
      `/sector/sector_info_by_id?sector_id=${id}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function vnindexRsiHistory(mode, type_output) {
  try {
    const res = await axiosInstance.get(
      `/market/vnindex_rsi_history?mode=${mode}&type_output=${type_output}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function ma200PctHistory(mode, type_output) {
  try {
    const res = await axiosInstance.get(
      `/market/ma200_pct_history?mode=${mode}&type_output=${type_output}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function invertedBondYieldHistory(mode, type_output) {
  try {
    const res = await axiosInstance.get(
      `/market/inverted_bond_yield_history?mode=${mode}&type_output=${type_output}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function topSectorsByStatistics(level, top) {
  try {
    const res = await axiosInstance.get(
      `/statistics/top_sectors_by_statistics?level=${level}&top=${top}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function topStocksByStatistics(top) {
  try {
    const res = await axiosInstance.get(
      `/statistics/top_stocks_by_statistics?top=${top}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}