import { useState } from 'react';
import ChangePrice from './components/PriceMovements/ChangePrice';
import ChangePriceVNindex from './components/PriceMovements/ChangePriceVNindex';
import InvertedBond from './components/PriceMovements/InvertedBond';

const PriceMovements = () => {
  const [modeChangePrice, setModeChangePrice] = useState('all');
  return (
    <>
      <div className="w-full xl:flex lg:px-20 xl:px-0 min-h-[350px]">
        <div className="w-full">
          <div className="mb-4">
            <div className="title-chart mb-4">Xu hướng thị trường</div>
            <div className="flex">
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === '1m'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('1m')}
              >
                1M
              </div>
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === '3m'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('3m')}
              >
                3M
              </div>
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === '6m'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('6m')}
              >
                6M
              </div>
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === '1y'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('1y')}
              >
                1Y
              </div>
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === '3y'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('3y')}
              >
                3Y
              </div>
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === 'all'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('all')}
              >
                ALL
              </div>
            </div>
          </div>
          <div className='xl:flex'>
            <ChangePrice timeline={modeChangePrice} />
            <InvertedBond timeline={modeChangePrice} />
            <ChangePriceVNindex timeline={modeChangePrice} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceMovements;
