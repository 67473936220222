import React from 'react';
import { TechnicalAnalysis } from 'react-tradingview-embed';

const Performance = (props) => {
  const data = [
    {label: '1W', value: 1.46},
    {label: '1M', value: 2.36},
    {label: '3M', value: 23.36},
    {label: '6M', value: 46.36},
    {label: 'YDT', value: 75.36},
    {label: '1Y', value: 16.36},
  ]
  return (
    <div>
        <div className='font-bold text-center text-md mb-2'>Performance</div>
        <div className='grid gap-4 grid-cols-2'>
          {data.map((item, index) => (
            <div key={index} className='text-center rounded-md shadow items-center py-4 bg-green-100'>
              <div className='text-green-600 font-extrabold text-xl'>{item.value}%</div>
              <div className="text-md font-bold">{item.label}</div>
            </div>
          ))}
        </div>
    </div>
  )
}

export default Performance
