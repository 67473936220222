import { useEffect, useReducer, useState } from 'react';
import '../Overview.css';
import {
  dividendHistory,
  foreignTradeVolume,
  holderTradingVolume,
  propTradingVolume,
  stockOrderDetails,
  stockOrderStatistics,
  stockValuation,
} from '../../../common/api/tools';
import ColumnChart from '../../recharts/tools/ColumnChart';
import ColumnChartMonth from '../../recharts/tools/ColumnChartMonth';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import PriceDetails from './PriceDetails';
import PieChart from './PieChart';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        stockInfo: action.payload[0],
        valuation: action.payload[1],
        chart1: action.payload[2],
        chart2: action.payload[3],
        chart3: action.payload[4],
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'FETCH_REQUEST_STATISTIC':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS_STATISTIC':
      return {
        ...state,
        statistic: action.payload[0],
        stockDetail: action.payload[1],
        loading: false,
      };
    case 'FETCH_FAIL_STATISTIC':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const TransactionInformation = () => {
  const [{ loading, stockInfo, valuation, chart1, chart2, chart3, statistic, stockDetail }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: '',
    });
  const [tickerDefault] = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const ticker = searchParams.get('ticker') ?? tickerDefault;
  const [selectedModeDetail, setSelectedModeDetail] = useState('all');
  const [date, setDate] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const [dividendRes, valuationRes, chart1Res, chart2Res, chart3Res] = await Promise.all([
          dividendHistory(ticker),
          stockValuation(ticker),
          propTradingVolume(ticker),
          foreignTradeVolume(ticker),
          holderTradingVolume(ticker),
        ]);

        const dividendData = dividendRes?.data || [];
        const valuationData = valuationRes?.data || [];
        const chart1Data = chart1Res?.data || [];
        const chart2Data = chart2Res?.data || [];
        const chart3Data = chart3Res?.data || [];

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: [dividendData, valuationData, chart1Data, chart2Data, chart3Data],
        });
      } catch (err) {
      }
    };

    fetchData();
  }, [ticker]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST_STATISTIC' });
      try {
        const [statisticRes, stockDetailRes] = await Promise.all([
          stockOrderStatistics(ticker, date),
          stockOrderDetails(ticker, date),

        ]);
        const statistic = statisticRes?.data || [];
        const stockDetail = stockDetailRes?.data || [];
        dispatch({
          type: 'FETCH_SUCCESS_STATISTIC',
          payload: [statistic, stockDetail],
        });
      } catch (err) {
      }
    };

    fetchData();
  }, [ticker, date]);

  function formatNumber(number) {
    if(!number) return 0;
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  const handleChange = (event) => {
    setSelectedModeDetail(event.target.value);
  };

  function formatdate(inputDateStr) {
    if(!inputDateStr) return '';
    var inputDate = new Date(inputDateStr);
    var year = inputDate.getFullYear();
    var month = inputDate.getMonth() + 1;
    var day = inputDate.getDate();
    return year + '-' + (month < 10 ? '0' : '') + month + (day < 10 ? '0' : '') + '-' + day;
  }

  return (
    <>
      {/* {loading ? (
        <Loading />
      ) : ( */}
        <div className="mt-10">
          <div className="title-chart">Thông tin giao dịch</div>
          <div className='mt-4 xl:flex sm:mx-10 min-[650px]:mx-28 min-[834px]:mx-0'>
            <div className='box-content xl:w-[40%]'>
              <div className="mb-4 font-extrabold text-lg flex justify-between">
                  <div>Lệnh mua bán</div>
                  <div>
                    <input 
                      type="date" 
                      className='outline-none' 
                      onChange={(e) => setDate(e.target.value)} 
                      value={formatdate(statistic?.tradingdate) ? formatdate(statistic?.tradingdate) : (date ? date : formatdate(new Date()))}
                      max={formatdate(new Date())}
                    />
                  </div>
              </div>
              <div className='my-4 w-full'>
                <div className='mx-4 rounded shadow-md p-3 border'>
                  <div className='flex font-bold'>
                    <div className='w-7/12'>Tổng KL khớp</div>
                    <div className='w-4/12'>{formatNumber(statistic?.volume.total)}</div>
                    <div className='w-1/12'></div>
                  </div>
                  <div className='flex mt-2'>
                    <div className='w-7/12'>KL mua chủ động</div>
                    <div className='w-4/12'>{formatNumber(statistic?.volume.buy)}</div>
                    <div className='w-1/12 text-[#00BF71] font-bold'>M</div>
                  </div>
                  <div className='flex mt-2'>
                    <div className='w-7/12'>KL bán chủ động</div>
                    <div className='w-4/12 text-[rgba(215,47,47,0.85)]'>{formatNumber(statistic?.volume.sell)}</div>
                    <div className='w-1/12 text-[rgba(215,47,47,0.85)] font-bold'>B</div>
                  </div>
                </div>
              </div>
              <div className='w-full mt-8'>
                {statistic &&
                  <PieChart data1={statistic?.buy_statistics.volume} data2={statistic?.sell_statistics.volume}/>
                }
              </div>
            </div>
            <div className='box-content xl:w-[60%] mt-4 xl:mt-0 xl:ml-4'>
              <div className="mb-4 flex justify-between">
                <div className='font-extrabold text-lg'>Chi tiết khớp lệnh</div>
                <div>
                <select
                  id="countries"
                  value={selectedModeDetail}
                  onChange={handleChange}
                  class="bg-gray-50 w-40 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                >
                  <option value="all" selected>Tất cả</option>
                  <option value="large">Dòng tiền lớn</option>
                  <option value="medium">Dòng tiền vừa</option>
                  <option value="small">Dòng tiền nhỏ</option>
                </select>
                </div>
              </div>
              {stockDetail &&
                <>
                  {selectedModeDetail === 'all' && <PriceDetails datas={stockDetail.all} />}
                  {selectedModeDetail === 'large' && <PriceDetails datas={stockDetail.large} />}
                  {selectedModeDetail === 'medium' && <PriceDetails datas={stockDetail.medium} />}
                  {selectedModeDetail === 'small' && <PriceDetails datas={stockDetail.small} />}
                </>
              }
            </div>
          </div>
          <div className="mt-4 min-[600px]:mt-6 grid xl:grid-cols-3 min-[834px]:grid-cols-2 grid-cols-1 min-[834px]:mx-0 min-[650px]:mx-28 min-[520px]:mx-10 gap-6">
            {/* <div className="box-content">
              <div className=" font-extrabold text-base">Lệnh mua bán</div>
              <div className="flex text-ssm font-extrabold">
                <div className="w-1/3 text-left mt-4">
                  <div>Khối lượng</div>
                  <div className="mt-6 font-normal">9,800</div>
                  <div className="mt-6 font-normal">46,800</div>
                  <div className="mt-6 font-normal">46,800</div>
                </div>
                <div className="w-1/6 text-left mt-4">
                  <div>Giá mua</div>
                  <div className="mt-6 font-normal text-red-500">71,900</div>
                  <div className="mt-6 font-normal text-red-500">71,900</div>
                  <div className="mt-6 font-normal text-red-500">71,900</div>
                </div>
                <div className="w-1/6 text-right mt-4">
                  <div>Giá bán</div>
                  <div className="mt-6 font-normal text-orange-400">72,000</div>
                  <div className="mt-6 font-normal text-green-500">72,000</div>
                  <div className="mt-6 font-normal text-green-500">72,000</div>
                </div>
                <div className="w-1/3 text-right mt-4">
                  <div>Khối lượng bán</div>
                  <div className="mt-6 font-normal">52,700</div>
                  <div className="mt-6 font-normal">1,200</div>
                  <div className="mt-6 font-normal">12,700</div>
                </div>
              </div>
              <div className="text-center text-red-500 mt-4">71,900</div>
              <div className="mt-2 flex justify-between color-g p-4">
                <div className="text-ssm font-extrabold text-white">89,700</div>
                <div className="text-ssm font-extrabold text-white">74,000</div>
              </div>
              <div className="flex justify-between text-ssm mt-2">
                <div>Tổng khối lượng đặt mua</div>
                <div>Tổng khối lượng đặt bán</div>
              </div>
            </div> */}
            <div className="box-content">
              <div className=" font-extrabold text-base">
                Giao dịch nhà đầu tư nước ngoài
              </div>
              <div className="flex text-ssm font-extrabold">
                {chart2 && (
                  <>
                    <div className="w-1/3 text-left mt-4">
                      <div className="mt-12 font-normal">Khối lượng</div>
                      <div className="mt-6 font-normal">Giá trị giao dịch</div>
                    </div>
                    <div className="w-1/6 text-right mt-4">
                      <div className="text-center">Mua</div>
                      <div className="mt-6 font-normal">
                        {formatNumber(chart2.TotalBuyVol)}
                      </div>
                      <div className="mt-6 font-normal">{chart2.TotalBuyVal}T</div>
                    </div>
                    <div className="w-1/4 mt-4">
                      <div className="text-center">Bán</div>
                      <div className="mt-6 font-normal text-right">
                        {formatNumber(chart2.TotalSellVol)}
                      </div>
                      <div className="mt-6 font-normal text-right">
                        {chart2.TotalSellVal}T
                      </div>
                    </div>
                    <div className="w-1/4 text-right mt-4">
                      <div>Mua bán ròng</div>
                      <div
                        className={`mt-6 font-normal ${
                          chart2.TotalTradeVol > 0 ? 'text-green' : 'text-red'
                        }`}
                      >
                        {formatNumber(chart2.TotalTradeVol)}
                      </div>
                      <div
                        className={`mt-6 font-normal ${
                          chart2.TotalTradeVal > 0 ? 'text-green' : 'text-red'
                        }`}
                      >
                        {chart2.TotalTradeVal}T
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="my-0 flex justify-center">
                {chart2 && (
                  <ColumnChart data={chart2.data} />
                )}
              </div>
              <div className="text-ssm font-extrabold">
                Giá trị giao dịch nhà đầu tư nước ngoài 10 phiên gần nhất
              </div>
              <div className="flex mt-4 text-ssm">
                <div className="mt-1">
                  {chart2?.CommentLatestSession?.mode === "Sell" ?
                    <svg class="css-12z0wuy" width="14" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="8" cy="8" r="8" fill="#E14040"></circle>
                      <path d="M5.5 5.5L10.5 10.5M10.5 5.5L5.5 10.5" stroke="white" stroke-width="2" stroke-linecap="round"></path>
                    </svg>
                    :
                    <svg
                      width="14"
                      height="13"
                      viewBox="0 0 14 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.05416 7.93073L4.50543 6.38201C4.41313 6.2897 4.2971 6.24248 4.15736 6.24034C4.01761 6.2382 3.89945 6.28542 3.80288 6.38201C3.7063 6.47859 3.65801 6.59568 3.65801 6.73328C3.65801 6.87088 3.7063 6.98797 3.80288 7.08454L5.63236 8.91403C5.75287 9.03454 5.89347 9.09479 6.05416 9.09479C6.21484 9.09479 6.35543 9.03454 6.47594 8.91403L10.1849 5.20506C10.2772 5.11276 10.3244 4.99674 10.3266 4.85699C10.3287 4.71725 10.2815 4.59909 10.1849 4.50251C10.0883 4.40593 9.97124 4.35764 9.83364 4.35764C9.69604 4.35764 9.57895 4.40593 9.48238 4.50251L6.05416 7.93073ZM7.00143 12.8333C6.12547 12.8333 5.30211 12.667 4.53136 12.3346C3.76059 12.0022 3.09014 11.551 2.51999 10.9811C1.94984 10.4112 1.49846 9.74103 1.16588 8.97061C0.833287 8.2002 0.666992 7.37702 0.666992 6.50106C0.666992 5.6251 0.833215 4.80175 1.16566 4.03099C1.4981 3.26023 1.94927 2.58977 2.51916 2.01963C3.08906 1.44947 3.75922 0.998098 4.52964 0.665509C5.30005 0.33292 6.12324 0.166626 6.99919 0.166626C7.87515 0.166626 8.6985 0.332848 9.46926 0.665293C10.24 0.997737 10.9105 1.4489 11.4806 2.01879C12.0508 2.58869 12.5022 3.25885 12.8347 4.02928C13.1673 4.79969 13.3336 5.62287 13.3336 6.49883C13.3336 7.37478 13.1674 8.19814 12.835 8.96889C12.5025 9.73966 12.0513 10.4101 11.4815 10.9803C10.9116 11.5504 10.2414 12.0018 9.47098 12.3344C8.70057 12.667 7.87738 12.8333 7.00143 12.8333Z"
                        fill="#1AAF76"
                      />
                    </svg>
                  }
                </div>
                <div className="ml-2">
                  Nhà đầu tư nước ngoài đã {chart2?.CommentLatestSession?.mode === "Sell" ? 'bán' : 'mua'} ròng {chart2?.CommentLatestSession?.trading_value < 1 ? chart2?.CommentLatestSession?.trading_value * 1000 : chart2?.CommentLatestSession?.trading_value} {chart2?.CommentLatestSession?.trading_value < 1 ? 'triệu' : 'tỷ'} đồng trong phiên
                  giao dịch gần nhất
                </div>
              </div>
              <div className="flex mt-4 text-ssm">
                <div className="mt-1">
                  {chart2?.CommentLast10Sessions?.mode === "Sell" ?
                    <svg class="css-12z0wuy" width="14" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="8" cy="8" r="8" fill="#E14040"></circle>
                      <path d="M5.5 5.5L10.5 10.5M10.5 5.5L5.5 10.5" stroke="white" stroke-width="2" stroke-linecap="round"></path>
                    </svg>
                    :
                    <svg
                      width="14"
                      height="13"
                      viewBox="0 0 14 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.05416 7.93073L4.50543 6.38201C4.41313 6.2897 4.2971 6.24248 4.15736 6.24034C4.01761 6.2382 3.89945 6.28542 3.80288 6.38201C3.7063 6.47859 3.65801 6.59568 3.65801 6.73328C3.65801 6.87088 3.7063 6.98797 3.80288 7.08454L5.63236 8.91403C5.75287 9.03454 5.89347 9.09479 6.05416 9.09479C6.21484 9.09479 6.35543 9.03454 6.47594 8.91403L10.1849 5.20506C10.2772 5.11276 10.3244 4.99674 10.3266 4.85699C10.3287 4.71725 10.2815 4.59909 10.1849 4.50251C10.0883 4.40593 9.97124 4.35764 9.83364 4.35764C9.69604 4.35764 9.57895 4.40593 9.48238 4.50251L6.05416 7.93073ZM7.00143 12.8333C6.12547 12.8333 5.30211 12.667 4.53136 12.3346C3.76059 12.0022 3.09014 11.551 2.51999 10.9811C1.94984 10.4112 1.49846 9.74103 1.16588 8.97061C0.833287 8.2002 0.666992 7.37702 0.666992 6.50106C0.666992 5.6251 0.833215 4.80175 1.16566 4.03099C1.4981 3.26023 1.94927 2.58977 2.51916 2.01963C3.08906 1.44947 3.75922 0.998098 4.52964 0.665509C5.30005 0.33292 6.12324 0.166626 6.99919 0.166626C7.87515 0.166626 8.6985 0.332848 9.46926 0.665293C10.24 0.997737 10.9105 1.4489 11.4806 2.01879C12.0508 2.58869 12.5022 3.25885 12.8347 4.02928C13.1673 4.79969 13.3336 5.62287 13.3336 6.49883C13.3336 7.37478 13.1674 8.19814 12.835 8.96889C12.5025 9.73966 12.0513 10.4101 11.4815 10.9803C10.9116 11.5504 10.2414 12.0018 9.47098 12.3344C8.70057 12.667 7.87738 12.8333 7.00143 12.8333Z"
                        fill="#1AAF76"
                      />
                    </svg>
                  }
                </div>
                <div className="ml-2">
                  Lũy kế 10 phiên gần nhất nhóm này {chart2?.CommentLast10Sessions?.mode === "Sell" ? 'bán' : 'mua'} ròng {chart2?.CommentLast10Sessions?.trading_value < 1 ? chart2?.CommentLast10Sessions?.trading_value * 1000 : chart2?.CommentLast10Sessions?.trading_value} {chart2?.CommentLast10Sessions?.trading_value < 1 ? 'triệu' : 'tỷ'} đồng.
                </div>
              </div>
            </div>
            <div className="box-content">
              <div className=" font-extrabold text-base">
                Giao dịch tự doanh
              </div>
              <div className="flex text-ssm font-extrabold">
              {chart1 && (
                <>
                  <div className="w-1/3 text-left mt-4">
                    <div className="mt-12 font-normal">Khối lượng</div>
                    <div className="mt-6 font-normal">Giá trị giao dịch</div>
                  </div>
                  <div className="w-1/6 text-right mt-4">
                    <div className="text-center">Mua</div>
                    <div className="mt-6 font-normal">
                      {formatNumber(chart1.TotalBuyVol)}
                    </div>
                    <div className="mt-6 font-normal">{chart1.TotalBuyVal}T</div>
                  </div>
                  <div className="w-1/4 mt-4">
                    <div className="text-center">Bán</div>
                    <div className="mt-6 font-normal text-right">
                      {formatNumber(chart1.TotalSellVol)}
                    </div>
                    <div className="mt-6 font-normal text-right">
                      {chart1.TotalSellVal}T
                    </div>
                  </div>
                  <div className="w-1/4 text-right mt-4">
                    <div>Mua bán ròng</div>
                    <div
                      className={`mt-6 font-normal ${
                        chart1.TotalTradeVol > 0 ? 'text-green' : 'text-red'
                      }`}
                    >
                      {formatNumber(chart1.TotalTradeVol)}
                    </div>
                    <div
                      className={`mt-6 font-normal ${
                        chart1.TotalTradeVal > 0 ? 'text-green' : 'text-red'
                      }`}
                    >
                      {chart1.TotalTradeVal}T
                    </div>
                  </div>
                </>
              )}
              </div>
              <div className="my-0 flex justify-center">
                {chart1 && (
                  <ColumnChart data={chart1.data} />
                )}
              </div>
              <div className="text-ssm font-extrabold">
                Giá trị giao dịch tự doanh 10 phiên gần nhất
              </div>
              <div className="flex mt-4 text-ssm">
                <div className="mt-1">
                  {chart1?.CommentLatestSession?.mode === "Sell" ?
                    <svg class="css-12z0wuy" width="14" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="8" cy="8" r="8" fill="#E14040"></circle>
                      <path d="M5.5 5.5L10.5 10.5M10.5 5.5L5.5 10.5" stroke="white" stroke-width="2" stroke-linecap="round"></path>
                    </svg>
                    :
                    <svg
                      width="14"
                      height="13"
                      viewBox="0 0 14 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.05416 7.93073L4.50543 6.38201C4.41313 6.2897 4.2971 6.24248 4.15736 6.24034C4.01761 6.2382 3.89945 6.28542 3.80288 6.38201C3.7063 6.47859 3.65801 6.59568 3.65801 6.73328C3.65801 6.87088 3.7063 6.98797 3.80288 7.08454L5.63236 8.91403C5.75287 9.03454 5.89347 9.09479 6.05416 9.09479C6.21484 9.09479 6.35543 9.03454 6.47594 8.91403L10.1849 5.20506C10.2772 5.11276 10.3244 4.99674 10.3266 4.85699C10.3287 4.71725 10.2815 4.59909 10.1849 4.50251C10.0883 4.40593 9.97124 4.35764 9.83364 4.35764C9.69604 4.35764 9.57895 4.40593 9.48238 4.50251L6.05416 7.93073ZM7.00143 12.8333C6.12547 12.8333 5.30211 12.667 4.53136 12.3346C3.76059 12.0022 3.09014 11.551 2.51999 10.9811C1.94984 10.4112 1.49846 9.74103 1.16588 8.97061C0.833287 8.2002 0.666992 7.37702 0.666992 6.50106C0.666992 5.6251 0.833215 4.80175 1.16566 4.03099C1.4981 3.26023 1.94927 2.58977 2.51916 2.01963C3.08906 1.44947 3.75922 0.998098 4.52964 0.665509C5.30005 0.33292 6.12324 0.166626 6.99919 0.166626C7.87515 0.166626 8.6985 0.332848 9.46926 0.665293C10.24 0.997737 10.9105 1.4489 11.4806 2.01879C12.0508 2.58869 12.5022 3.25885 12.8347 4.02928C13.1673 4.79969 13.3336 5.62287 13.3336 6.49883C13.3336 7.37478 13.1674 8.19814 12.835 8.96889C12.5025 9.73966 12.0513 10.4101 11.4815 10.9803C10.9116 11.5504 10.2414 12.0018 9.47098 12.3344C8.70057 12.667 7.87738 12.8333 7.00143 12.8333Z"
                        fill="#1AAF76"
                      />
                    </svg>
                  }
                </div>
                <div className="ml-2">
                  Tự doanh đã {chart1?.CommentLatestSession?.mode === "Sell" ? 'bán' : 'mua'} ròng {chart1?.CommentLatestSession?.trading_value < 1 ? chart1?.CommentLatestSession?.trading_value * 1000 : chart1?.CommentLatestSession?.trading_value} {chart1?.CommentLatestSession?.trading_value < 1 ? 'triệu' : 'tỷ'} đồng trong phiên
                  giao dịch gần nhất
                </div>
              </div>
              <div className="flex mt-4 text-ssm">
                <div className="mt-1">
                  {chart1?.CommentLast10Sessions?.mode === "Sell" ?
                    <svg class="css-12z0wuy" width="14" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="8" cy="8" r="8" fill="#E14040"></circle>
                      <path d="M5.5 5.5L10.5 10.5M10.5 5.5L5.5 10.5" stroke="white" stroke-width="2" stroke-linecap="round"></path>
                    </svg>
                    :
                    <svg
                      width="14"
                      height="13"
                      viewBox="0 0 14 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.05416 7.93073L4.50543 6.38201C4.41313 6.2897 4.2971 6.24248 4.15736 6.24034C4.01761 6.2382 3.89945 6.28542 3.80288 6.38201C3.7063 6.47859 3.65801 6.59568 3.65801 6.73328C3.65801 6.87088 3.7063 6.98797 3.80288 7.08454L5.63236 8.91403C5.75287 9.03454 5.89347 9.09479 6.05416 9.09479C6.21484 9.09479 6.35543 9.03454 6.47594 8.91403L10.1849 5.20506C10.2772 5.11276 10.3244 4.99674 10.3266 4.85699C10.3287 4.71725 10.2815 4.59909 10.1849 4.50251C10.0883 4.40593 9.97124 4.35764 9.83364 4.35764C9.69604 4.35764 9.57895 4.40593 9.48238 4.50251L6.05416 7.93073ZM7.00143 12.8333C6.12547 12.8333 5.30211 12.667 4.53136 12.3346C3.76059 12.0022 3.09014 11.551 2.51999 10.9811C1.94984 10.4112 1.49846 9.74103 1.16588 8.97061C0.833287 8.2002 0.666992 7.37702 0.666992 6.50106C0.666992 5.6251 0.833215 4.80175 1.16566 4.03099C1.4981 3.26023 1.94927 2.58977 2.51916 2.01963C3.08906 1.44947 3.75922 0.998098 4.52964 0.665509C5.30005 0.33292 6.12324 0.166626 6.99919 0.166626C7.87515 0.166626 8.6985 0.332848 9.46926 0.665293C10.24 0.997737 10.9105 1.4489 11.4806 2.01879C12.0508 2.58869 12.5022 3.25885 12.8347 4.02928C13.1673 4.79969 13.3336 5.62287 13.3336 6.49883C13.3336 7.37478 13.1674 8.19814 12.835 8.96889C12.5025 9.73966 12.0513 10.4101 11.4815 10.9803C10.9116 11.5504 10.2414 12.0018 9.47098 12.3344C8.70057 12.667 7.87738 12.8333 7.00143 12.8333Z"
                        fill="#1AAF76"
                      />
                    </svg>
                  }
                </div>
                <div className="ml-2">
                  Lũy kế 10 phiên gần nhất nhóm này {chart1?.CommentLast10Sessions?.mode === "Sell" ? 'bán' : 'mua'}  ròng {chart1?.CommentLast10Sessions?.trading_value < 1 ? chart1?.CommentLast10Sessions?.trading_value * 1000 : chart1?.CommentLast10Sessions?.trading_value} {chart1?.CommentLast10Sessions?.trading_value < 1 ? 'triệu' : 'tỷ'} đồng.
                </div>
              </div>
            </div>
            <div className="box-content">
              <div className=" font-extrabold text-base">
                Giao dịch Cổ đông lớn
              </div>
              <div className="flex text-ssm font-extrabold">
                {chart3 && (
                  <>
                    <div className="w-1/3 text-left mt-4">
                      <div className="mt-12 font-normal">Khối lượng</div>
                      <div className="mt-6 font-normal">Giá trị giao dịch</div>
                    </div>
                    <div className="w-1/6 text-right mt-4">
                      <div className="text-center">Mua</div>
                      <div className="mt-6 font-normal">
                        {formatNumber(chart3.TotalBuyVol)}
                      </div>
                      <div className="mt-6 font-normal">{chart3.TotalBuyVal}T</div>
                    </div>
                    <div className="w-1/4 mt-4">
                      <div className="text-center">Bán</div>
                      <div className="mt-6 font-normal text-right">
                        {formatNumber(chart3.TotalSellVol)}
                      </div>
                      <div className="mt-6 font-normal text-right">
                        {chart3.TotalSellVal}T
                      </div>
                    </div>
                    <div className="w-1/4 text-right mt-4">
                      <div>Mua bán ròng</div>
                      <div
                        className={`mt-6 font-normal ${
                          chart3.TotalTradeVol > 0 ? 'text-green' : 'text-red'
                        }`}
                      >
                        {formatNumber(chart3.TotalTradeVol)}
                      </div>
                      <div
                        className={`mt-6 font-normal ${
                          chart3.TotalTradeVal > 0 ? 'text-green' : 'text-red'
                        }`}
                      >
                        {chart3.TotalTradeVal}T
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="my-0 flex justify-center">
                {chart3 && (
                  <ColumnChartMonth data={chart3.data} />
                )}
              </div>
              <div className="text-ssm font-extrabold">
                Giá trị giao dịch Cổ đông lớn 10 phiên gần nhất
              </div>
              <div className="flex mt-4 text-ssm">
                <div className="mt-1">
                  {chart3?.CommentLatestSession?.mode === "Sell" ?
                    <svg class="css-12z0wuy" width="14" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="8" cy="8" r="8" fill="#E14040"></circle>
                      <path d="M5.5 5.5L10.5 10.5M10.5 5.5L5.5 10.5" stroke="white" stroke-width="2" stroke-linecap="round"></path>
                    </svg>
                    :
                    <svg
                      width="14"
                      height="13"
                      viewBox="0 0 14 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.05416 7.93073L4.50543 6.38201C4.41313 6.2897 4.2971 6.24248 4.15736 6.24034C4.01761 6.2382 3.89945 6.28542 3.80288 6.38201C3.7063 6.47859 3.65801 6.59568 3.65801 6.73328C3.65801 6.87088 3.7063 6.98797 3.80288 7.08454L5.63236 8.91403C5.75287 9.03454 5.89347 9.09479 6.05416 9.09479C6.21484 9.09479 6.35543 9.03454 6.47594 8.91403L10.1849 5.20506C10.2772 5.11276 10.3244 4.99674 10.3266 4.85699C10.3287 4.71725 10.2815 4.59909 10.1849 4.50251C10.0883 4.40593 9.97124 4.35764 9.83364 4.35764C9.69604 4.35764 9.57895 4.40593 9.48238 4.50251L6.05416 7.93073ZM7.00143 12.8333C6.12547 12.8333 5.30211 12.667 4.53136 12.3346C3.76059 12.0022 3.09014 11.551 2.51999 10.9811C1.94984 10.4112 1.49846 9.74103 1.16588 8.97061C0.833287 8.2002 0.666992 7.37702 0.666992 6.50106C0.666992 5.6251 0.833215 4.80175 1.16566 4.03099C1.4981 3.26023 1.94927 2.58977 2.51916 2.01963C3.08906 1.44947 3.75922 0.998098 4.52964 0.665509C5.30005 0.33292 6.12324 0.166626 6.99919 0.166626C7.87515 0.166626 8.6985 0.332848 9.46926 0.665293C10.24 0.997737 10.9105 1.4489 11.4806 2.01879C12.0508 2.58869 12.5022 3.25885 12.8347 4.02928C13.1673 4.79969 13.3336 5.62287 13.3336 6.49883C13.3336 7.37478 13.1674 8.19814 12.835 8.96889C12.5025 9.73966 12.0513 10.4101 11.4815 10.9803C10.9116 11.5504 10.2414 12.0018 9.47098 12.3344C8.70057 12.667 7.87738 12.8333 7.00143 12.8333Z"
                        fill="#1AAF76"
                      />
                    </svg>
                  }
                </div>
                <div className="ml-2">
                  Cổ đông lớn đã {chart3?.CommentLatestSession?.mode === "Sell" ? 'bán' : 'mua'} ròng {chart3?.CommentLatestSession?.trading_value < 1 ? chart3?.CommentLatestSession?.trading_value * 1000 : chart3?.CommentLatestSession?.trading_value} {chart3?.CommentLatestSession?.trading_value < 1 ? 'triệu' : 'tỷ'} đồng trong phiên
                  giao dịch gần nhất
                </div>
              </div>
              <div className="flex mt-4 text-ssm">
                <div className="mt-1">
                  {chart3?.CommentLast10Sessions?.mode === "Sell" ?
                    <svg class="css-12z0wuy" width="14" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="8" cy="8" r="8" fill="#E14040"></circle>
                      <path d="M5.5 5.5L10.5 10.5M10.5 5.5L5.5 10.5" stroke="white" stroke-width="2" stroke-linecap="round"></path>
                    </svg>
                    :
                    <svg
                      width="14"
                      height="13"
                      viewBox="0 0 14 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.05416 7.93073L4.50543 6.38201C4.41313 6.2897 4.2971 6.24248 4.15736 6.24034C4.01761 6.2382 3.89945 6.28542 3.80288 6.38201C3.7063 6.47859 3.65801 6.59568 3.65801 6.73328C3.65801 6.87088 3.7063 6.98797 3.80288 7.08454L5.63236 8.91403C5.75287 9.03454 5.89347 9.09479 6.05416 9.09479C6.21484 9.09479 6.35543 9.03454 6.47594 8.91403L10.1849 5.20506C10.2772 5.11276 10.3244 4.99674 10.3266 4.85699C10.3287 4.71725 10.2815 4.59909 10.1849 4.50251C10.0883 4.40593 9.97124 4.35764 9.83364 4.35764C9.69604 4.35764 9.57895 4.40593 9.48238 4.50251L6.05416 7.93073ZM7.00143 12.8333C6.12547 12.8333 5.30211 12.667 4.53136 12.3346C3.76059 12.0022 3.09014 11.551 2.51999 10.9811C1.94984 10.4112 1.49846 9.74103 1.16588 8.97061C0.833287 8.2002 0.666992 7.37702 0.666992 6.50106C0.666992 5.6251 0.833215 4.80175 1.16566 4.03099C1.4981 3.26023 1.94927 2.58977 2.51916 2.01963C3.08906 1.44947 3.75922 0.998098 4.52964 0.665509C5.30005 0.33292 6.12324 0.166626 6.99919 0.166626C7.87515 0.166626 8.6985 0.332848 9.46926 0.665293C10.24 0.997737 10.9105 1.4489 11.4806 2.01879C12.0508 2.58869 12.5022 3.25885 12.8347 4.02928C13.1673 4.79969 13.3336 5.62287 13.3336 6.49883C13.3336 7.37478 13.1674 8.19814 12.835 8.96889C12.5025 9.73966 12.0513 10.4101 11.4815 10.9803C10.9116 11.5504 10.2414 12.0018 9.47098 12.3344C8.70057 12.667 7.87738 12.8333 7.00143 12.8333Z"
                        fill="#1AAF76"
                      />
                    </svg>
                  }
                </div>
                <div className="ml-2">
                  Lũy kế 10 phiên gần nhất nhóm này {chart3?.CommentLast10Sessions?.mode === "Sell" ? 'bán' : 'mua'}  ròng {chart3?.CommentLast10Sessions?.trading_value < 1 ? chart3?.CommentLast10Sessions?.trading_value * 1000 : chart3?.CommentLast10Sessions?.trading_value } {chart3?.CommentLast10Sessions?.trading_value < 1 ? 'triệu' : 'tỷ'} đồng.
                </div>
              </div>
            </div>
            <div className="box-content text-ssm">
              <div className="font-extrabold text-base">Định giá</div>
              {valuation && (
                <>
                  <div className="mt-4 font-extrabold flex">
                    <div className="w-1/4">Mô hình</div>
                    <div className="w-1/2 text-center">Định giá</div>
                    <div className="w-1/4">Trọng số</div>
                  </div>
                  <div className="mt-4 font-normal flex">
                    <div className="w-1/4">P/E</div>
                    <div className="w-1/2 text-center">
                      {formatNumber(valuation[0]?.estimate_price_pe.toFixed(0))}
                    </div>
                    <div className="w-1/4 text-center">{valuation[0]?.pe_contribution*100}%</div>
                    
                  </div>
                  <div className="mt-4 font-normal flex">
                    <div className="w-1/4">P/B</div>
                    <div className="w-1/2 text-center">
                      {formatNumber(valuation[0]?.estimate_price_pb.toFixed(0))}
                    </div>
                    <div className="w-1/4 text-center">{valuation[0]?.pb_contribution*100}%</div>
                  </div>
                  <div className="mt-4 box-footer text-green text-center py-2">
                    <div className="text-ssm font-extrabold">Giá trị nội tại</div>
                    <div className="font-extrabold number">
                      {formatNumber(valuation[0]?.estimate_price.toFixed(0))}
                    </div>
                  </div>
                </>
              )}
              
            </div>
            <div className="box-content text-ssm">
              {valuation && (
                <>
                  <div className={`${valuation[0].safety_margin > 0 ? 'text-[#00BF71]' : 'text-[rgba(215,47,47,0.85)]'} mt-2 pb-1`}
                    style={{ 
                      marginLeft: `${valuation[0].safety_margin > 0 ? valuation[0].current_price/valuation[0].upper_bound*100 : valuation[0].estimate_price/valuation[0].upper_bound*100}%`,
                    }}
                  >
                    <div className='font-bold'>{valuation[0].safety_margin.toFixed(2)}%</div>
                    <div className='-ml-4'>Biên an toàn</div>
                  </div>
                  <div className={`pb-1 border-b ${valuation[0].safety_margin > 0 ? 'border-[#00BF71]' : 'border-[rgba(215,47,47,0.85)]'}`}
                    style={{ 
                      marginLeft: `${valuation[0].safety_margin > 0 ? valuation[0].current_price/valuation[0].upper_bound*100 : valuation[0].estimate_price/valuation[0].upper_bound*100 }%`,
                      marginRight: `${valuation[0].safety_margin > 0 ? 100 - valuation[0].estimate_price/valuation[0].upper_bound*100 : 100 - valuation[0].current_price/valuation[0].upper_bound*100}%`,
                    }}>
                  </div>
                  <div className="color-g mt-2 py-4" 
                    style={{
                      background: `linear-gradient(
                        90deg, 
                        #00BF71 0%,
                        #00BF71 ${valuation[0].estimate_price_low/valuation[0].upper_bound*100}%, 
                        #FFCD6B ${valuation[0].estimate_price_low/valuation[0].upper_bound*100}%, 
                        #FFCD6B ${(valuation[0].upper_bound - valuation[0].estimate_price_low)/valuation[0].upper_bound*100}%, 
                        rgba(215,47,47,0.85) ${(valuation[0].upper_bound - valuation[0].estimate_price_low)/valuation[0].upper_bound*100}%, 
                        rgba(215,47,47,0.85) 100%`, 
                      borderRadius: "4px"
                    }}>

                    <div className="border-r border-white text-ssm font-extrabold text-white bg-slate-800 p-1" style={{width: `${valuation[0].current_price/valuation[0].upper_bound*100}%`, backgroundColor: 'rgba(0, 0, 0, 0.3)'}}>
                      <div className='opacity-100 text-right'>
                        <div>Giá hiện tại</div>
                        <div>{formatNumber(valuation[0].current_price.toFixed(0))}</div>
                      </div>
                    </div>
                    <div className="border-r border-white text-ssm font-extrabold text-white mt-2 bg-slate-800 p-1" style={{width: `${valuation[0].estimate_price/valuation[0].upper_bound*100}%`, backgroundColor: 'rgba(0, 0, 0, 0.3)'}}>
                      <div className='opacity-100 text-right'>
                        <div>Mức định giá</div>
                        <div>{formatNumber(valuation[0].estimate_price.toFixed(0))}</div>
                      </div>
                    </div>
                  </div>
                  <div className='flex justify-between mt-2'>
                    <div className='flex items-center'>
                      <div className='w-4 h-4 rounded-sm bg-[#00BF71] mr-1'></div>
                      <div>Vùng giá hấp dẫn</div>
                    </div>
                    <div className='flex items-center'>
                      <div className='w-4 h-4 rounded-sm bg-[#FFCD6B] mr-1'></div>
                      <div>Mức định giá</div>
                    </div>
                    <div className='flex items-center'>
                      <div className='w-4 h-4 rounded-sm bg-[rgba(215,47,47,0.85)] mr-1'></div>
                      <div>Vùng giá cao</div>
                    </div>
                  </div>
                </>
              )}
              
            </div>
            <div className="box-content-2 overflow-y-auto">
              <div className="mx-4">
                <div className="font-extrabold text-base mt-4">
                  Lịch trả cổ tức và chia tách cổ phiếu
                </div>
                <div className="flex">
                  <div className="mt-4 w-1/4">
                    <div className="font-extrabold text-ssm">Ngày</div>
                  </div>
                  <div className="mt-4 w-3/4">
                    <div className="font-extrabold text-ssm">Sự kiện</div>
                  </div>
                </div>
                {stockInfo && (
                  <div className="content-scroll">
                    {stockInfo.map((stock) => (
                      <div className="flex">
                        <div className="mt-4 w-1/4">
                          <div className="font-normal text-ssm">
                            {stock.tradingdate
                              .substring(0, 10)
                              .replaceAll('-', '/')}
                          </div>
                        </div>
                        <div className="mt-4 w-3/4">
                          <div className="font-extrabold text-ssm">
                            {stock.title}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      {/* )} */}
    </>
  );
};

export default TransactionInformation;
