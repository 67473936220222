import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { ToastContainer, toast } from 'react-toastify';
import ReactApexChart from 'react-apexcharts';
import 'react-toastify/dist/ReactToastify.css';

const LineCharts2 = (props) => {
  const { dataSectorChangeFromDate, dataListSector } = props;
  const [data, setData] = useState([]);
  const [listSectorSelect, setListSectorSelect] = useState([12, 13, 14])
  const notify = () => toast.error("Ngành này chưa có đủ dữ liệu !",)

  const onChange = (value, index) => {
    let tmpIndex = dataSectorChangeFromDate.findIndex(item => item.sector_id === value.sector_id)
    if (tmpIndex >= 0) {
      setListSectorSelect(prev => {
        let isChecked = prev.includes(value.sector_id)
        if (isChecked) {
          return prev.filter(item => item !== value.sector_id)
        } else {
          return [...prev, value.sector_id]
        }
      })
    } else {
      notify()
    }
  }

  useEffect(() => {
    if (dataSectorChangeFromDate?.length !== 0 && dataListSector?.length !== 0) {
      const data = dataSectorChangeFromDate
        .filter(dataChange => listSectorSelect.includes(dataChange.sector_id))
        .map(dataChange => {
          const sector_id = dataChange.sector_id;
          const sector_info = dataListSector.find(sector => sector.sector_id === sector_id);
          if (sector_info) {
            const sector_name = sector_info.name;
            const data = dataChange.data;
            return {
              name: sector_name,
              data: data.map(([date, value]) => ({
                x: new Date(date).getTime(),
                y: value.toFixed(2),
              })),
            };
          }
          return null;
        })
        .filter(Boolean);
      setData(data);
    }
  }, [dataSectorChangeFromDate, dataListSector, listSectorSelect]);

  const options = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    stroke: {
      width: 3,
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.3
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: function (value, timestamp, opts) {
          return moment(new Date(value)).format("DD/MM/YYYY")
        }
      },
      tickAmount: 12,
    },
  };
  return (
    <div className="w-full lg:flex layout">
      <div className="flex-auto mr-6 lg:w-3/4 h-full">
        <ReactApexChart
          options={options}
          series={data}
          type="line"
          height={500}
        />
      </div>
      <div className="flex-auto py-2 px-3 bg-white rounded-lg shadow-lg lg:w-1/4 layout-item h-[500px] overflow-y-auto">
        {dataListSector.map((el, index) => {
          return <div className="w-full">
            <Checkbox checked={listSectorSelect.includes(el.sector_id)} onChange={() => onChange(el, index)}>
              {el.name}
            </Checkbox>
          </div>
        })}
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    dataSectorColor: state.homePage.sectorColor,
    dataSectorChangeFromDate: state.homePage.dataSectorChangeFromDate,
    dataListSector: state.homePage.dataListSector,
  }
}
export default connect(mapStateToProps, {
})(LineCharts2);
