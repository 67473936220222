import React, { useEffect, useRef, useState } from 'react';
import TopPriceIncrease from './TopPriceIncrease';
import TopDiscount from './TopDiscount';
import TopBuy from './TopBuy';
import TopSell from './TopSell';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import ModeActions from '../tools/ModeActions';
import { connect } from 'react-redux';
import { getTopStockBuyForeign,
  getTopStockSellForeign,
  getTopStockDecreaseRate,
  getTopStockIncreaseRate
} from '../../store/reducers/homepage/actions';
import TopForeign from './TopForeign';
import TopTD from './TopTD';
import TopInvest from './TopInvest';
import Swipers from './Swipers';

const MarketMovements = (props) => {
  const {getTopStockBuyForeign,
        getTopStockSellForeign,
        getTopStockDecreaseRate,
        getTopStockIncreaseRate} = props
  const [mode, setMode] = useState('1d');
  const elRef = useRef();

  const listMode= ['1d','1w','1m']
  
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: 'smooth',
        });
      };
      el.addEventListener('wheel', onWheel);
      return () => el.removeEventListener('wheel', onWheel);
    }
  }, []);

  useEffect(()=>{
    getTopStockDecreaseRate(mode)
    getTopStockIncreaseRate(mode)
  },[mode])

  return (
    <div className="w-full h-full Movement">
      <div className="">
      <div className="flex justify-between">
            <div className="font-extrabold text-[32px]">Diễn biến thị trường</div>
            <div className="flex justify-between items-center">
              <ModeActions listMode={listMode} onChangeMode={(el)=>setMode(el)}/>
            </div>
          </div>
      </div>
      <div className="w-full" ref={elRef}>
        <div
          className="w-full flex market-moment my-10 justify-between gap-6 layout"
          style={{ whiteSpace: 'nowrap' }}
        >
          <div className="sub-container">
            <div className="flex-auto w-1/5 h-full layout-item">
              <div className="w-full h-full">
                <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                loop={true} // Enable loop
                spaceBetween={10} // Adjust spacing between slides
                slidesPerView={1}
                // navigation // Enable navigation arrows
                pagination={{ clickable: true }} // Enable pagination dots
                autoplay={{ delay: 3000 }}
                >
                  <SwiperSlide><TopPriceIncrease mode={mode} topPrice={1}/></SwiperSlide>
                  <SwiperSlide><TopPriceIncrease mode={mode} topPrice={2}/></SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div className="flex-auto w-1/5 h-full layout-item">
              <div className="w-full h-full">
                <Swiper
                  modules={[Navigation, Pagination, Autoplay]}
                  loop={true} // Enable loop
                  spaceBetween={10} // Adjust spacing between slides
                  slidesPerView={1}
                  // navigation // Enable navigation arrows
                  pagination={{ clickable: true }} // Enable pagination dots
                  autoplay={{ delay: 3000 }}
                >
                  <SwiperSlide><TopInvest mode={mode} type={1}/></SwiperSlide>
                  <SwiperSlide><TopInvest mode={mode} type={2}/></SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
          <div className="sub-container">
            <div className="flex-auto w-1/5 h-full layout-item">
              <div className="w-full h-full">
                <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                loop={true} // Enable loop
                spaceBetween={10} // Adjust spacing between slides
                slidesPerView={1}
                // navigation // Enable navigation arrows
                pagination={{ clickable: true }} // Enable pagination dots
                autoplay={{ delay: 3000 }}
                >
                  <SwiperSlide><TopTD mode={mode} type={1}/></SwiperSlide>
                  <SwiperSlide><TopTD mode={mode} type={2}/></SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div className="flex-auto w-1/5 h-full layout-item">
              <div className="w-full h-full">
                <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                loop={true} // Enable loop
                spaceBetween={10} // Adjust spacing between slides
                slidesPerView={1}
                // navigation // Enable navigation arrows
                pagination={{ clickable: true }} // Enable pagination dots
                autoplay={{ delay: 3000 }}
                >
                  <SwiperSlide><TopForeign mode={mode} type={1}/></SwiperSlide>
                  <SwiperSlide><TopForeign mode={mode} type={2}/></SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {

  }
}

export default connect(mapStateToProps,{
  getTopStockBuyForeign,
  getTopStockSellForeign,
  getTopStockDecreaseRate,
  getTopStockIncreaseRate
})(MarketMovements);
