import { useState, useEffect } from "react";
import { connect } from "react-redux";

const IndustryGroup = (props) => {
  const {dataSectorIndexChange} = props

  return(
    <div className="mb-10 grid max-[500px]:grid-cols-2 grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-4">
      {dataSectorIndexChange.map((el,index) => {
        return (
        <div className={`flex flex-col items-center justify-center rounded text-white p-4 h-[100px]
        ${
          el?.pct_change > 0 ? 'bg-[#1AAF76]' : 'bg-[#D72F2F]'
        }`} key={index}>
          <div className="text-center text-[16px]">{el?.name}</div>
          <div className="text-[24]">{el?.pct_change.toFixed(2)}%</div>
        </div>
        )
      })}
    </div>
  )
}

const mapStateToProps = state => {
  return{
    dataSectorIndexChange: state.homePage.dataSectorIndexChange
  }
}

export default connect(mapStateToProps, {
})(IndustryGroup)