import '../Overview.css';
import RRGChart from './RRGChart';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { useEffect, useReducer, useState } from 'react';
// import { relatedStocks } from '../../../common/api/tools';
import { getSectorByCompany } from '../../../common/api/sector';
import { autocompleteSearch } from '../../../common/api/search';
import { Slider } from '@mui/material';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        series: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function StockTrend(props) {
  const [tickerDefault] = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const ticker = searchParams.get('ticker') ?? tickerDefault;
  let [{ loading, series }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  const [datas, setDatas] = useState([]);
  const [mode, setMode] = useState('daily');
  const [dataStocks, setDataStocks] = useState([]);
  const [window, setWindow] = useState(5);
  const [timeframe, setTimeframe] = useState(30);
  const [maxTimeframe, setMaxTimeframe] = useState(30);
  const [rrg, setRrg] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getSectorByCompany(ticker);
        const defa = [[res.data[0].ticker, res.data[0].company_name]];
        setRrg(res.data[0].ticker);
        setDatas(defa);
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: defa,
        });
      } catch (err) { }
    };
    fetchData();
  }, [ticker]);

  function deleteItem(value) {
    var index = datas.indexOf(value);
    if (index !== -1) {
      setDatas(datas.filter(function (e) { return e !== value }));
    }
    if (rrg.includes('%2C' + value[0])) {
      setRrg(rrg.replace('%2C' + value[0], ''));
    } else {
      setRrg(rrg.replace(value[0] + '%2C', ''));
    }
  }

  const search = async (event) => {
    if (event.target.value) {
      const res = await autocompleteSearch(event.target.value);
      setDataStocks(res.data);
    } else setDataStocks([]);
  };

  const handleSearch = (e, value) => {
    loading = true;
    const cp = [value.ticker, value.company_name];
    if (!rrg.includes(cp[0])) {
      setDatas([cp, ...datas]);
      setRrg(rrg + "%2C" + value.ticker);
    }
    var inputElement = document.getElementById('inputAdd');
    inputElement.value = '';
    setDataStocks([]);
    loading = false;
  };

  return (
    <div className="px-4 w-full lg:px-20 xl:px-0">
      <div className="w-full xl:w-2/3">
        <div className="min-[450px]:flex justify-between my-12">
          <div className="title-chart"> Xu hướng cổ phiếu </div>
        </div>
      </div>
      <div className="md:w-4/5 w-full flex text-sm justify-between">
        <div className="flex border rounded mr-5 h-1/2">
          <div 
            className={`px-8 py-2 flex justify-center items-center cursor-pointer ${ mode === 'daily' ? 'rounded-s border-r filter-active' : ''}`}
            onClick={() => setMode('daily')}  
          >
            Ngày
          </div>
          <div 
            className={`px-8 py-2 flex justify-center items-center cursor-pointer ${ mode === 'weekly' ? 'filter-active' : ''}`}
            onClick={() => setMode('weekly')}
          >
            Tuần
          </div>
          
        </div>
        <div className="pl-5">
          <div>Lịch sử: {timeframe} kỳ</div>
          <div>
            <Slider
              value={timeframe}
              aria-label="Default"
              valueLabelDisplay="auto"
              onChange={(e) => setTimeframe(e.target.value)}
              max={maxTimeframe}
              min={1}
            />
          </div>
        </div>
      </div>
      <div className="xl:flex justify-between mt-4 overflow-auto">
        {loading ? '' : <div className='flex justify-center'>
          <div class="p-1 w-5 text-center whitespace-nowrap flex flex-col justify-center">
            <span class="block transform -rotate-90 origin-center mt-20">Sức mạnh (S-Strength)</span>
          </div>
          <div className='flex flex-col justify-between mb-10 mr-1 text-center'>
            <div>113</div>
            <div>107</div>
            <div>101</div>
            <div>95</div>
            <div>89</div>
          </div>
          <div>
            <div> <RRGChart rrg={rrg} load={loading} time={timeframe} win={window} setTime={setTimeframe} setMaxTime={setMaxTimeframe} mode={mode}/></div>
            <div className='flex justify-between mt-1 text-center'>
              <div>89</div>
              <div>95</div>
              <div>101</div>
              <div>107</div>
              <div>113</div>
            </div>
            <div className='text-center'>Xu hướng (S-Trend)</div>
          </div>
        </div>}
        <div className="w-full xl:w-1/3 rounded-md bg-white shadow-md p-2 mb-11">
          <div className="flex justify-between px-4 py-2">
            <div className="text-base font-extrabold">Rổ cổ phiếu so sánh</div>
            <div>
              {datas.length}/{datas.length} cổ phiếu
            </div>
          </div>
          {loading ? (
            'Loading...'
          ) : (
            <div>
              <div className="overflow-auto h-[350px]">
                {datas.map((item) => (
                  <div className="flex justify-between border rounded p-2 mt-2">
                    <div className="flex">
                      <div className="font-extrabold text-[#1AAF76] mr-4">
                        {item[0]}
                      </div>
                      <div>{item[1]}</div>
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => deleteItem(item)}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.99965 10.8782L12.5606 13.4391C12.6759 13.5545 12.821 13.6135 12.9957 13.6162C13.1703 13.6189 13.318 13.5598 13.4387 13.4391C13.5595 13.3184 13.6198 13.172 13.6198 13C13.6198 12.828 13.5595 12.6817 13.4387 12.5609L10.8778 10L13.4387 7.4391C13.5541 7.32373 13.6132 7.1787 13.6158 7.00402C13.6185 6.82935 13.5595 6.68166 13.4387 6.56094C13.318 6.4402 13.1717 6.37983 12.9997 6.37983C12.8277 6.37983 12.6813 6.4402 12.5606 6.56094L9.99965 9.12183L7.43874 6.56094C7.32336 6.44555 7.17833 6.38652 7.00365 6.38385C6.82899 6.38117 6.68129 6.4402 6.56057 6.56094C6.43983 6.68166 6.37947 6.82802 6.37947 7.00002C6.37947 7.17202 6.43983 7.31838 6.56057 7.4391L9.12147 10L6.56057 12.5609C6.44518 12.6763 6.38615 12.8213 6.38349 12.996C6.38081 13.1707 6.43983 13.3184 6.56057 13.4391C6.68129 13.5598 6.82765 13.6202 6.99965 13.6202C7.17165 13.6202 7.31801 13.5598 7.43874 13.4391L9.99965 10.8782ZM10.001 17.9167C8.9061 17.9167 7.87691 17.7089 6.91347 17.2933C5.95001 16.8778 5.11194 16.3138 4.39926 15.6015C3.68656 14.8891 3.12235 14.0514 2.70661 13.0884C2.29088 12.1253 2.08301 11.0964 2.08301 10.0014C2.08301 8.90647 2.29079 7.87728 2.70634 6.91383C3.1219 5.95037 3.68586 5.1123 4.39822 4.39962C5.11059 3.68693 5.94829 3.12271 6.91132 2.70698C7.87433 2.29124 8.90331 2.08337 9.99826 2.08337C11.0932 2.08337 12.1224 2.29115 13.0858 2.70671C14.0493 3.12226 14.8874 3.68622 15.6 4.39858C16.3127 5.11096 16.877 5.94866 17.2927 6.91169C17.7084 7.8747 17.9163 8.90368 17.9163 9.99862C17.9163 11.0936 17.7085 12.1228 17.293 13.0862C16.8774 14.0497 16.3135 14.8877 15.6011 15.6004C14.8887 16.3131 14.051 16.8773 13.088 17.2931C12.125 17.7088 11.096 17.9167 10.001 17.9167Z"
                          fill="#344054"
                        />
                      </svg>
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full relative">
                <input
                  className="border rounded p-2 mt-8 cursor-pointer w-full outline-none"
                  placeholder="Thêm mã cổ phiếu"
                  onChange={(event) => search(event)}
                  id='inputAdd'
                />
                {dataStocks !== [] ? (
                  <div className="rounded shadow-md h-40 overflow-auto z-10 absolute bg-white">
                    {dataStocks.map((t) => (
                      <div className="bg-white m-2 px-4 sm:p-1 cursor-pointer hover:bg-gray-100">
                        <div className="w-full">
                          <div
                            className="blocktext-sm font-medium text-gray-900"
                            onClick={(e) => handleSearch(e, t)}
                          >
                            <div className="flex justify-center items-center">
                              <div className="w-8 h-8 mr-4">
                                <img src={t.logo} alt="" />
                              </div>
                              <div className="font-bold mr-4">{t.ticker}</div>
                              <div className="w-5/6">{t.company_name}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  ''
                )}
              </div>
              {/* <div className="flex justify-end mt-2">
                <div className="py-2 px-4 border rounded mr-2 cursor-pointer">
                  Mặc định
                </div>
                <div className="py-2 px-4 text-white rounded bg-[#1AAF76] cursor-pointer">
                  Lưu lại
                </div>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
