import React from 'react';

const Updating = () => {
  return (
    <div className="w-full flex justify-center bg-[#F7FAFF]">
      <div className="w-full max-w-[1390px]">
        {' '}
        <div className="bg-tintMain mx-2 sm:mx-4 lg:mx-20 mb-10 text-center">
          Đang cập nhật ...
        </div>
      </div>
    </div>
  );
};

export default Updating;
