import { useEffect, useReducer } from 'react';
import {
  currentFinanceInformation,
  stockInformation,
  stockLogo,
} from '../../common/api/tools';
import Loading from '../../components/loading/Loading';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        stockInfo: action.payload[0],
        stockCur: action.payload[1],
        logoCompany: action.payload[2],
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const InfoStocks = (props) => {
  const {ticker} = props ?? "VCB";
  const [{ loading, stockInfo, stockCur, logoCompany }, dispatch] = useReducer(
    reducer,
    {
      loading: true,
      error: '',
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [stockInformationRes, currentFinanceInformationRes, stockLogoRes] = await Promise.all([
          stockInformation(ticker),
          currentFinanceInformation(ticker),
          stockLogo(ticker),
        ]);
        const stockInformationData = stockInformationRes?.data || [];
        const currentFinanceInformationData = currentFinanceInformationRes?.data[0] || [];
        const stockLogoData = stockLogoRes?.data[0] || [];
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: [stockInformationData, currentFinanceInformationData, stockLogoData],
        });
      } catch (err) {
      }
    };
  
    fetchData();
  }, [ticker]);

  function formatNumber(number) {
    if(!number) return '';
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <div className="tool-header">
      {loading ? (
        <Loading></Loading>
      ) : (
        <div>
          <div className="w-full px-5 mt-10 ">
            <div className="w-full">
              <div className="w-full box-style">
                <div className="w-full flex">
                  <div className="w-1/2 md:p-1 justify-center">
                    <img src={logoCompany} alt="" />
                  </div>
                </div>
                <div className="text-md font-bold w-full">
                    {stockInfo[0].ticker} - {stockInfo[0].company_name}
                </div>
                <div className="flex text-sm flex-auto">
                    <div className="mr-3 rounded p-1 bg-blue-100">
                        {stockInfo[0].sector_level_2}
                    </div>
                </div>
                <div className="w-full mt-3">
                  <div className="flex justify-between">
                    <div>
                      <div className="mb-1">Giá cổ phiếu</div>{' '}
                      <div className="flex">
                        <div className="text-base flex justify-center items-center min-[600px]:text-lg font-extrabold mr-1 min-[400px]:mr-3 sm:mr-4">
                          {stockCur.price}
                        </div>
                        <div
                          className={`text-base flex justify-center items-center min-[600px]:text-lg font-extrabold mr-1 min-[400px]:mr-3 sm:mr-4 ${
                            stockCur.price_change > 0
                              ? 'text-green'
                              : 'text-red'
                          }`}
                        >
                          {stockCur.price_change}
                          {stockCur.price_change < 0 ? (
                            <svg
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="ml-2"
                            >
                              <path
                                d="M0.248399 0.248751C0.15181 0.345328 0.103516 0.462416 0.103516 0.600016C0.103516 0.737616 0.15181 0.854706 0.248399 0.951283L7.79713 8.50003H3.66633C3.52467 8.50003 3.40592 8.54797 3.3101 8.64383C3.21427 8.73971 3.16635 8.85851 3.16635 9.00023C3.16635 9.14197 3.21427 9.26069 3.3101 9.35642C3.40592 9.45214 3.52467 9.5 3.66633 9.5H8.8971C9.06782 9.5 9.21093 9.44226 9.32642 9.32677C9.4419 9.21128 9.49965 9.06817 9.49965 8.89745V3.66668C9.49965 3.52502 9.45172 3.40627 9.35585 3.31043C9.25997 3.21461 9.14117 3.1667 8.99945 3.1667C8.85772 3.1667 8.73899 3.21461 8.64327 3.31043C8.54754 3.40627 8.49968 3.52502 8.49968 3.66668V7.79748L0.950932 0.248751C0.854355 0.152162 0.737266 0.103868 0.599666 0.103868C0.462066 0.103868 0.344977 0.152162 0.248399 0.248751Z"
                                fill="#D72F2F"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-arrow-up-right"
                              viewBox="0 0 16 16"
                              className="ml-2"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"
                              />
                            </svg>
                          )}
                        </div>
                        <div
                          className={`text-base flex justify-center items-center min-[600px]:text-lg font-extrabold mr-1 min-[400px]:mr-3 min-[600px]:mr-2 sm:mr-4 ${
                            stockCur.price_change > 0
                              ? 'text-green'
                              : 'text-red'
                          }`}
                        >
                          {stockCur.pct_change}%
                        </div>
                        <div className="text-sm font-bold border p-1 sm:p-2 rounded-md">
                          {stockInfo[0].exchange}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full mt-4 xl:mt-2 text-base">
              <div className="w-full box-style mr-1 min-[400px]:mr-4">
                <div className="min-[600px]:flex justify-between">
                  <div>KLGD TB 10 ngày</div>
                  <div className="flex">
                    <div className="font-bold mr-2">
                      {formatNumber(stockCur['average_volume_10d'])}
                    </div>
                    <div>{stockCur.outstanding_shares_unit}</div>
                  </div>
                </div>
                <div className="min-[600px]:flex justify-between mt-2">
                  <div>Giá thấp nhất 52 tuần</div>
                  <div className="flex">
                    <div className="font-bold">{stockCur.price_lowest_52w}</div>
                  </div>
                </div>
                <div className="min-[600px]:flex justify-between mt-2">
                  <div>Giá cao nhất 52 tuần</div>
                  <div className="flex">
                    <div className="font-bold">
                      {stockCur.price_highest_52w}
                    </div>
                  </div>
                </div>
                <div className="min-[600px]:flex justify-between mt-2">
                  <div>ROA</div>
                  <div className="flex">
                    <div className="font-bold">{stockCur.ROA}</div>
                  </div>
                </div>
                <div className="min-[600px]:flex justify-between mt-2">
                  <div>BETA</div>
                  <div className="flex">
                    <div className="font-bold">{stockCur.beta}</div>
                  </div>
                </div>
                <div className="min-[600px]:flex justify-between">
                  <div>EPS (D)</div>
                  <div className="flex">
                    <div className="font-bold mr-2">
                      {formatNumber(stockCur.EPS?.toFixed(2))}
                    </div>
                    <div>đồng</div>
                  </div>
                </div>
                <div className="min-[600px]:flex justify-between mt-2">
                  <div>PEG</div>
                  <div className="flex">
                    <div className="font-bold mr-2">{stockCur.PEG}</div>
                    <div>lần</div>
                  </div>
                </div>
                <div className="min-[600px]:flex justify-between mt-2">
                  <div>ROE</div>
                  <div className="flex">
                    <div className="font-bold mr-2">{stockCur.ROE}</div>
                  </div>
                </div>
                <div className="min-[600px]:flex justify-between mt-2">
                  <div>BVPS</div>
                  <div className="flex">
                    <div className="flex">
                      <div className="font-bold mr-2">
                        {formatNumber(stockCur.BVPS?.toFixed(2))}
                      </div>
                      <div>đồng</div>
                    </div>
                  </div>
                </div>
                <div className="min-[600px]:flex justify-between mt-2">
                  <div>P/B (D)</div>
                  <div className="flex">
                    <div className="flex">
                      <div className="font-bold mr-1">
                        {stockCur['P/B']?.toFixed(2)}
                      </div>
                      <div>lần</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoStocks;
