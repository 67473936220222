import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ColumnChart = (props) => {
  const { data } = props;
  let chartData = [];
  let dates = [];
  for (let i = 0; i < data.length; i++) {
    chartData = [...chartData, data[i].trading_value];
    let d = data[i].tradingdate.slice(0, 5);
    dates = [...dates, d.replaceAll('-', '/')];
  }
  const series = [
    {
      name: 'Giá trị giao dịch',
      data: chartData,
    },
  ];
  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        colors: {
          ranges: [
            {
              from: -900000000000,
              to: 0,
              color: '#d72f2f',
            },
            {
              from: 0,
              to: 900000000000,
              color: '#1aaf76',
            },
          ],
        },
        borderRadius: 4,
        columnWidth: '95%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      opposite: true,
      crosshairs: {
        show: false,
      },
      labels: {
        formatter: function (val) {
          return (val >= 1 || val <= -1) ? val.toFixed(2) + 'T' : (val*1000).toFixed(0) + ' triệu';
        },
      },
    },
    xaxis: {
      categories: dates,
    },
    grid: {
      show: false,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  };

  return (
    <div id="chart" className="w-full">
      {false ? (
        <div>Loading...</div>
      ) : (
        <div id="chart-column" className="w-full">
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={150}
            width="100%"
          />
        </div>
      )}
    </div>
  );
};
export default ColumnChart;
