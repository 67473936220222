import React, { useEffect, useReducer, useRef } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import CandlestickCharts from './tools/technical-chart/CandlestickCharts';
import { stockPriceHistory } from '../common/api/tools';
import { AdvancedChart } from "react-tradingview-embed";

const reducer = (state, action) => {
  switch (action.type) {
      case 'FETCH_REQUEST':
          return { ...state, loading: true };
      case 'FETCH_SUCCESS':
          return {
              ...state,
              series: action.payload,
              loading: false,
          };
      case 'FETCH_FAIL':
        return { ...state, loading: false, error: action.payload };
    case 'CHANGE-TICKER':
        return { currentTicker: action.payload.ticker ,loading: false }
      default:
          return state;
  }
};

const TechnicalChart = () => {
  const [{ loading, series, currentTicker }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
    currentTicker: ''
  });
  const container = useRef();
  const [tickerDefault] = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const ticker = searchParams.get('ticker') ?? tickerDefault;
  useEffect(() => {
    console.log(currentTicker);
    dispatch({
      type: 'CHANGE-TICKER',
      payload: { ticker: "HOSE:" + ticker }
    })
  }, []);

  const options = {
    "height": 400,
    "symbol": currentTicker,
    "interval": "D",
    "timezone": "Etc/UTC",
    "theme": "light",
    "style": "1",
    "locale": "vi_VN",
    "enable_publishing": false,
    "hide_top_toolbar": true,
    "hide_legend": true,
    "allow_symbol_change": true,
    "save_image": false,
  }
  return (
    <div className="w-full flex justify-center bg-[#F7FAFF]">
      <div className="w-full">
        {' '}
        <div className="bg-tintMain mb-10">
          <div>
            <div className='text-2xl font-extrabold mb-4'>Bảng giá</div>
            {!loading &&
              <AdvancedChart widgetProps={options} />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalChart;
