import React from 'react';
import { Outlet, useOutletContext, useSearchParams } from 'react-router-dom';
import ToolHeader from './tools/ToolHeader';

const Tool = () => {
  const [tickerDefault] = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const ticker = searchParams.get('ticker') ?? tickerDefault;
  
  console.log(ticker);
  return (
    <div className="w-full flex justify-center bg-[#F7FAFF] overflow-x-hidden">
      <div className="w-full max-w-[1390px]">
        {' '}
        <ToolHeader context={[ticker]} />
        <div className="bg-tintMain mx-2 sm:mx-4 lg:mx-20 mb-10">
          <Outlet context={[ticker]} />
        </div>
      </div>
    </div>
  );
};

export default Tool;
