import React, { useEffect, useReducer } from 'react';
import ReactApexChart from 'react-apexcharts';
import { historicalPe } from '../../common/api/tools';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        series: Object.entries(action.payload),
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const PE = (props) => {
  const [{ loading, series }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const {ticker} = props ?? 'VCB';
  let seriesData = [];
  if (series) {
    for (const [name, values] of series) {
        const formattedData = values.map(entry => {
            const [dateString, y] = entry;
            const [day, month, year] = dateString.split('-');
            const formattedDate = new Date(`${year}-${month}-${day}`);
            return { x: formattedDate, y: y.toFixed(2) };
        });

        seriesData.push({
            name: name,
            data: formattedData
        });
        break;
    }
  }

  const options = {
    colors: ['#EA9127', '#60a5fa', '#9ca3af'],
    chart: {
      id: 'area-datetime',
      type: 'line',
      height: 350,
      width: '100%',
      zoom: {
        autoScaleYaxis: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      labels: {
          format: 'dd/MM/yyyy',
      },
      tickAmount: 6,
    },
    tooltip: {
      x: {
        formatter: function (val) {
          var date = new Date(val);
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          day = day < 10 ? '0' + day : day;
          month = month < 10 ? '0' + month : month;
          return day + '/' + month + '/' + year;
        },
      },
    },
    stroke: {
      color: ['#EA9127', '#60a5fa', '#9ca3af'],
      width: 2,
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await historicalPe(ticker);
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: res.data,
        });
      } catch (err) {}
    };
    fetchData();
  }, [ticker]);

  return (
    <div id="chart" className="w-full">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div id="chart-timeline" className="w-full">
          <ReactApexChart
            options={options}
            series={seriesData}
            type="line"
            height={400}
          />
        </div>
      )}
    </div>
  );
};
export default PE;
