import React, { useEffect, useReducer, useState } from 'react';
import CandlestickCharts from '../tools/technical-chart/CandlestickCharts';
import { stockPriceHistory } from '../../common/api/tools';

const reducer = (state, action) => {
  switch (action.type) {
      case 'FETCH_REQUEST':
          return { ...state, loading: true };
      case 'FETCH_SUCCESS':
          return {
              ...state,
              series: action.payload,
              loading: false,
          };
      case 'FETCH_FAIL':
          return { ...state, loading: false, error: action.payload };
      default:
          return state;
  }
};

const TechnicalChart = (props) => {
  const [{ loading, series }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
});
  const { ticker } = props ?? 'VCB';
  const [modeChangePrice, setModeChangePrice] = useState('all');
  useEffect(() => {
    const fetchData = async () => {
        try {
            const res = await stockPriceHistory(modeChangePrice, ticker, 'candle');
            res.data.volume_data = res.data.volume_data.map((volume, index) => {
              const priceChange = res.data.price_data[index].close - res.data.price_data[index].open;
              if (priceChange >= 0) return { ...volume, color: '#D72F2F' };
              else return { ...volume, color: '#00BF71' };
              
            });
            dispatch({
                type: 'FETCH_SUCCESS',
                payload: res,
            });
        } catch (err) { }
    };
    fetchData();
}, [ticker, modeChangePrice]);
  return (
    <div className="w-full flex justify-center bg-[#F7FAFF]">
      <div className="w-full max-w-[1390px]">
        {' '}
        <div className="mx-2 my-10">
          <div>
            <div className="flex justify-end mb-4">
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === '1m'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('1m')}
              >
                1M
              </div>
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === '3m'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('3m')}
              >
                3M
              </div>
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === '6m'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('6m')}
              >
                6M
              </div>
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === '1y'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('1y')}
              >
                1Y
              </div>
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === '3y'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('3y')}
              >
                3Y
              </div>
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === 'all'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('all')}
              >
                ALL
              </div>
            </div>
            {!loading && <CandlestickCharts data={series}/>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalChart;
