import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import './style.css';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  Cell,
  ResponsiveContainer
} from 'recharts';
import { ketQuaGiaoDichNdtnn } from '../../common/api';
import { getForeignTradingResult, getPropTradingresult } from '../../store/reducers/homepage/actions';
import Loading from '../loading/Loading';
import moment from 'moment';

const ValueChart = (props) => {
  const { mode, typeMode, getForeignTradingResult, getPropTradingresult, dataForeign, dataProp } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  let unit = '';
  useEffect(() => {
    if(typeMode === 'TD'){
      getPropTradingresult(mode)
    }else if(typeMode === 'NDTTN'){
      getForeignTradingResult(mode)
    }
  }, [typeMode, mode]);
  useEffect(()=>{
    if(typeMode === 'TD'){
      setData(dataProp)
    }else if(typeMode === 'NDTTN'){
      setData(dataForeign)
    }
  },[dataForeign, dataProp])
  return (
    <div id="chart w-full" className="value-chart w-full h-[350px]">
      {loading ? (
        <Loading />
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={600}
            height={300}
            data={data}
            margin={{
              top: 5,
              bottom: 5,
              left: -10
            }}
            >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="TradingDate" tickFormatter={(value)=>moment(value).format('DD/MM/YYYY')}/>
            <YAxis />
            <Tooltip
            content={(value)=>{
              let tmpvalue
              data.map(el=>{
                if(el.TradingDate === value.label){
                  tmpvalue = el.TradeValue
                }
              })
              return(
                <div className='bg-white p-2 flex flex-col border-0'>
                  <span>Ngày: {moment(value.label).format('DD/MM/YYYY')}</span>
                  <span>Giá trị giao dịch: {tmpvalue}</span>
                </div>
              )
            }}
            />
            <ReferenceLine y={0} stroke="#000" />
            <Bar dataKey="TradeValue" unit={unit}>
              {data.map((el,index) => {
                let fill = '#1AAF76'
                if(el.TradeValue < 0){
                  fill = '#F04438'
                }
                return(
                  <Cell fill={fill} key={index}/>
                  )
                })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    dataForeign: state.homePage.dataForeignTradingResult,
    dataProp: state.homePage.dataPropTradingResult,
  }
}

export default connect(mapStateToProps, {
  getForeignTradingResult,
  getPropTradingresult,
})(ValueChart);
