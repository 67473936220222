import axios from 'axios';
import { baseURL2, header } from '../const';
const axiosInstance = axios.create({
	baseURL: baseURL2,
	headers: header,
});

export async function login({ username, password }) {
	try {
		const res = await axiosInstance.post('/auth/login/', {
			username,
			password,
		});
		return {
			success: true,
			data: res.data,
		};
	} catch (error) {
		return {
			success: false,
            message: error.response.data.detail || 'Something was wrong!'
		};
	}
}

export async function register({ username, password, phone }) {
	try {
		const res = await axiosInstance.post('/auth/register/', {
			username,
			password,
            phone
		});
		return {
			success: true,
			data: res.data,
		};
	} catch (error) {
		return {
			success: false,
			message: error.response.data.detail || 'Something was wrong!',
		};
	}
}


export async function loginWithGoogle(accessToken) {
    try {
        const res = await axiosInstance.post(
			`/auth-google/?access_token=${accessToken}`,
		);
        return {
			success: true,
			data: res.data,
		};
    } catch (error) {
        return {
			success: false,
			message: error.response.data.detail || 'Something was wrong!',
		};
    }
}

export async function forgotPassword(email) {
    try {
        const res = await axiosInstance.post(`/auth/forgot-password/?email=${email}`);
		return {
			success: true,
			data: res.data,
		};
    } catch (error) {
        return {
			success: false,
			message: error.response.data.detail || 'Something was wrong!',
		};
    }
}

export async function resetPassword({ access_token, new_password }) {
	try {
		const res = await axiosInstance.post(
			`/auth/reset-password/?access_token=${access_token}&new_password=${new_password}`,
		);
		return {
			success: true,
			data: res.data,
		};
	} catch (error) {
		return {
			success: false,
			message: error.response?.data.detail || 'Something was wrong!',
		};
	}
}

export default axiosInstance;
