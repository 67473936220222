import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getSectorValuation } from "../../../../store/reducers/homepage/actions";
import CustomizedLabel from "./CustomizedLabel";

const Valuation = (props) => {
  const {getSectorValuation, data} = props
  const [maxData, setMaxData] = useState(17)
  const [dataChart, setDataChart] = useState()
  useEffect(()=>{
    getSectorValuation('all')
  },[])

  useEffect(()=>{
    let tmp = 0
    data.map(el => {
      if(el.value > tmp){
        tmp = el.value
      }
      el.fill = el.value > 0 ? '#1AAF76' : '#D72F2F';
    })
    setMaxData(tmp)
    // data.sort((a,b)=> b.value - a.value)
    setDataChart(data)
  },[data])

  return(
    <div className="w-full mb-6 Valuation">
      <div className="font-extrabold text-[24px] text-title-color " onClick={()=>{console.log(data);}}>
        Định giá
      </div>
      <div className="w-full">
        <div className="w-full font-bold my-3">
          Định giá PE các nhóm ngành
        </div>
        <div className="w-full h-[500px] overflow-y-auto">
          {
            dataChart && 
            <div className="w-full h-full flex">
              <div className="w-2/5 h-full flex flex-col justify-around mr-6 width-item">
                {dataChart.map((el,index) => {
                  return(
                    <div className="flex justify-between" key={index}>
                      <div className="font-bold text-sm overflow-text">{el.name}</div>
                      <div className={`${el.value > 0 ? 'text-[#1AAF76]' : 'text-[#D72F2F]'} font-bold text-sm`}>{el.value}</div>
                    </div>
                  )
                })}
              </div>
              <div className="w-3/5 h-full width-item flex-auto">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    layout="vertical"
                    barCategoryGap={20}
                    data={data}
                    margin={{
                      top: -2,
                    }}
                  >
                    <XAxis type="number" hide="true" domain={[0,maxData]}/>
                    <YAxis type="category" dataKey="name" hide/>
                    <Bar dataKey="value" fill={(entry) => entry.fill} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return{
    data: state.homePage.dataSectorValuation,
  }
}

export default connect(mapStateToProps, {
  getSectorValuation
})(Valuation)