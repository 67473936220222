import { Routes, Route } from 'react-router-dom';
import { LandingPage, Home, HomePage, Tool } from './components';
import { Overview } from './components';
import Suggest from './components/Suggest';
import Analysis from './components/Analysis';
import TechnicalChart from './components/TechnicalChart';
import FinancialChart from './components/tools/FinancialChart';
import FinancialFigures from './components/tools/FinancialFigures';
import DefaultPage from './components/DefaultPage';
import Updating from './components/Updating';
import ResetPassword from './components/ResetPassword';
import CandlestickTool from './components/candlesstickTool/CandlestickTool';
import FilterPage from './components/filterTools/FilterPage';
function App() {
  return (
    <Routes data-theme="light">
      <Route path="/" element={<Home />}>
        <Route path="home" element={<HomePage />} />
        <Route path="/chat-app" element={<DefaultPage />} />
        <Route path="tools" element={<Tool />}>
          <Route path="overview" element={<Overview />} />
          <Route path="financial-chart" element={<FinancialChart />} />
          <Route path="financial-figures" element={<FinancialFigures />} />
          <Route path="technical-chart" element={<TechnicalChart />} />
          <Route path="compare" element={<Updating />} />
          <Route path="administration" element={<Updating />} />
          <Route path="information" element={<Updating />} />
          <Route path="note" element={<Updating />} />
        </Route>
        <Route path="suggest" element={<Suggest />} />
        <Route path="analysis" element={<Analysis />} />
        <Route path="technical-chart" element={<TechnicalChart />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path='candlestick-chart' element={<CandlestickTool />} />
        <Route path='filter-tool' element={<FilterPage />} />
      </Route>
      <Route path="/dashboard" element={<LandingPage />} />
    </Routes>
  );
}

export default App;
