import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import icon from '../../assets/png/logo_company.png';
import { ReactComponent as Increase } from '../../assets/svg/homepage/increase.svg';
import { getTopStockIncreaseRate, getTopStockDecreaseRate } from '../../store/reducers/homepage/actions';
import { getLogoTop } from './actions/actions';
import moment from 'moment';
const TopPriceIncrease = (props) => {
  const { mode, topStockIncreaseRate, topStockDecreaseRate,
    getTopStockIncreaseRate,
    getTopStockDecreaseRate,
    getLogoTop,
    logoIncrease,
    logoDecrease,
    topPrice  } = props;
  // const [topPrice, setTopPrice] = useState(1)
  const [data, setData] = useState([])

  useEffect(() => {
    if(topPrice === 1){
      setData(topStockIncreaseRate)
    }else{
      setData(topStockDecreaseRate)
    }
  }, [topPrice, mode])

  useEffect(()=>{
    if(topStockDecreaseRate?.length !== 0){
      let listCode = topStockDecreaseRate[0]?.StockCode
      for (let i = 1; i < topStockDecreaseRate.length; i++) {
        listCode = listCode + ',' + topStockDecreaseRate[i]?.StockCode
      }
      getLogoTop(listCode, 'FETCH-LOGO-TOP-STOCK-DECREASE')
    }
    if(topPrice === 2){
      setData(topStockDecreaseRate)
    }
  },[topStockDecreaseRate])

  useEffect(()=>{
    if(topStockIncreaseRate?.length !== 0){
      let listCode = topStockIncreaseRate[0]?.StockCode
      for (let i = 1; i < topStockIncreaseRate.length; i++) {
        listCode = listCode + ',' + topStockIncreaseRate[i]?.StockCode
      }
      getLogoTop(listCode, 'FETCH-LOGO-TOP-STOCK-INCREASE')
    }
    if(topPrice === 1){
      setData(topStockIncreaseRate)
    }
  },[topStockIncreaseRate])

  return (
    <div className="w-full">
      <div className="font-bold text-sm mb-4 flex">
        {
          topPrice === 1 ? <div className="">Top tăng giá</div>: <div className="">Top giảm giá</div>
        }
        {
          data.length !== 0 && <div className="">
            ({moment(data[0].TradingDate,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')})
          </div>
        }
      </div>
      <div className="h-[460px] w-full bg-white items overflow-visible rounded-lg p-[16px]">
        <div className="flex justify-between h-full">
          <div className="flex-auto flex flex-col gap-2 basis-1/3">
            <div className="mr-3 font-medium text-[13px] whitespace-normal h-10">
                Mã cổ phiếu
            </div>
            {
              data.map((el, index) => {
                return(
                  <div className="flex h-8 font-bold text-[13px] items-center" key={index}>
                    <img className="w-8 h-8 mr-2 object-contain" src={topPrice === 1? logoIncrease[index]: logoDecrease[index]} alt="" />
                    {el.StockCode}
                  </div>
                )
              })
            }
          </div>
          <div className="flex-auto flex flex-col gap-2 basis-1/3">
            <div className="mr-3 font-medium text-[13px] text-center whitespace-normal h-10">
                Thay đổi giá (%)
            </div>
            {
              data.map((el, index) => {
                return(
                  <div className={`flex h-8 text-[13px] font-bold items-center justify-center
                  ${
                    topPrice === 1? 'text-[#1AAF76]' : 'text-[#D72F2F]'
                  }
                  `} key={index}>
                    <Increase className={`my-auto mr-1
                      ${
                        topPrice === 1? '' : 'rotate-180'
                      }
                    `} />
                    {el.PerChange.toFixed(2)}%
                  </div>
                )
              })
            }
          </div>
          <div className="flex-auto flex flex-col gap-2 basis-1/3">
            <div className="font-medium text-[13px] text-right whitespace-normal h-10">
                Khối lượng giao dịch
            </div>
            {
              data.map((el, index) => {
                return(
                  <div className="h-8 text-[13px] flex justify-end items-center" key={index}>
                    {el.AvgVol.toFixed(0)}
                  </div>
                )
              })
            }
          </div>       
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return{
    topStockIncreaseRate: state.homePage.topStockIncreaseRate,
    topStockDecreaseRate: state.homePage.topStockDecreaseRate,
    logoIncrease: state.moverment.logoTopStockIncrease,
    logoDecrease: state.moverment.logoTopStockDecrease,
  }
}

export default connect(mapStateToProps, {
  getTopStockIncreaseRate,
  getTopStockDecreaseRate,
  getLogoTop
})(TopPriceIncrease)
