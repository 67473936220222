import axios from 'axios';
import { baseURL2 } from '../const';

const axiosInstance = axios.create({
  baseURL: baseURL2 + '/finance',
});
export default axiosInstance;

export async function cdktGetReportData(ticker, tab, currentYear, currentQuater, type) {
  try {
    const res = await axiosInstance.get(
      `/${tab}_get_report_data?ticker=${ticker}&mode=${type}&end_quarter=${currentQuater}&end_year=${currentYear}&num_histories=3`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function historicalBiz(ticker, type, number) {
  try {
    const res = await axiosInstance.get(
      `/report_income_statement?ticker=${ticker}&num_histories=${number}&mode=${type}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function historicalBalance(ticker, type, number) {
  try {
    const res = await axiosInstance.get(
      `/report_balance_sheet?ticker=${ticker}&num_histories=${number}&mode=${type}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

