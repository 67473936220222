import Chart1 from '../../assets/png/landingpage/Chart1.png';
import Item2 from '../../assets/png/landingpage/item2.png';
import Item2Res from '../../assets/png/landingpage/item2Res.png';
import Item3 from '../../assets/png/landingpage/item3.png';
import Item3Res from '../../assets/png/landingpage/item3Res.png';
import Item4 from '../../assets/png/landingpage/item4.png';
import Item4Res from '../../assets/png/landingpage/item4Res.png';
import Item5 from '../../assets/png/landingpage/item5.png';
import Item5Res from '../../assets/png/landingpage/item5Res.png';
import RightBoard from '../../assets/png/landingpage/RightBoard.png';
import LeftChart from '../../assets/png/landingpage/LeftChart.png';
import RightchartWithPopup from '../../assets/png/landingpage/RightchartWithPopup.png';
import MobileScreen from '../../assets/png/landingpage/MobileScreen.png';

import './Main.css';
const Main = () => {
  return (
    <div className="main">
      <div className="relative hidden min-[420px]:flex">
        <div className="absolute left-0 top-48 z-0">
          <img src={Item2} alt="" />
        </div>
      </div>
      <div className="relative min-[420px]:hidden">
        <div className="absolute left-0 -top-8 z-0">
          <img src={Item2Res} alt="" />
        </div>
      </div>
      <div className="md:flex justify-between items-center px-2 min-[420px]:px-8 xl:px-28 mb-10 relative z-10">
        <div>
          <img src={Chart1} alt="" />
        </div>
        <div className="px-4 md:ml-8 md:px-0 lg:ml-16 xl:ml-32">
          <div className="hidden sm:flex md:block">
            <div className="w-full lg:w-5/6 title-green">
              Dễ dàng theo dõi các biến động cổ phiếu với
            </div>
            <div className="title-gradient">GoStock</div>
          </div>
          <div className="sm:hidden">
            <div className="w-full lg:w-5/6 title-green">
              Dễ dàng theo dõi các biến
            </div>
            <div className="flex justify-start items-start min-[420px]:justify-center">
              <div className="title-green mr-2">động cổ phiếu với</div>
              <div className="title-gradient">GoStock</div>
            </div>
          </div>
          <div className="min-[420px]:px-16 md:px-0 text-start min-[420px]:text-center md:text-start font-normal text-sm min-[420px]:text-base w-full lg:w-2/3 mt-3 lg:mt-6">
            Theo dõi cổ phiếu mà bạn đang mong muốn với công nghệ real-time thật
            dễ dàng và chuẩn xác.
          </div>
          <div className="flex justify-center md:justify-start items-center">
            <button className="read-more mt-6 lg:mt-10">Tìm hiểu thêm</button>
          </div>
        </div>
      </div>
      <div className="relative min-[420px]:hidden">
        <div className="absolute right-0 z-0 -top-6">
          <img src={Item3Res} alt="" />
        </div>
      </div>
      <div className="md:flex justify-between items-center px-2 min-[420px]:px-8 xl:px-28 mb-10 relative z-10 grid">
        <div className="px-4 min-[420px]:px-0 order-last md:order-first ml-0 md:ml-8 lg:ml-16 xl:ml-32 w-full md:w-3/4 lg:w-7/12">
          <div>
            <div className="w-full lg:w-11/12 title-green">
              Tìm ra những cổ phiếu phát triển trong tương lai
            </div>
          </div>
          <div className="min-[420px]:px-8 md:px-0 text-start min-[420px]:text-center md:text-start font-normal text-sm min-[420px]:text-base min-[420px]:w-full lg:w-5/6 mt-3 lg:mt-6 trans-f">
            Sử dụng công nghệ AI, chúng tôi sẽ giúp bạn tìm ra những cổ phiếu có
            tiềm năng tăng giá trong thời gian tới.
          </div>
          <div>
            <div className="w-full lg:w-11/12 title-green mt-6 lg:mt-10">
              Quản lí tài khoản của bạn
            </div>
          </div>
          <div className="min-[420px]:px-8 md:px-0 text-start min-[420px]:text-center md:text-start font-normal text-sm min-[420px]:text-base min-[420px]:w-full lg:w-5/6 mt-3 lg:mt-6 trans-f">
            Mua và bán các cổ phiếu, theo dõi tất cả chỉ trong một ví duy nhất
            sử dụng thuyết Modern Portfolio được giới thiệu bởi chuyên gia kinh
            tế Harry Markowitz.
          </div>
          <div className="flex justify-center md:justify-start items-center">
            <button className="read-more mt-6 lg:mt-10">Tìm hiểu thêm</button>
          </div>
        </div>
        <div className="ml-1 order-first md:order-last">
          <img src={RightBoard} alt="" />
        </div>
      </div>
      <div className="relative hidden min-[420px]:flex">
        <div className="absolute right-0 z-0 bottom-72">
          <img src={Item3} alt="" />
        </div>
      </div>
      <div className="lg:flex justify-between items-start px-2 min-[420px]:px-8 xl:px-28 mb-10 relative z-10">
        <div>
          <img src={LeftChart} alt="" />
        </div>
        <div className="min-[420px]:ml-4 w-full px-4 md:px-32 lg:px-0 lg:w-1/2">
          <div>
            <div className="w-full text-center lg:text-start xl:w-5/6 title-green">
              Phát hiện dòng tiền thông minh tới từng nhóm ngành và cổ phiếu
            </div>
          </div>
          <div className="text-start min-[420px]:text-center lg:text-start font-normal text-sm min-[420px]:text-base w-full xl:w-3/4 mt-6">
            Sử dụng biểu đồ RRG của chúng tôi để phát hiện dòng tiền thông minh
            tới từng nhóm ngành và cổ phiếu
          </div>
          <div className="flex justify-center lg:justify-start items-center">
            <button className="read-more mt-10">Tìm hiểu thêm</button>
          </div>
        </div>
      </div>
      <div className="lg:flex justify-between items-start px-2 min-[420px]:px-8 xl:px-28 mb-10 z-10 grid">
        <div className="order-last lg:order-first w-full lg:w-1/2 px-4 md:px-28 lg:px-0 mr-4 items-center">
          <div>
            <div className="w-full text-center lg:text-start xl:w-11/12 title-green">
              Phân tích mọi cổ phiếu chỉ trong 3 phút
            </div>
          </div>
          <div className="text-start min-[420px]:text-center lg:text-start font-normal text-sm min-[420px]:text-base w-full xl:w-3/4 mt-6">
            Chúng tôi giúp bạn phân tích mọi cổ phiếu về cơ bản và kỹ thuật, kèm
            theo đó thể hiện trực quan với dữ liệu trong quá khứ và các cổ phiếu
            trong cùng nhóm ngành nhằm giúp bạn tự tin hơn trong quyết định đầu
            tư
          </div>
          <div className="flex justify-center lg:justify-start items-center">
            <button className="read-more mt-10">Tìm hiểu thêm</button>
          </div>
        </div>
        <div className="min-[420px]:ml-4 order-first lg:order-last">
          <img src={RightchartWithPopup} alt="" />
        </div>
      </div>
      <div className="md:flex justify-between items-center px-2 min-[420px]:px-8 xl:px-28 mb-20 relative z-10">
        <div>
          <img src={MobileScreen} alt="" />
        </div>
        <div className="w-full md:w-1/2 ml-0 px-4 md:ml-8 lg:ml-28">
          <div>
            <div className="w-full xl:w-2/3 title-green">
              Giảm chỉ phí doanh nghiệp và tăng tối đa lợi nhuận
            </div>
          </div>
          <div className="min-[420px]:px-8 md:px-0 text-start min-[420px]:text-center md:text-start font-normal text-sm min-[420px]:text-base w-full xl:w-2/3 mt-6">
            Với việc sử dụng sản phẩm này, quy trình làm việc của công ty sẽ đã
            giảm thiểu chi phí vận hành, từ đó tăng doanh thu tổng thê của doanh
            nghiệp
          </div>
          <div className="flex justify-center md:justify-start items-center">
            <button className="read-more mt-10">Tìm hiểu thêm</button>
          </div>
        </div>
      </div>
      <div className="relative hidden min-[420px]:flex">
        <div className="absolute left-0 bottom-0 z-0">
          <img src={Item4} alt="" />
        </div>
      </div>
      <div className="relative min-[420px]:hidden">
        <div className="absolute left-0 bottom-72 z-0">
          <img src={Item4Res} alt="" />
        </div>
      </div>
      <div className="text-center flex justify-center items-center mb-20 relative z-10">
        <div className="mx-4 min-[420px]:mx-0 min-[420px]:w-3/4 xl:w-7/12">
          <div className="title-green-2 text-center trans-f">
            Trải Nghiệm Hoàn Toàn Miễn Phí
          </div>
          <div className="text-md font-medium md:text-lg lg:text-2xl mt-6 text-center trans-f mx-4 md:mx-20">
            Tham gia GoStock cùng các nhà đầu tư chuyên nghiệp,{' '}
            <br className="min-[420px]:hidden" /> Dẫn đầu và chiến thắng thị
            trường cùng GoStock
          </div>
          <div className="flex justify-center items-center">
            <button className="read-more mt-10">Tìm hiểu thêm</button>
          </div>
        </div>
      </div>
      <div className="relative hidden min-[420px]:flex">
        <div className="absolute right-0 bottom-20 z-0">
          <img src={Item5} alt="" />
        </div>
      </div>
      <div className="relative min-[420px]:hidden">
        <div className="absolute right-0 bottom-48 z-0">
          <img src={Item5Res} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Main;
