import React, { useEffect, useReducer, useState } from "react";
import TechnicalChart from "./TechnicalChart";
import PE from "./PE";
import PB from "./PB";
import InfoStocks from "./InfoStocks";
import { autocompleteSearch } from "../../common/api/search";
import KeyStats from "./KeyStats";
import Dividends from "./Dividends";
import Performance from "./Performance";
import Technicals from "./Technicals";
import AnalystRating from "./AnalystRating";

const reducer = (state, action) => {
	switch (action.type) {
		case 'FETCH_REQUEST':
			return { ...state, loading: true };
		case 'FETCH_SUCCESS':
			return {
				...state,
				tickers: action.payload,
				loading: false,
			};
		case 'FETCH_FAIL':
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};

const CandlestickTool = () => {
  const [ticker, setTicker] = useState("VCB");
	const [query, setQuery] = useState('');
  const [{ tickers }, dispatch] = useReducer(reducer, {
		loading: true,
		error: '',
	});

  useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await autocompleteSearch(query);
				dispatch({ type: 'FETCH_SUCCESS', payload: res.data ?? [] });
			} catch (err) {}
		};
		fetchData();
	}, [query]);

  const handleSearch = (e, value) => {
    setTicker(value);
    setQuery('');
  };

  return ( 
    <div className="bg-tintMain w-full flex justify-center bg-[#F7FAFF] overflow-x-hidden">
      <div className="w-full max-w-[1390px] min-h-screen">
        <div className='mt-3 relative'>
          <div className='relative mt-2'>
            <input
              type='search'
              class='cursor-pointer relative h-12 border py-2 px-3 rounded-md outline-none w-full bg-white'
              onChange={(e) => setQuery(e.target.value)}
              placeholder='Nhập mã cổ phiếu'
              value={query}
            />
          </div>
          {(tickers && query) && <div className='z-20 absolute gird grid-cols-2 gap-4 w-full overflow-y-auto h-96 bg-white hover:overflow-auto'>
            {tickers?.map((t) => (
              <div className='bg-gray-50 m-2 px-4 sm:p-1 cursor-pointer hover:bg-gray-100'>
                <div className='w-full'>
                  <div
                    className='blocktext-sm font-medium text-gray-900'
                    onClick={(e) =>
                      handleSearch(
                        e,
                        t.ticker,
                      )
                    }
                  >
                    <div className='flex justify-center items-center'>
                      <div className='w-8 h-8 mr-4'>
                        <img
                          src={t.logo}
                          alt=''
                        />
                      </div>
                      <div className='font-bold mr-4'>
                        {t.ticker}
                      </div>
                      <div className='w-5/6'>
                        {t.company_name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>}
        </div>
        <div className="md:flex w-full">
          <div className="md:w-3/5 xl:w-3/4">
            <TechnicalChart ticker={ticker} />
            <div className="text-md font-bold text-center">PE</div>
            <PE ticker={ticker} />
            <div className="text-md font-bold text-center">PB</div>
            <PB ticker={ticker} />
          </div>
          <div className="md:w-2/5 xl:w-1/4">
            <InfoStocks ticker={ticker} />
            <Dividends ticker={ticker} />
            <Performance ticker={ticker} />
          </div>
        </div>
        <div className="xl:flex w-full mb-8">
          <div className="xl:w-1/2">
            <KeyStats ticker={ticker} />
          </div>
          <div className="xl:w-1/2 sm:flex">
            <div className="sm:w-1/2">
              <Technicals ticker={ticker} />
            </div>
            <div className="sm:w-1/2">
              <AnalystRating ticker={ticker} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandlestickTool;
