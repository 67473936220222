import ReactApexChart from 'react-apexcharts';

const PieChart = (props) => {
  const { data1, data2 } = props;
  const series1 = [data1?.SHARK ?? 0, data1?.WOLF ?? 0, data1?.SHEEP ?? 0]; 
  const series2 = [data2?.SHARK ?? 0, data2?.WOLF ?? 0, data2?.SHEEP ?? 0]; 
  const options = {
    chart: {
      width: 300,
      type: 'pie',
    },
    labels: ['Dòng tiền lớn', 'Dòng tiền vừa', 'Dòng tiền nhỏ'],
    colors: ['rgba(26,175,118,0.85)', '#FFCD6B', 'rgba(215,47,47,0.85)'],
    legend: {
      show: false,
    },
    yaxis: {
      opposite: true,
      labels: {
        formatter: (value) => {
          return value.toFixed(0) + ' cp';
        },
      },
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
      }
    }]
  };
  return (
    <div className='block pie-chart'>
      <div id="chart" className='md:flex'>
        <div className='md:w-1/2'>
          <div className='font-bold text-center'>KL mua chủ động</div>
          <div className='xl:left-0 flex justify-center xl:block'><ReactApexChart options={options} series={series1} type="pie" width={300} /></div>
        </div>
        <div className='md:w-1/2'>
          <div className='font-bold text-center'>KL bán chủ động</div>
          <div className='flex justify-center xl:block'><ReactApexChart options={options} series={series2} type="pie" width={300} /></div>
        </div>
      </div>
      <div className='flex justify-around mt-4 sm:mx-10'>
        <div className='flex items-center cursor-pointer'>
          <div className='w-3 h-3 rounded-full bg-[rgba(215,47,47,0.85)] mr-1'></div>
          <div className='text-sm sm:text-base'>Dòng tiền nhỏ</div>
        </div>
        <div className='flex items-center cursor-pointer'>
          <div className='w-3 h-3 rounded-full bg-[#FFCD6B] mr-1'></div>
          <div className='text-sm sm:text-base'>Dòng tiền vừa</div>
        </div>
        <div className='flex items-center cursor-pointer'>
          <div className='w-3 h-3 rounded-full bg-[rgba(26,175,118,0.85)] mr-1'></div>
          <div className='text-sm sm:text-base'>Dòng tiền lớn</div>
        </div>
      </div>
    </div>
  );
};
export default PieChart;
