import React, { useEffect } from 'react';
import './HomePage.css';
import MarketMovements from './homepage/MarketMovements';
import MarketChart from './recharts/marketChart/MarketChart';
import { connect } from "react-redux"
import MarketMap from './homepage/components/MarketMap/MarketMap';
import { 
  getTopStockBuyForeign,
  getStockPrice,
  getStockTop,
  getTopStockVolume,
  getTopStockSellForeign,
  getTopStockIncreaseRate,
  getTopStockDecreaseRate,
  getStockMarketPulse,
  getForeignTradingResult,      
  getListSector,
  getSectorIndexChange,
} from '../store/reducers/homepage/actions';
import IndustryMap from './homepage/components/IndustryGroupInformation/IndustryMap';
import PriceMovements from './homepage/PriceMovements';

const Dashboard = (props) => {
  const { 
    getListSector,
    getTopStockBuyForeign,
    getStockTop,
    getTopStockVolume,
    getTopStockSellForeign,
    getTopStockIncreaseRate,
    getTopStockDecreaseRate,
    getStockMarketPulse,
    getForeignTradingResult, 
    getSectorIndexChange,
   } = props

  useEffect(() => {
    const fetch = async () => {
      await getListSector()
      await getStockTop()
      await getTopStockVolume('1d')
      await getTopStockBuyForeign('1d')
      await getTopStockSellForeign('1d')
      await getTopStockIncreaseRate('1d')
      await getTopStockDecreaseRate('1d')
      await getStockMarketPulse(1)
      await getForeignTradingResult('1d')
      await getSectorIndexChange()
    }
    fetch()
  }, []);
  return (
    <div id="homepage" className="justify-center items-center flex bg-theme-bg-primary homePage">
      <div className="w-full py-10 home-page-layout">
        <div className="w-full">
          <MarketChart />
        </div>
        <div className="w-full">
          <PriceMovements/>
        </div>
        <div className="w-full">
          <MarketMovements/>
        </div>
        <div className="w-full">
          <MarketMap />
        </div>
        <div className="w-full">
          <IndustryMap />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return{

  }
}

export default connect(mapStateToProps, {
  getTopStockBuyForeign,
  getStockPrice,
  getStockTop,
  getTopStockVolume,
  getTopStockSellForeign,
  getTopStockIncreaseRate,
  getTopStockDecreaseRate,
  getStockMarketPulse,
  getForeignTradingResult,  
  getListSector,
  getSectorIndexChange
})(Dashboard);
