import React from 'react';

import { Footer } from './layout';
import { Header, Main } from './cpnlandingpage';

function LandingPage() {
  return (
    <React.Fragment>
      <div className="w-full h-screen bg-white overflow-y-auto overflow-x-hidden">
        <Header />
        <Main />
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default LandingPage;
