import { useState, useEffect } from "react";
import { connect } from "react-redux";
import IndustryGroup from "./IndustryGroup";
import Overview from "./Overview";
import MarketPulse from "./MarketPulse";
import './style.css';
import TransactionInformation from "./TransactionInformation";

const MarketMap = (props) => {
  const [menu, setMenu] = useState('overview')

  useEffect(() =>{
  },[menu])

  return(
    <div className="w-full MarketMap">
      <div className="font-extrabold text-[32px] text-title-color">
        <span>Bản Đồ Thị Trường</span>
      </div>
      <div className="flex h-[61px] items-center bottom-border">
        <div className={`cursor-pointer h-full flex items-center px-4 text-base
          ${
            menu === 'overview'? 'font-bold text-color-primary bottom-border--active' : ''
          }
        `} onClick={()=>{setMenu('overview')}}>Tổng quan</div>
        <div className={`cursor-pointer h-full flex items-center px-4 text-base
          ${
            menu === 'pulse'? 'font-bold text-color-primary bottom-border--active' : ''
          }
        `} onClick={()=>{setMenu('pulse')}}>Nhịp đập thị trường</div>
        <div className={`cursor-pointer h-full flex items-center px-4 text-base
          ${
            menu === 'group'? 'font-bold text-color-primary bottom-border--active' : ''
          }
        `} onClick={()=>{setMenu('group')}}>Thông tin giao dịch</div>
      </div>
      <div className="mt-6">
        <div className={`
        ${
          menu === 'overview'? '' : 'hidden'
        }`}>
          <Overview />
        </div>
        <div className={`
        ${
          menu === 'pulse'? '' : 'hidden'
        }`}>
          <MarketPulse />
        </div>
        <div className={`
        ${
          menu === 'group'? '' : 'hidden'
        }`}>
          {/* <IndustryGroup /> */}
          <TransactionInformation />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return{}
}

export default connect(mapStateToProps,{

})(MarketMap)