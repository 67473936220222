import React from 'react';
import { ReactComponent as Mess } from '../../assets/svg/footer/Mess.svg';
import { ReactComponent as Mail } from '../../assets/svg/footer/Mail.svg';
import { ReactComponent as Logo } from '../../assets/svg/footer/Logo2.svg';

const Footer = () => {
  return (
    <div className="bg-cl-green sm:flex justify-between px-1 py-4 md:px-10 lg:px-20 md:py-4 xl:px-28 xl:py-8">
      <div className="text-white font-medium mb-8 md:mb-0 justify-center md:justify-start text-center min-[601px]:text-left">
        <div className="flex justify-center min-[601px]:justify-start">
          <div>
            <Logo />
          </div>
          <div className="my-auto ml-4 font-medium text-3xl">GoStock</div>
        </div>
        <div className="mt-8 font-bold text-2xl md:text-3xl">
          CÔNG TY CỔ PHẦN GoStock
        </div>
        <div className="mt-4 font-bold text-base">MST: 0109620361</div>
        <div className="mt-1 font-bold text-base">
          Số 5 ngõ 316 Lê Trọng Tấn
        </div>
      </div>
      <div className="font-medium text-base text-white mt-4">
        <div className="flex justify-center min-[601px]:justify-start">
          <div className="">
            <div>Về chúng tôi</div>
            <div className="mt-8">Gói Hội Viên</div>
          </div>
          <div className="ml-8">
            <div>Điều Khoản Sử Dụng</div>
            <div className="mt-8">Chính Sách Bảo mật</div>
          </div>
        </div>

        <div className="my-8 text-center font-medium text-base">
          Liên hệ với chúng tôi
        </div>
        <div className="flex justify-center">
          <div className="mr-2">
            <Mess />
          </div>
          <div className="ml-2">
            <Mail />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
