import * as React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	FormControl,
	InputLabel,
	Input,
	InputAdornment,
	IconButton,
	CircularProgress,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import {
	userLogin,
	registerUser,
	userLoginWithGoogle,
} from '../../store/reducers/auth/authAction';
import { ReactComponent as Google } from '../../assets/svg/google.svg';
import { useGoogleLogin } from '@react-oauth/google';
import { forgotPassword } from '../../common/api/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AuthDialog({ open, handleClose }) {
	const theme = useTheme();
	const [type, setType] = React.useState('login');
	const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [phoneNumber, setPhoneNumber] = React.useState('');
	const [confirmPassword, setConfirmPassword] = React.useState('');

	const [showPassword, setShowPassword] = React.useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    // forgot-password
    const [loadingForgotPassword, setLoadingForgotPassword] = React.useState(false)

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();
	};

	const handleChangeType = () => {
		if (type === 'login') setType('register');
		else setType('login');
	};
	const disabledAction = React.useMemo(() => {
		if (type === 'login' && (email === '' || password === '')) return true;
		else if (
			type === 'register' &&
			(email === '' || password === '' || confirmPassword !== password)
		)
			return true;
        else if (type === "forgot-password" && email === '')
            return true;
		else return '';
	}, [type, email, password, confirmPassword]);
	const { error, loading, success } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const handleOnClick = () => {
		if (type === 'login') {
			dispatch(userLogin({ email, password }));
		} else if (type === 'register') {
			dispatch(registerUser({ email, password, phoneNumber }));
		}
	};

    const handleOnForgotPassword = async () => {
        setLoadingForgotPassword(true)
        const res = await forgotPassword(email)
        if(res.success){
            toast.success(res.data.result, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            handleClose()
            setLoadingForgotPassword(false)
        }else {
            toast.error(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoadingForgotPassword(false)

        }
            

    }
	React.useEffect(() => {
		reset();
	}, [type]);

	React.useEffect(() => {
        setType('login')
		reset();
	}, [open]);

	React.useEffect(() => {
		if (success) handleClose();
	}, [success]);

	const reset = () => {
		setEmail('');
		setConfirmPassword('');
		setPassword('');
	};

	const onGoogleLogin = useGoogleLogin({
		onSuccess: (codeResponse) => {
			dispatch(
				userLoginWithGoogle({ accessToken: codeResponse.access_token }),
			);
		},
		onError: (error) => console.log(error),
	});

	return (
		<>
            <ToastContainer />
			<Dialog
				fullWidth={true}
				maxWidth='xs'
				open={open}
				onClose={handleClose}
				aria-labelledby='responsive-dialog-title'
			>
				<DialogTitle id='responsive-dialog-title'>
					{type === 'register'
						? 'Đăng ký'
						: type === 'login'
						? 'Đăng nhập'
						: 'Quên mật khẩu'}
				</DialogTitle>
				{type === 'forgot-password' ? (
					<DialogContent>
                        <DialogContentText>
							Hãy nhập địa chỉ email của bạn, chúng tôi sẽ gửi cho bạn link để đặt lại mật khẩu
						</DialogContentText>
                        <div class='my-5'>
							<label
								className='block text-gray-700 text-sm font-bold mb-2'
								for='email'
							>
								Email (*)
							</label>
							<input
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								className='text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-green-600 focus:outline-none'
								id='email'
								type='text'
								placeholder='Email bạn đăng ký là ...'
							/>
						</div>
                        <div className='my-5'>
							<button
								className='text-white bg-app-green p-2 rounded font-bold w-full disabled:bg-gray-300'
								disabled={disabledAction}
								onClick={handleOnForgotPassword}
							>
								{loadingForgotPassword ? (
									<CircularProgress
                                        size="16px"
										sx={{
											color: '#ffffff',
										}}
									/>
								) : "Lấy lại mật khẩu"}
							</button>
						</div>
                    </DialogContent>
				) : (
					<DialogContent>
						<DialogContentText>
							<p className='text-red-600'>{error}</p>
						</DialogContentText>
						<div class='mb-5'>
							<label
								className='block text-gray-700 text-sm font-bold mb-2'
								for='email'
							>
								Email (*)
							</label>
							<input
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								className='text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-green-600 focus:outline-none'
								id='email'
								type='text'
								placeholder='Email'
							/>
						</div>
						<div className='mb-5'>
							<div className='flex justify-between'>
								<label
									className='block text-gray-700 text-sm font-bold mb-2'
									for='password'
								>
									Mật khẩu (*)
								</label>
								{type === 'login' && (
									<label class='block text-green-400 text-sm'>
										<a
											href='#'
                                            onClick={() => setType('forgot-password')}
											className='cursor-pointer tracking-tighter text-green hover:text-green-500 hover:border-b-2 hover:border-green-400'
										>
											<span>Quên mật khẩu?</span>
										</a>
									</label>
								)}
							</div>
							<div className='relative'>
								<input
									type={showPassword ? 'text' : 'password'}
									value={password}
									onChange={(e) =>
										setPassword(e.target.value)
									}
									className='text-md block px-3 py-2  rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-green-600 focus:outline-none'
									id='password'
									placeholder='Mật khẩu'
								/>
								<div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
									>
										{showPassword ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</IconButton>
								</div>
							</div>
						</div>
						{type === 'register' && (
							<>
								<div class='mb-5'>
									<label
										className='block text-gray-700 text-sm font-bold mb-2'
										for='password'
									>
										Xác nhận mật khẩu (*)
									</label>
									<div className='relative'>
										<input
											type={
												showConfirmPassword
													? 'text'
													: 'password'
											}
											value={confirmPassword}
											onChange={(e) =>
												setConfirmPassword(
													e.target.value,
												)
											}
											className='text-md block px-3 py-2  rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-green-600 focus:outline-none'
											id='password'
											placeholder='Xác nhận mật khẩu'
										/>
										<div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={() =>
													setShowConfirmPassword(
														(show) => !show,
													)
												}
												onMouseDown={
													handleMouseDownPassword
												}
											>
												{showConfirmPassword ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</IconButton>
										</div>
									</div>
								</div>
								<div class='mb-5'>
									<label
										className='block text-gray-700 text-sm font-bold mb-2'
										for='phone-number'
									>
										Số điện thoại
									</label>
									<div className='relative'>
										<div className='inline-flex items-center justify-center absolute left-4 top-0 py-2  h-full text-gray-600 text-sm'>
											<svg
												width='16'
												height='16'
												viewBox='0 0 16 16'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M6.14503 2.66681H0V13.3332H6.14503H16V2.66681H6.14503Z'
													fill='#E14040'
												></path>
												<path
													d='M8 4.91498L8.70822 7.09457H11L9.14587 8.44163L9.85413 10.6213L8 9.2742L6.14587 10.6213L6.85413 8.44163L5 7.09457H7.29178L8 4.91498Z'
													fill='#FFC701'
												></path>
											</svg>
											<span>+84 |</span>
										</div>
										<input
											value={phoneNumber}
											onChange={(e) =>
												setPhoneNumber(e.target.value)
											}
											className='pl-16 text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-green-600 focus:outline-none'
											id='phone-number'
											type='text'
											placeholder='Ví dụ: 0912 103 307'
										/>
									</div>
								</div>
							</>
						)}
						<div className='my-5'>
							<button
								className='text-white bg-app-green p-2 rounded font-bold w-full disabled:bg-gray-300'
								disabled={disabledAction}
								onClick={handleOnClick}
							>
								{loading ? (
									<CircularProgress
                                        size="16px"
										sx={{
											color: '#ffffff',
										}}
									/>
								) : type === 'register' ? (
									'Đăng ký'
								) : (
									'Đăng nhập'
								)}
							</button>
						</div>
						<div className='or-line'>
							<span>Hoặc</span>
						</div>
						{type === 'login' && (
							<div>
								<button
									className='bg-white border-solid rounded p-2 font-bold w-full border-2 flex items-center justify-center'
									onClick={onGoogleLogin}
								>
									<Google className='icon-google mr-3' />
									Sign In with Google
								</button>
							</div>
						)}
						<div className='mt-5 text-center text-sm'>
							<span className=''>
								Bạn {type === 'login' ? 'chưa ' : 'đã '} có tài
								khoản?{' '}
							</span>{' '}
							<span className='link' onClick={handleChangeType}>
								{type === 'login' ? 'Đăng ký ' : 'Đăng nhập '}
							</span>
						</div>
					</DialogContent>
				)}
			</Dialog>
		</>
	);
}
