import ReactApexChart from 'react-apexcharts';

const series = [
  {
    name: 'Lợi nhuận khác',
    data: [1, 7, 5, 1, 7, 5, 5, 2.1, 7, 5, 5, 2, 5, 2],
  },
  {
    name: 'Lợi nhuận khác',
    data: [74, 64, 61, 74, 64, 51, 77, 69, 57, 69, 74, 64, 61, 67, 69],
  },
  {
    name: 'Lợi nhuận khác',
    data: [1, 7, 5, 1, 7, 5, 5, 2.1, 7, 5, 5, 2, 5, 2],
  },
  {
    name: 'Lợi nhuận khác',
    data: [1, 2, 2, 1, 1, 1, 2, 2, 1, 1, 2, 1, 1, 2, 2],
  },
  {
    name: 'Lợi nhuận khác',
    data: [19, 22, 22, 22, 28, 21, 22, 29, 32, 22, 28, 21, 22, 28, 21],
  },
  {
    name: 'Lợi nhuận khác',
    data: [1, 7, 5, 1, 7, 5, 5, 2.1, 7, 5, 5, 2, 5, 2],
  },
  {
    name: 'Lợi nhuận khác',
    data: [34, 44, 41, 34, 54, 41, 37, 49, 37, 49, 34, 44, 41, 47, 39],
  },
  {
    name: 'Lợi nhuận khác',
    data: [16, 26, 24, 14, 10, 14, 26, 24, 16, 16, 22, 16, 10, 22, 20],
  },
];
const options = {
  colors: [
    '#736567',
    '#ED2858',
    '#D7A47C',
    '#EA9127',
    '#E37DB6',
    '#2B7FFF',
    '#1AAF76',
    '#39CEEB',
  ],
  chart: {
    type: 'bar',
    height: 350,
    stacked: true,
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
  },
  responsive: [
    {
      breakpoint: 480,
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 0,
    },
    area: {
      fillTo: 'origin',
    },
  },
  xaxis: {
    type: 'datetime',
    categories: [
      '01/01/2011 GMT',
      '01/02/2011 GMT',
      '01/03/2011 GMT',
      '01/04/2011 GMT',
      '01/05/2011 GMT',
      '01/06/2011 GMT',
      '01/07/2011 GMT',
      '01/08/2011 GMT',
      '01/09/2011 GMT',
      '01/10/2011 GMT',
      '01/11/2011 GMT',
      '01/12/2011 GMT',
      '01/13/2011 GMT',
      '01/14/2011 GMT',
    ],
  },

  yaxis: {
    opposite: true,
    labels: {
      formatter: (value) => {
        return value.toFixed(2) + 'T';
      },
    },
  },

  fill: {
    opacity: 1,
  },

  dataLabels: {
    enabled: false,
  },

  legend: {
    show: false,
  },
};

const AssetStructure = () => {
  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={450}
      />
    </div>
  );
};
export default AssetStructure;
