import { useState, useEffect } from "react";
import { connect } from "react-redux";
import './style.css';
import Valuation from "../Valuation/Valuation";
import ProfitRate from "../ProfitRate/ProfitRate";
import IndustryGroup from "../MarketMap/IndustryGroup";
import StockTrend from "../IndustryGroupTrends/StockTrends";

const IndustryMap = (props) => {
  const [menu, setMenu] = useState('overview')

  useEffect(() =>{
  },[menu])

  return(
    <div className="w-full MarketMap">
      <div className="font-extrabold text-[32px] text-title-color">
        <span>Bản Đồ Thị Trường</span>
      </div>
      <div className="flex h-[61px] items-center bottom-border">
        <div className={`cursor-pointer h-full flex items-center px-4 text-base
          ${
            menu === 'overview'? 'font-bold text-color-primary bottom-border--active' : ''
          }
        `} onClick={()=>{setMenu('overview')}}>Định giá</div>
        <div className={`cursor-pointer h-full flex items-center px-4 text-base
          ${
            menu === 'pulse'? 'font-bold text-color-primary bottom-border--active' : ''
          }
        `} onClick={()=>{setMenu('pulse')}}>Xu hướng nhóm ngành</div>
        <div className={`cursor-pointer h-full flex items-center px-4 text-base
          ${
            menu === 'group'? 'font-bold text-color-primary bottom-border--active' : ''
          }
        `} onClick={()=>{setMenu('group')}}>Tăng giảm nhóm ngành</div>
      </div>
      <div className="mt-6">
        <div className={`
        ${
          menu === 'overview'? '' : 'hidden'
        }`}>
          <div className="xl:flex">
            <div className="xl:w-2/5">
              <Valuation />
            </div>
            <div className="xl:w-3/5">
              <ProfitRate />
            </div>
          </div>
        </div>
        <div className={`
        ${
          menu === 'pulse'? '' : 'hidden'
        }`}>
          <StockTrend />
        </div>
        <div className={`
        ${
          menu === 'group'? '' : 'hidden'
        }`}>
          <IndustryGroup />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return{}
}

export default connect(mapStateToProps,{

})(IndustryMap)