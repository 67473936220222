import { callApiget } from "../../../../common/fetch"
import { baseURL2 } from '../../../../common/const';

// const server_url = process.env.REACT_APP_API_URL
const server_url = baseURL2 +'/'

export const getStockLogo = (listStockCode) => {
  return dispatch => {
    let url = server_url + `stock/stock_logo`

    const params = new URLSearchParams()
  
    params.append('tickers', listStockCode)
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-STOCK-LOGO',
          payload: res.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}

export const getIndexHisFromDate = (ticker ,from, to) => {
  return dispatch => {
    let url = server_url + `index/index_history`

    const params = new URLSearchParams()
    
    params.append('ticker', ticker)
    params.append('from_date', from)
    params.append('to_date', to)
    params.append('mode', '1d')
    params.append('type', 'candle')
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-INDEX-FROM-DATE',
          payload: res.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
} 