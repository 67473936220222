import React, { useState, useEffect } from "react"
import {ReactComponent as SmallTriangle} from '../../../../assets/svg/SmallTriangle.svg'
import currency from "currency.js"

const StockItem = (props) => {
  const {logo, name, close, change, pct, changeSelect, isSelected} = props

  return(
    <div className={`cursor-pointer item flex justify-between rounded-lg py-2 px-4 mt-2
      ${isSelected === true? 'bg-[#33CCFF]':'' }
    `} onClick={changeSelect}>
      <div className="flex items-center mb-1">
        <img src={logo} alt="" className="w-8 h-8 object-contain mr-2 rounded-full"/>
        <div className="font-bold text-lg truncate mr-2 w-32 hover:text-clip has-tooltip cursor-pointer">
          {name}
          <div className="tooltip rounded shadow-lg p-3 bg-gray-50 -mt-20 relative">
            {name}
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <span className="mr-3 font-bold text-sm">{currency(close,{symbol:''}).format()}</span>
        <span className={`mr-3 font-bold text-sm flex items-center
          ${pct >= 0? 'text-[#1AAF76]' : 'text-[#D72F2F]'}
        `}>
          {change >= 0 ? '+' : ''}
          {change.toFixed(2)}</span>
        <span className={`font-bold text-sm flex items-center
          ${pct >= 0? 'text-[#1AAF76]' : 'text-[#D72F2F]'}
        `}>
          <SmallTriangle className={`w-2 h-2
            ${pct >= 0? '' : 'rotate-180'}
          `}/>
          {pct.toFixed(2)}
        </span>
      </div>
    </div>
  )
}

export default StockItem