import { useEffect, useState } from 'react';
import icon from '../../assets/png/logo_company.png';
import { ReactComponent as Increase } from '../../assets/svg/homepage/reduce.svg';
import { topBanNuocNgoai, topGiamGia } from '../../common/api';
import { connect } from 'react-redux';

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getTopStockSellForeign } from '../../store/reducers/homepage/actions';
import Loading from '../loading/Loading';
const TopSell = (props) => {
  const { mode, data, getTopStockSellForeign } = props;
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // getTopStockSellForeign(mode)
  }, [mode]);

  let max = 0;
  for (let i = 0; i < datas.length; i++) {
    if (max < datas[i].AvgVol) max = datas[i].AvgVol;
  }

  for (let i = 0; i < datas.length; i++) {
    datas[i].percent = '' + (datas[i].AvgVol * 100) / max + '%';
  }
  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex-none font-bold text-sm mb-4">
        Top khối ngoại bán ròng
      </div>
      <div className="flex flex-col w-full grow bg-white items overflow-visible rounded-lg p-[16px]">
        <div className="flex justify-between font-medium text-[13px] h-10">
          <div className="">Mã Cổ phiếu</div>
          <div className="">Giá trịi giao dịch</div>
        </div>
        <div className="flex justify-between grow">
          <div className="flex flex-col justify-around py-1 w-1/3 min-w-[80px]">
            {
              data.map((el,index)=>{
                return(
                  <div className="flex items-center" key={index}>
                    <img className="mr-2" src={icon} alt="" />
                    <div className="font-bold text-[13px]">
                      {el.StockCode}
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="w-7/12">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                layout="vertical"
                barCategoryGap={10}
                data={data}
              >
                <XAxis type="number" hide="true"/>
                <YAxis type="category" dataKey="name" hide/>
                <Tooltip />
                <Bar  dataKey={"DiffAvgVal"} fill="#D72F2F"
                maxBarSize={20}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="flex flex-col justify-around py-1 flex-auto">
            {
              data.map((el,index)=>{
                let price = el.DiffAvgVal/1000000000
                return(
                  <div className="flex justify-end font-bold text-[#D72F2F]" key={index}>{price.toFixed(0)}T</div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return{
    data: state.homePage.topStockSellForeign,
  }
}

export default connect(mapStateToProps, {
  getTopStockSellForeign
})(TopSell);
