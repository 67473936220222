import React, { useEffect, useReducer, useRef, useState } from 'react';
import * as d3 from 'd3';
import { rrgStatisticsBySector } from '../../../../common/api/search';
import Loading from '../../../loading/Loading';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        allDatas: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const RRGChart = (props) => {
  const chartRef = useRef(null);
  const [series, setSeries] = useState([]);
  const [{ loading, allDatas }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const { rrg, load, time, win, setTime, setMaxTime, mode } = props

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const res = await rrgStatisticsBySector(rrg, 10, win, mode);
        setSeries(res.data);
        setTime(res.data[0].length);
        setMaxTime(res.data[0].length);
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: res.data,
        });
      } catch (err) { }
    };
    fetchData();
  }, [rrg, load, win, mode]);

  useEffect(() => {
    if(allDatas?.length > 0) {
      let s = [];
      allDatas.forEach(data => {
        s = [...s, data.slice(data.length - time, data.length)]
      });
      setSeries(s)
    }
  }, [time]);
  // Sample data for demonstration
  function SVGComponent({ dataLoad }) {
    const datas = dataLoad;
    const width = 650;
    const height = 640;
    if (chartRef.current) {
      const chartElement = chartRef.current;
      while (chartElement.firstChild) {
        chartElement.removeChild(chartElement.firstChild);
      }
    }
    // Check if the chart has already been drawn
    const chartContainer = document.getElementById('rrg-chart');
    if (chartContainer) {
      if (!chartContainer.hasChildNodes()) {
        // Create SVG container with specified width and height
        const svg = d3
          .select('#rrg-chart')
          .append('svg')
          .attr('width', width)
          .attr('height', height);

        // Define the scales for x and y axis with the new width and height
        const xScale = d3.scaleLinear().domain([-1, 1]).range([0, width]);
        const yScale = d3.scaleLinear().domain([-1, 1]).range([height, 0]);

        // Define colors for the quadrants
        const colors = ['green', 'orange', 'red', 'purple', 'brown', 'blue', 'pink', 'yellow','green', 'orange', 'blue', 'red', 'purple', 'brown', 'pink', 'yellow'];

        // Draw the quadrant background
        svg
          .selectAll('.quadrant')
          .data(colors)
          .enter()
          .append('rect')
          .attr('class', 'quadrant')
          .attr('width', width/2) // Half of the width
          .attr('height', height/2) // Half of the height
          .attr('x', (d, i) => (i % 2) * width/2) // Half of the width
          .attr('y', (d, i) => Math.floor(i / 2) * height/2) // Half of the height
          .style('fill', (d) => d)
          .style('opacity', 0.2);

        // Draw the quadrant lines
        svg
          .append('line')
          .attr('x1', xScale(0))
          .attr('y1', yScale(-1))
          .attr('x2', xScale(0))
          .attr('y2', yScale(1))
          .style('stroke', 'grey');

        svg
          .append('line')
          .attr('x1', xScale(-1))
          .attr('y1', yScale(0))
          .attr('x2', xScale(1))
          .attr('y2', yScale(0))
          .style('stroke', 'grey');

        svg
          .append('text')
          .attr('x', 20)
          .attr('y', 20)
          .attr('text-anchor', 'start')
          .attr('dominant-baseline', 'hanging')
          .text('TÍCH LŨY')
          .style('fill', 'green')
          .style('font-weight', 'bold');

        svg
          .append('text')
          .attr('x', width - 20)
          .attr('y', 20)
          .attr('text-anchor', 'end')
          .attr('dominant-baseline', 'hanging')
          .text('TĂNG GIÁ')
          .style('fill', 'orange')
          .style('font-weight', 'bold');

        svg
          .append('text')
          .attr('x', width - 20)
          .attr('y', height - 20)
          .attr('text-anchor', 'end')
          .attr('dominant-baseline', 'baseline')
          .text('SUY YẾU')
          .style('fill', 'red')
          .style('font-weight', 'bold');

        svg
          .append('text')
          .attr('x', 20)
          .attr('y', height - 20)
          .attr('text-anchor', 'start')
          .attr('dominant-baseline', 'baseline')
          .text('GIẢM GIÁ')
          .style('fill', 'blue')
          .style('font-weight', 'bold');

        // Draw vertical grid lines
        const verticalLines = [-0.5, 0, 0.5].map((x) => ({
          x1: xScale(x),
          y1: yScale(-1),
          x2: xScale(x),
          y2: yScale(1),
        }));

        svg
          .selectAll('.vertical-line')
          .data(verticalLines)
          .enter()
          .append('line')
          .attr('class', 'vertical-line')
          .attr('x1', (d) => d.x1)
          .attr('y1', (d) => d.y1)
          .attr('x2', (d) => d.x2)
          .attr('y2', (d) => d.y2)
          .style('stroke', 'grey')
          .style('stroke-dasharray', '3 3');

        // Draw horizontal grid lines
        const horizontalLines = [-0.5, 0, 0.5].map((y) => ({
          x1: xScale(-1),
          y1: yScale(y),
          x2: xScale(1),
          y2: yScale(y),
        }));

        svg
          .selectAll('.horizontal-line')
          .data(horizontalLines)
          .enter()
          .append('line')
          .attr('class', 'horizontal-line')
          .attr('x1', (d) => d.x1)
          .attr('y1', (d) => d.y1)
          .attr('x2', (d) => d.x2)
          .attr('y2', (d) => d.y2)
          .style('stroke', 'grey')
          .style('stroke-dasharray', '3 3');

        function handleMouseLeave() {
          // Remove info box on mouse leave
          svg.selectAll('.info-box').remove();
          svg.selectAll('.info-text').remove();
        }

        // Draw curve connecting all data points
        const line = d3
          .line()
          .x((d) => xScale((d.jdk_rs_momentum - 101) / 8))
          .y((d) => yScale((d.jdk_rs_ratio - 101) / 8))
          .curve(d3.curveCardinal);

        let color = 0;
        var dataPointGroup = [];
        datas?.forEach(drawLine);
        function drawLine(data) {
          let pathData = line(data);
          svg
            .append('path')
            .attr('d', pathData)
            .attr('fill', 'none')
            .attr('stroke', colors[color])
            .attr('stroke-width', 2)
            .attr('opacity', 0.5);
          const points = svg.selectAll('.data-point').data(data);
          // Add a group to hold data point and label
          dataPointGroup = points
            .enter()
            .append('g')
            .on('mouseenter', handleMouseEnter)
            .on('mouseleave', handleMouseLeave);

          dataPointGroup
            .append('circle')
            .attr('cx', (d) => xScale((d.jdk_rs_momentum - 101) / 8))
            .attr('cy', (d) => yScale((d.jdk_rs_ratio - 101) / 8))
            .attr('r', 4) // Increase the radius for a bigger data point
            .style('fill', colors[color]);
          const lastDataPoint = data[data.length - 1];
          dataPointGroup
            .append('text')
            .attr('x', xScale((lastDataPoint.jdk_rs_momentum - 101) / 8) + 8) // Offset for text
            .attr('y', yScale((lastDataPoint.jdk_rs_ratio - 101) / 8) + 8) // Offset for text
            .attr('stroke', colors[color])
            .text(lastDataPoint.sector_viet_name); // Your desired text
          color++;
        }

        function handleMouseEnter(d) {
          const offsetX = d.offsetX;
          const offsetY = d.offsetY;

          const infoBoxWidth = 220;
          const infoBoxHeight = 90;

          let infoBoxX = offsetX + 8;
          let infoBoxY = offsetY - 25;

          // Adjust position if the info box would go beyond the right edge
          if (infoBoxX > width/2) {
            infoBoxX = offsetX - infoBoxWidth - 8;
          }

          // Adjust position if the info box would go beyond the bottom edge
          if (infoBoxY + infoBoxHeight > height/2) {
            infoBoxY = offsetY - infoBoxHeight + 25;
          }

          // Show info box on mouse enter
          let infoBox = dataPointGroup
            .append('rect')
            .attr('class', 'info-box')
            .attr('x', infoBoxX)
            .attr('y', infoBoxY)
            .attr('width', 220)
            .attr('height', 90)
            .attr('rx', 5)
            .attr('ry', 5)
            .style('fill', 'white')
            .style('fill-opacity', 1)
            .style('border-radius', 8)
            .style('z-index', 10);
          dataPointGroup
            .append('text')
            .attr('class', 'info-text')
            .attr('x', infoBoxX + 10)
            .attr('y', infoBoxY + 43)
            .style('background', colors[color])
            .text(d.target.__data__.sector_viet_name);
          dataPointGroup
            .append('text')
            .attr('class', 'info-text')
            .attr('x', infoBoxX + 10)
            .attr('y', infoBoxY + 23)
            .style('background', colors[color])
            .text(d.target.__data__.tradingdate.slice(0, 10));
          dataPointGroup
            .append('text')
            .attr('class', 'info-text')
            .attr('x', infoBoxX + 10)
            .attr('y', infoBoxY + 63)
            .style('fill', 'gray')
            .text('sức mạnh: ' + d.target.__data__.jdk_rs_momentum.toFixed(2));
          dataPointGroup
            .append('text')
            .attr('class', 'info-text')
            .attr('x', infoBoxX + 10)
            .attr('y', infoBoxY + 83)
            .style('fill', 'gray')
            .text('xu hướng: ' + d.target.__data__.jdk_rs_ratio.toFixed(2));
        }
      }
    }
  }
  return <div className='relative'>
    {loading && <div className='bg-gray-900 opacity-50 absolute w-full h-full'><Loading /></div>}
    {loading && load ? 'Loading...' :
      <div id="rrg-chart" ref={chartRef}><SVGComponent dataLoad={series} /></div>
    }
  </div>;
};


export default RRGChart;
