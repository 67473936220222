import React, { useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { createChart, ColorType, CrosshairMode } from 'lightweight-charts';
import moment from 'moment';
import { logDOM } from '@testing-library/react';
import { getIndexHisFromDate } from '../Actions/actions';
import debounce from 'lodash/debounce'


const CandlestickChart = (props) => {
  const {
    getIndexHisFromDate,
    ticker,
	} = props;

	const chartContainerRef = useRef();

	useEffect(
		() => {
      const chart = createChart(chartContainerRef.current,{
        width: chartContainerRef.current.clientWidth,
        height: 353,
        layout: {
          textColor: 'black',
          background: { 
            type: 'solid', 
            color: '#F7FAFF' },
        },
        rightPriceScale: {
          borderVisible: false,
        },
        crosshair: {
          mode: CrosshairMode.Normal,
        },
        priceScale: {
          borderColor: '#485c7b',
        },
        timeScale: {
          borderColor: '#485c7b',
        },
      });
      
			const handleResize = () => {
				chart.applyOptions({ width: chartContainerRef.current.clientWidth });
			};
      chart.timeScale().fitContent();

      const candlestickSeries = chart.addCandlestickSeries({
        upColor: '#1AAF76',
        downColor: '#D72F2F',
        borderDownColor: '#D72F2F',
        borderUpColor: '#1AAF76',
        wickDownColor: '#838ca1',
        wickUpColor: '#838ca1',
      });
      candlestickSeries.priceScale().applyOptions({
        scaleMargins: {
          // positioning the price scale for the area series
          top: 0.1,
            bottom: 0.2,
          },
        });
        
        const volumeSeries = chart.addHistogramSeries({
          color: '#26a69a',
          priceFormat: {
            type: 'volume',
          },
          priceScaleId: '', // set as an overlay by setting a blank priceScaleId
          // set the positioning of the volume series
          scaleMargins: {
            top: 0.7, // highest point of the series will be 70% away from the top
            bottom: 0,
          },
        });
        volumeSeries.priceScale().applyOptions({
          scaleMargins: {
            top: 0.7, // highest point of the series will be 70% away from the top
            bottom: 0,
          },
        });
        
        console.log(moment(new Date().getTime()).format('YYYY-MM-DD'));
        
        console.log(new Date().getTime());
      
        const res = getIndexHisFromDate(ticker, moment(new Date().getTime() - 31536000000).format('YYYY-MM-DD'), moment(new Date().getTime()).format('YYYY-MM-DD'))
        .then(res1 => {
          let volumeData = [], priceData = []

          res1.data.data.price_data.map((el,index)=>{
            priceData.push({...el, time : moment(el.time, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD')})
            if(el.close > el.open){
              volumeData.push({...res1.data.data.volume_data[index], 
              time : moment(res1.data.data.volume_data[index].time, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD'),
              color: "rgba(26, 175, 118, 0.7)"})
            }else{
              volumeData.push({...res1.data.data.volume_data[index],
                time : moment(res1.data.data.volume_data[index].time, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD'),
                color: "rgba(215, 47, 47, 0.7)"})
              }
            })

            candlestickSeries.setData(priceData)
            volumeSeries.setData(volumeData)

            return res1.data.data
        })

          const updateSeriesData = debounce((newValue)=>{
            const from = Number(((new Date(newValue.from).getTime()/1000).toFixed(0))) + Number((newValue.barsBefore.toFixed(0))*86400)

            const res = getIndexHisFromDate(ticker, moment(new Date(from*1000)).format('YYYY-MM-DD'), newValue.from)
            .then(res1 =>{

              let volumeData = [], priceData = []

              res1.data.data.price_data.map((el,index)=>{
                priceData.push({...el, time : moment(el.time, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD')})
                if(el.close > el.open){
                  volumeData.push({...res1.data.data.volume_data[index], 
                  time : moment(res1.data.data.volume_data[index].time, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD'),
                  color: "rgba(26, 175, 118, 0.7)"})
                }else{
                  volumeData.push({...res1.data.data.volume_data[index],
                    time : moment(res1.data.data.volume_data[index].time, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD'),
                    color: "rgba(215, 47, 47, 0.7)"})
                  }
                })
              priceData.pop()
              volumeData.pop()
              
              let tmpPriceData = [...priceData, ...candlestickSeries.data()]
              let tmpVolumeData = [...volumeData, ...volumeSeries.data()]

              if (tmpVolumeData.length > 0) {
                candlestickSeries.setData(tmpPriceData)
                volumeSeries.setData(tmpVolumeData)
              }

              return res1.data.data
            })
          }, 300 )

          const onVisibleLogicalRangeChanged = (newVisibleLogicalRange) => {
            const barsInfo = candlestickSeries.barsInLogicalRange(newVisibleLogicalRange);

            // if there less than 50 bars to the left of the visible area
            if (barsInfo !== null && barsInfo.barsBefore <= 0) {
              // try to load additional historical data and prepend it to the series data`
              updateSeriesData(barsInfo)
            }
          }
          
          chart.timeScale().subscribeVisibleLogicalRangeChange(onVisibleLogicalRangeChanged)
          
          window.addEventListener('resize', handleResize);
          
          return () => {
            window.removeEventListener('resize', handleResize);
            chart.timeScale().unsubscribeVisibleLogicalRangeChange(onVisibleLogicalRangeChanged)
            chart.remove();
          };
        },
        [ticker]
        );
        
        return (
		<div className='h-[353px]'
    ref={chartContainerRef}
		/>
	);
}

const mapStateToProps = state => {
  return{
  }
}

export default connect(mapStateToProps, {
  getIndexHisFromDate,
})(CandlestickChart)