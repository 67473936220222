import React, { useEffect, useRef } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import moment from 'moment';

const CandlestickChart = (props) => {
    const {
        data,
    } = props;
    const chartContainerRef = useRef();
    useEffect(
        () => {
            const chart = createChart(chartContainerRef.current, {
                width: chartContainerRef.current.clientWidth,
                height: 353,
                layout: {
                    textColor: 'black',
                    background: {
                        type: 'solid',
                        color: '#F7FAFF',
                    },
                },
                rightPriceScale: {
                    borderVisible: false,
                },
                crosshair: {
                    mode: CrosshairMode.Normal,
                },
                priceScale: {
                    borderColor: '#485c7b',
                },
                timeScale: {
                    borderColor: '#485c7b',
                },
            });
            const handleResize = () => {
                chart.applyOptions({ width: chartContainerRef.current.clientWidth });
            };
            chart.timeScale().fitContent();
            const candlestickSeries = chart.addCandlestickSeries({
                upColor: '#D72F2F',
                downColor: '#00BF71',
                borderDownColor: '#00BF71',
                borderUpColor: '#D72F2F',
                wickDownColor: '#00BF71',
                wickUpColor: '#D72F2F',
            });
            // const tooltipContent = `Open: ${candle.open}\nHigh: ${candle.high}\nLow: ${candle.low}\nClose: ${candle.close}`; 
            candlestickSeries.priceScale().applyOptions({
                scaleMargins: {
                    top: 0.1,
                    bottom: 0.4,
                },
            });
            const volumeSeries = chart.addHistogramSeries({
                priceFormat: {
                    type: 'volume',
                },
                priceScaleId: '',
                scaleMargins: {
                    top: 0.7,
                    bottom: 0,
                },
            });
            volumeSeries.priceScale().applyOptions({
                scaleMargins: {
                    top: 0.7,
                    bottom: 0,
                },
            });
            if (data.length !== 0) {
                let volumeData = [], priceData = []
                data?.data?.price_data.map(el => {
                    priceData.push({ ...el, time: moment(el.time, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD') })
                })
                data?.data?.volume_data.map(el => {
                    volumeData.push({ ...el, time: moment(el.time, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD') })
                })
                candlestickSeries.setData(priceData);
                volumeSeries.setData(volumeData)
            }
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
                chart.remove();
            };
        },
        [data]
    );
    return (
        <div className='h-[353px]'
            ref={chartContainerRef}
        />
    );
}


export default CandlestickChart