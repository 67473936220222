import TransactionInformation from './overview/TransactionInformation';
import PriceMovements from './overview/PriceMovements';
import MainAnalysis from './overview/MainAnalysis';
import KeyStats from './overview/KeyStats';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import StockTrend from './overview/StockTrends';

const Overview = () => {
  const [tickerDefault] = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const ticker = searchParams.get('ticker') ?? tickerDefault;

  return (
    <div className="w-full">
      <PriceMovements context={[ticker]} />
      <TransactionInformation context={[ticker]} />
      < StockTrend context={[ticker]}/>
      <MainAnalysis context={[ticker]} />
      <KeyStats context={[ticker]} />
    </div>
  );
};

export default Overview;
