import React from 'react';
import ReactApexChart from 'react-apexcharts';

const Dividends = (props) => {
    const series = [44, 55];
    const options = {
        chart: {
            type: 'donut',
        },
        labels: ['Cổ tức tiền mặt', 'Cổ tức cổ phiếu'],
        legend: {
            show: false,
        },
        colors: ['#1AAF76', '#BFC3C8'],
        plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    showAlways: true,
                    show: true
                  }
                }
              }
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };
    return (
        <div>
            <div className='font-bold text-center text-md'>Dividends</div>
            <div className='flex justify-center'>
                <ReactApexChart
                    options={options}
                    series={series}
                    type="donut"
                />
            </div>
            <div className='flex justify-around mt-4 mx-10'>
                <div className='flex items-center cursor-pointer'>
                    <div className='w-3 h-3 rounded-full bg-[#1AAF76] mr-1'></div>
                    <div>Cổ tức tiền mặt</div>
                </div>
                <div className='flex items-center cursor-pointer'>
                    <div className='w-3 h-3 rounded-full bg-[#BFC3C8] mr-1'></div>
                    <div>Cổ tức cổ phiếu</div>
                </div>
            </div>
        </div>
    )
}

export default Dividends
