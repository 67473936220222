import { combineReducers } from "redux"

import homePageReducer from "./reducers/homepage/homePageReducer"
import movermentReducer from "./reducers/homepage/moverment/movermentReducer"
import authSlice from './reducers/auth/authSlice'

export default combineReducers({
	homePage: homePageReducer,
	moverment: movermentReducer,
	auth: authSlice,
});