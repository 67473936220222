import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { 
	getStockPrice, 
	getStockTop,
	getCommodityPriceHistory,
	getListCommodity,
	getStockByCommodity,
	getCurrentFinancaImformation,
	getIndexHistory,
	getListIndex,
	getCommodityLogo,
	getIndexLogo,
	getStockCommodityPrice
 } from "../../../store/reducers/homepage/actions"

import { getStockLogo } from "./Actions/actions";
import './style.css';
import ModeActions from "../../tools/ModeActions"
import moment from "moment"
import StockPanel from "./elements/StockPanel";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import CandlestickChart from "./elements/CandlestickChart";

const MarketChart = (props) => {
	const listTime = ['1m','3m','1y','3y','all']
	const {
		dataIndexHistory,
		getCommodityPriceHistory,
		getListCommodity,
		dataCurrentFinanceImformation,
		getStockByCommodity,
		dataListCommodity,
		dataStockByCommodity,
		getCurrentFinancaImformation,
		getIndexHistory,
		getStockLogo,
		dataStockLogo,
		dataListIndex,
		getListIndex,
		dataCommodityLogo,
		getCommodityLogo,
		getStockCommodityPrice,
		dataStockCommodityPrice
	} = props
	const [menu, setMenu] = useState('chiso')
	const [currentStock, setCurrentStock] = useState('')
	const [currentStockCom, setCurrentStockCom] = useState('')
	const [currentCommodity, setCurrentCommodity] = useState('')
	const [currentIndex, setCurrentIndex] = useState('VNINDEX')
	const [currentTime, setcurrentTime] = useState('1m')
	const [dataPanel, setDataPanel] = useState([])
	const [dataPanelCom, setDataPanelCom] = useState([])
	const [dataPanelIndex, setDataPanelIndex] = useState([])
	const [labels, setLabels] = useState([])
	const [dataCom, setDataCom] = useState([])
	const [dataStock, setDataStock] = useState([])
//Multi axis chart

	const data = {
		labels: labels,
		datasets: [
			{
				label: "Giá hàng hoá ($)",
				// backgroundColor: "rgb(255, 99, 132)",
				borderColor: "#2B7FFF",
				data: dataCom,
				yAxisID: 'y',
				fill: {
					target: 'origin',
					above: 'rgba(43, 127, 255, 0.2)',   // Area will be red above the origin
					below: 'rgba(43, 127, 255, 0)'
				}
			},
			{
				label: "Giá cổ phiếu có liên quan (VND)",
				// backgroundColor: "#000000",
				borderColor: "#1AAF76",
				data: dataStock,
				yAxisID: 'y1',
				fill: {
					target: 'origin',
					above: 'rgba(26, 175, 118, 0.35)',   // Area will be red above the origin
					below: 'rgba(255, 255, 255, 0)'
				}
			},
		],
	};

//--------------------------
	const changeSelect = (param) => {
		switch (menu) {
			case 'chiso':
				setCurrentStock(param.StockCode)
				break;
		
			case 'hanghoa':
				setCurrentStockCom(param.StockCode)
				break;
		
			default:
				break;
		}
	}

	const changeSelectCom = (param) => {
		switch (menu) {
			case 'chiso':
				setCurrentIndex(param.ticker)
				break;
			case 'hanghoa':
				setCurrentCommodity(param.ticker)
				break;
		
			default:
				break;
		}
	}

	const fetchLogo = async (listLogo) => {
		await getStockLogo(listLogo)
	}

	const fetchCommodity = (ticker, mode) => {
		getCommodityPriceHistory(ticker, mode)
		getStockByCommodity(ticker)
	}

	useEffect(() => {
		if(menu === 'hanghoa'){
			setCurrentCommodity(dataListCommodity[1].ticker)
			fetchCommodity(dataListCommodity[1].ticker, currentTime)
		}else if(menu === 'chiso' && currentIndex !== ''){
			// getIndexHistory(currentIndex, currentTime)
		}
	}, [menu])

	useEffect(()=>{
    if(currentIndex !== '' && menu === 'chiso'){
    //   getIndexHistory(currentIndex, currentTime)
    }else if(menu === 'hanghoa' && currentStockCom !== ''){
			getStockByCommodity(currentCommodity)
			getCommodityPriceHistory(currentCommodity, currentTime)
			getStockCommodityPrice(currentStockCom, currentCommodity, currentTime)
		}
	},[currentTime, currentStock, currentCommodity, currentIndex])
	
	useEffect(()=>{
		if(currentStockCom !== ''){
			getStockCommodityPrice(currentStockCom, currentCommodity, currentTime)
		}
	}, [currentStockCom])

	useEffect(()=>{
		if(menu === 'hanghoa' && dataStockCommodityPrice.length !== 0){
			let tmpLabels = [], tmpStockPrice = [], tmpComPrice = []
			dataStockCommodityPrice.map(el => {
				tmpLabels.push(moment(el.tradingdate, "DD-MM-YYYYTHH:mm:ss").format("DD/MM/YYYY"))
				tmpComPrice.push(el.commodity_price)
				tmpStockPrice.push(el.stock_price)
			})

			setLabels(tmpLabels)
			setDataCom(tmpComPrice)
			setDataStock(tmpStockPrice)
		}
	},[dataStockCommodityPrice])

	useEffect(()=>{
		if(dataStockByCommodity.length !== 0){
			let listLogo = dataStockByCommodity[0]
			for(let i=1; i < dataStockByCommodity.length; i++){
				listLogo = listLogo + ',' + dataStockByCommodity[i]
			}
			getCurrentFinancaImformation(listLogo)
			fetchLogo(listLogo)
			setCurrentStockCom(dataStockByCommodity[0])
		}else{
			setDataPanel([])
		}
	},[dataStockByCommodity])

	useEffect(()=>{
		if (menu === 'hanghoa' && dataStockLogo?.length !== 0) {
			let tmpData = []
			dataCurrentFinanceImformation.map((el,index)=>{
				tmpData.push({
					StockCode: el.ticker,
					ClosePrice: el.price,
					Change: el.price_change,
					PerChange: el.pct_change,
					url: dataStockLogo?.data[index]
				})
			})
			setDataPanel(tmpData)
		}
	}, [dataStockLogo, dataCurrentFinanceImformation])

	useEffect(()=>{
		if(dataListCommodity.length !== 0){
			let listTicker = dataListCommodity[0].ticker
			for (let i = 1; i < dataListCommodity.length; i++) {
				listTicker = listTicker + ',' + dataListCommodity[i].ticker
			}
			getCommodityLogo(listTicker)
		}
	},[dataListCommodity])

	useEffect(()=>{
		if(dataCommodityLogo?.length !== 0){
			let tmp = []
			dataListCommodity.map((el,index)=>{
				tmp.push({
					name: el.ticker_viet_name,
					ticker: el.ticker,
					close: el.close,
					pct_change: el.pct_change,
					price_change: el.price_change,
					url: el.logo_url,
				})
			})

			setDataPanelCom(tmp)
		}
	},[dataCommodityLogo])

	useEffect(()=>{
		if(dataListIndex.length !== 0){
			let tmp = []
			dataListIndex.map((el,index)=>{
				tmp.push({
					name: el.name,
					ticker: el.ticker,
					close: el.close || 0,
					pct_change: el.pct_change || 0,
					index_change: el.index_change || 0,
					url: el.logo_url
				})
			})
			setDataPanelIndex(tmp)
		}
	},[dataListIndex])

	useEffect(()=>{
		getListCommodity()
		getListIndex()
	},[])

	return(
		<div className="pb-20 market-chart MarketChart w-full">
			<div className="font-extrabold text-[32px] text-title-color">Thị trường</div>
			<div className="flex my-5">
				<div className="cursor-pointer rounded text-sm py-2 px-4 mr-4 border-[1px] border-solid border-color-border-1" style={{
					backgroundColor: menu === 'chiso' ? '#1AAF76' : 'white',
					color: menu === 'chiso' ? 'white' : '#344054',
				}} onClick={(e)=>{
					setMenu('chiso')
					}}>Chỉ số</div>
				<div className="cursor-pointer rounded text-sm py-2 px-4 mr-4 border-[1px] border-solid border-color-border-1" style={{
					backgroundColor: menu === 'hanghoa' ? '#1AAF76' : 'white',
					color: menu === 'hanghoa' ? 'white' : '#344054',
				}} onClick={(e)=>{
					setMenu('hanghoa')
					}}>Hàng hóa</div>
			</div>
			<div className="flex justify-between mt-10 h-[423px] w-full layout">
				<div className={` mr-6 w-3/4 layout-item-1`}>
					<div className="w-full h-[423px]" 
          >
						<div className="flex text-lg mb-4">
							<ModeActions listMode={listTime} onChangeMode={(el)=>setcurrentTime(el)}/>
						</div>
						<div className={`flex h-full w-full flex-col`}>
							<div className={`flex flex-auto w-full
								${
									menu==='hanghoa'?"":"hidden"
								}
							`}>
								<Line height={353} data={data} options={{	
									responsive: true,
									maintainAspectRatio: false,
									interaction: {
										mode: 'index',
										intersect: false,
									},
									stacked: false,
									plugins: {
										title: {
											display: false,
										},
									},
									scales: {
										y: {
											type: 'linear',
											display: true,
											position: 'left',
											ticks: {
												callback: (value) => value.toFixed(1) + ' $' // Add your desired unit here
											}
										},
										y1: {
											type: 'linear',
											display: true,
											position: 'right',
											ticks: {
												callback: (value) => value/1000 + 'K' // Add your desired unit here
											},
											// grid line settings
											grid: {
												drawOnChartArea: false, // only want the grid lines for one axis to show up
											},
										},
									}
								}}/>
							</div>
							<div className={`w-full
								${
									menu==='chiso'? "" :"hidden"
								}
							`}>
								<CandlestickChart data={dataIndexHistory} ticker={currentIndex}/>
							</div>
						</div>
					</div>
				</div>
				<div className="w-1/4 layout-item-2">
					<StockPanel 
					listStock={dataPanel}
					logoCom={dataCommodityLogo}
					changeSelect={(el)=>{changeSelect(el)}} 
					mode={menu}
					listCom={dataPanelCom}
					listIndex={dataPanelIndex}
					changeSelectCom={(el)=>{changeSelectCom(el)}}
					></StockPanel>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return{
		dataStockPrice: state.homePage.dataStockPrice,
		dataStockTop: state.homePage.dataStockTop,
		dataListCommodity: state.homePage.dataListCommodity,
		dataCommodityPrice: state.homePage.dataCommodityPrice,
		dataStockByCommodity: state.homePage.dataStockByCommodity,
		dataCurrentFinanceImformation: state.homePage.dataCurrentFinanceImformation,
		dataIndexHistory: state.homePage.dataIndexHistory,
		dataStockLogo: state.homePage.dataStockLogo,
		dataListIndex: state.homePage.dataListIndex,
		dataIndexLogo: state.homePage.dataIndexLogo,
		dataCommodityLogo: state.homePage.dataCommodityLogo,
		dataStockCommodityPrice: state.homePage.dataStockCommodityPrice,
		isShowMenu: state,
	}
}

export default connect(mapStateToProps, {
	getStockPrice,
	getStockTop,
	getCommodityPriceHistory,
	getListCommodity,
	getStockByCommodity,
	getCurrentFinancaImformation,
	getIndexHistory,
	getStockLogo,
	getListIndex,
	getCommodityLogo,
	getIndexLogo,
	getStockCommodityPrice,
})(MarketChart)
