import { ReactComponent as Search } from '../../assets/svg/landingpage/search.svg';
import './Header.css';
import Logo from '../../assets/png/landingpage/logo.png';
import LogoText from '../../assets/png/landingpage/logoText.png';
import Circle from '../../assets/png/landingpage/circle.png';
import CircleRes from '../../assets/png/landingpage/circleRes.png';
import Item1 from '../../assets/png/landingpage/item1.png';
import Item1Res from '../../assets/png/landingpage/item1Res.png';
import MultipleBoard1 from '../../assets/png/landingpage/MultipleBoard1.png';
import Menu from '../../assets/png/landingpage/Menu.png';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  return (
    <div className="header">
      <div className="relative hidden min-[420px]:flex">
        <div className="absolute">
          <img src={Circle} alt="" />
        </div>
      </div>
      <div className="flex justify-between items-center px-2 xl:px-10 py-3 relative bg-white min-[420px]:bg-transparent z-10">
        <div
          className="flex justify-center items-center cursor-pointer"
          onClick={() => navigate('/dashboard')}
        >
          <div>
            <img src={Logo} alt="" />
          </div>{' '}
          <div className="ml-4">
            <img src={LogoText} alt="" />
          </div>
        </div>
        <div className="flex">
          <a
            href="/"
            className="hidden lg:flex mr-8 xl:mr-16 py-4 menu-item-active"
          >
            {' '}
            Trang chủ{' '}
          </a>
          <a href="/" className="hidden lg:flex mr-8 xl:mr-16 py-4 menu-item">
            {' '}
            Tin tức{' '}
          </a>
          <a href="/" className="hidden lg:flex mr-8 xl:mr-16 py-4 menu-item">
            {' '}
            Tài nguyên{' '}
          </a>
          <a href="/" className="hidden lg:flex mr-8 xl:mr-16 py-4 menu-item">
            {' '}
            Về chúng tôi{' '}
          </a>
          <a href="/" className="hidden lg:flex py-4 menu-item">
            {' '}
            Liên hệ{' '}
          </a>
        </div>
        <div className="flex justify-center items-center">
          <div className="mr-6 lg:mr-11">
            <Search />
          </div>
          <div className="hidden lg:flex btn-login">Đăng nhập</div>
          <div className="lg:hidden mr-5">
            <img src={Menu} alt="" onClick={() => setMenu(!menu)} />
          </div>
          <div
            id="dropdown"
            className={`lg:hidden top-14 right-3 z-20 bg-white divide-y rounded-md shadow absolute ${
              menu ? '' : 'hidden'
            }`}
          >
            <ul
              class="py-2 text-sm text-gray-700 bg-white"
              aria-labelledby="dropdownDefaultButton"
            >
              <li>
                <a
                  href="/"
                  class="text-md block mx-6 py-1 font-bold hover:border-b-2 hover:border-green-500 hover:text-green-500"
                >
                  Trang chủ
                </a>
                <a
                  href="/"
                  class="text-md block mx-6 py-1 font-bold hover:border-b-2 hover:border-green-500 hover:text-green-500"
                >
                  Tin tức
                </a>
                <a
                  href="/"
                  class="text-md block mx-6 py-1 font-bold hover:border-b-2 hover:border-green-500 hover:text-green-500"
                >
                  Tài nguyên
                </a>
                <a
                  href="/"
                  class="text-md block mx-6 py-1 font-bold hover:border-b-2 hover:border-green-500 hover:text-green-500"
                >
                  Về chúng tôi
                </a>
                <a
                  href="/"
                  class="text-md block mx-6 py-1 font-bold hover:border-b-2 hover:border-green-500 hover:text-green-500"
                >
                  Liên hệ
                </a>
                <a
                  href="/"
                  class="text-md block mx-6 py-1 font-bold hover:border-b-2 hover:border-green-500 hover:text-green-500"
                >
                  Đăng ký
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="relative min-[420px]:hidden z-0">
        <div className="absolute -bottom-32">
          <img src={CircleRes} alt="" />
        </div>
      </div>
      <div className="flex justify-center items-center mt-10 md:mt-24 relative z-0">
        <div className="sm:w-3/4 title-green text-center">
          <div className="flex justify-center"> Nhanh chóng phát triển</div>
          <div className="min-[600px]:flex justify-center items-center">
            <div className="text-center">Tài chính của bạn với </div>
            <div className="title-gradient ml-2"> GoStock</div>
          </div>
        </div>
      </div>
      <div className="relative hidden min-[420px]:flex">
        <div className="absolute right-0">
          <img src={Item1} alt="" />
        </div>
      </div>
      <div className="relative min-[420px]:hidden">
        <div className="absolute right-0">
          <img src={Item1Res} alt="" />
        </div>
      </div>
      <div className="flex justify-center items-center font-medium text-base min-[600px]:text-lg md:text-xl xl:text-2xl mt-5 md:mt-10">
        <div className="w-2/3 lg:w-1/2 md:px-12 text-center">
          Đăng ký ngay để bắt đầu sử dụng miễn phí dịch vụ của chúng tôi!
        </div>
      </div>
      <div className="mt-5 md:mt-10 flex justify-center items-center">
        <div className="mx-4 min-[420px]:mx-0 min-[420px]:flex justify-center items-center relative w-full min-[420px]:w-2/3 lg:w-1/2">
          <input
            type="text"
            placeholder="Nhập địa chỉ email của bạn"
            className="search-input"
          />
          <button className="search min-[420px]:absolute start-search">
            Bắt đầu ngay!
          </button>
        </div>
      </div>
      <div className="flex justify-center items-center mt-16 min-[420px]:mt-32 relative mx-2 lg:mx-20 min-[420px]:mb-20 mb-10">
        <div className="">
          <img src={MultipleBoard1} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Header;
