import { useState } from 'react';
import { Button, Popover } from 'antd';
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiTwotoneDelete } from "react-icons/ai";


const PopoverButton = (props) => {
    const [open, setOpen] = useState(false);

    const hide = () => {
        setOpen(false);
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    return (
        <Popover
            content={<a className='flex items-center gap-1' onClick={hide}>
                <AiTwotoneDelete size={20} />
                Xóa bộ lọc
            </a>}
            title={null}
            placement="bottomRight"
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
        >
            <Button className='border-none'><BsThreeDotsVertical /></Button>
        </Popover>
    );
}

export default PopoverButton;