import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { color } from 'chart.js/helpers';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { Chart } from 'react-chartjs-2';
import { getStockMarketPulse } from "../../../../store/reducers/homepage/actions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TreemapController,
  TreemapElement
);

const MarketPulse = (props) => {
  const {data, getStockMarketPulse, } = props
  const [dataMap, setDataMap] = useState([])

  useEffect(()=>{
    getStockMarketPulse(3)
  }, [])

  useEffect(()=>{
    let tmpData = []
    if(data.length !== 0){
      data.map(el=>{
        el.data.map(el1 => {
          el1.name = el.vietnamese_name
          tmpData.push(el1)
        })
      })
    }
    setDataMap(tmpData)
  }, [data])

  const config = {
    type: 'treemap',
    data: {
      datasets: [
        {
          label: 'My treemap dataset',
          tree: dataMap,
          key: 'total_value',
          groups: ['name', 'ticker'],
          borderColor: 'transparent',
          borderWidth: 1,
          spacing: 2,
          backgroundColor: (ctx) => {
            if(ctx.raw?._data?.children.length > 1){
              return '#DDDDDD'
            }else{
              if(ctx.raw?._data?.children[0]?.price_change > 0){
                return '#1AAF76'
              }else if(ctx.raw?._data?.children[0]?.price_change < 0){
                return '#D72F2F'
              }else{
                return '#FFFF33'
              }
            }
          },
          captions: {
            display: true,
            color: 'black'
          },
          labels:{
            display: true,
            align: 'center',
            position: 'center',
            formatter: (ctx) =>{
              return ctx.raw.g
            }
          }
        },
      ],
      labels: {
        display: false,
        formatter: (ctx) => 'Kmq ' + ctx.raw.v
      }
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: false,
          text: 'My treemap chart'
        },
        legend: {
          display: false
        },
        tooltip:{
          callbacks: {
            title: function(ctx) {
              let title = ctx.pop()
              return title?.raw.g
            },
            label: function(ctx, xx) {
              let item = ctx?.raw?._data?.children.find((el) => el.ticker === this.title[0])
              
              let close = item.close;
              let unit = item.unit;
              let volume = item.volume;
              let change = item.price_change;
              return [
                `Giá: ${close} ${unit}`,
                `Khối lượng GD: ${volume} Triệu cổ`,
                `Thay đổi: ${change} %`,
              ]
            }, 
          },
        }
      }
    }
  };

  return(
    <div className="flex w-full">
      <div className="w-full">
        <div className="h-[450px]">
          <Chart type="treemap" data={config.data} options={config.options}/>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return{
    data: state.homePage.dataStockMarketPulse,
  }
}

export default connect(mapStateToProps, {
  getStockMarketPulse,
})(MarketPulse)