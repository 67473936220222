import './FinancialFigures.css';
import left from '../../assets/png/tools/financial-figures/left.png';
import right from '../../assets/png/tools/financial-figures/right.png';
import left2 from '../../assets/png/tools/financial-figures/left2.png';
import right2 from '../../assets/png/tools/financial-figures/right2.png';
import down from '../../assets/png/tools/financial-figures/down.png';
import download from '../../assets/png/tools/financial-figures/download.png';
import { useEffect, useReducer, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { cdktGetReportData } from '../../common/api/finance';

const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_REQUEST':
            return { ...state, loading: true };
        case 'FETCH_SUCCESS':
            return {
                ...state,
                series: action.payload,
                loading: false,
            };
        case 'FETCH_FAIL':
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

const FinancialFigures = () => {
    const [{ loading, series }, dispatch] = useReducer(reducer, {
        loading: true,
        error: '',
    });
    const [tickerDefault] = useOutletContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const ticker = searchParams.get('ticker') ?? tickerDefault;
    const [tab, setTab] = useState('kqkd');
    const [type, setType] = useState('quarter');
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Tháng bắt đầu từ 0
    const [currentQuater, setCurrentQuater] = useState(Math.ceil(currentMonth / 3) - 1);
    const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await cdktGetReportData(ticker, tab, currentYear, currentQuater, type);
                dispatch({
                    type: 'FETCH_SUCCESS',
                    payload: res.data,
                });
            } catch (err) { }
        };
        fetchData();
    }, [ticker, tab, currentYear, currentQuater, type]);

    const hiddenChild = (e, className) => {
        if (e && e.currentTarget) {
            const parentDiv = e.currentTarget.parentElement.parentElement;
            const level2Elements = parentDiv.querySelectorAll(className);
            level2Elements.forEach((element) => {
                if (!element.classList.contains('hidden')) {
                    element.classList.add('hidden');
                    element.classList.remove('bgStyle');
                } else {
                    element.classList.remove('hidden');
                    element.classList.remove('bgStyle');
                }
            });
        }
    };

    const handleAddYear = () => {
        if (currentYear < currentDate.getFullYear()) {
            setCurrentYear(currentYear + 1);
            setCurrentQuater(4);
        }
        if (currentYear === currentDate.getFullYear()) {
            setCurrentQuater(Math.ceil(currentMonth / 3) - 1);
        }
    }

    const handleSubYear = () => {
        if (currentYear > 2005) {
            setCurrentQuater(4);
            setCurrentYear(currentYear - 1);
        }
        if (currentYear === currentDate.getFullYear()) {
            setCurrentQuater(Math.ceil(currentMonth / 3) - 1);
        }
    }

    return (
        <div className="w-full text-sm">
            <div className='xl:flex xl:justify-between'>
                <div className='flex items-center justify-center mb-4 xl:mb-0'>
                    <div className={`${currentYear <= 2005 ? "cursor-not-allowed" : "cursor-pointer"} flex h-6`}>
                        <img src={left2} alt="" onClick={handleSubYear} />
                        <img src={left} className='pl-4' alt="" />
                    </div>
                    <div className='flex justify-center items-center mx-4 text-base font-semibold'>Q1-{currentYear - 2} - Q{currentQuater}-{currentYear}</div>
                    <div className={`${currentYear >= currentDate.getFullYear() ? "cursor-not-allowed" : "cursor-pointer"} flex h-6`}>
                        <img src={right} alt="" />
                        <img src={right2} className='pl-4' alt="" onClick={handleAddYear} />
                    </div>
                </div>
                <div className='flex flex-wrap'>
                    <div className={`${tab === 'cdkt' ? 'bg-[#1AAF76] text-white' : 'bg-white border'} mt-4 text-center w-36 flex justify-center p-3 rounded font-semibold mr-4 items-center cursor-pointer`} onClick={() => setTab('cdkt')}>
                        Cân đối kế toán
                    </div>
                    <div className={`${tab === 'lctt' ? 'bg-[#1AAF76] text-white' : 'bg-white border'} mt-4 text-center w-40 flex justify-center p-3 rounded font-semibold mr-4 items-center cursor-pointer`} onClick={() => setTab('lctt')}>
                        Lưu chuyển tiền tệ
                    </div>
                    <div className={`${tab === 'kqkd' ? 'bg-[#1AAF76] text-white' : 'bg-white border'} mt-4 text-center w-40 flex justify-center p-3 rounded font-semibold mr-4 items-center cursor-pointer`} onClick={() => setTab('kqkd')}>
                        Kết quả kinh doanh
                    </div>
                    {/* <div className='w-28 border flex justify-between py-2 px-4 rounded bg-white font-semibold mr-4 items-center cursor-pointer'>
                        <div>Tỷ đồng</div>
                        <div><img src={down} alt="" /></div>
                    </div> */}
                    <div className='w-28 border flex justify-between py-2 px-4 rounded bg-white font-semibold mt-4 mr-4 items-center cursor-pointer'>
                        <select 
                            className='appearance-none w-full h-full outline-none border-0'
                            // onChange={(e) => setType(e.target.value)}
                        >
                            <option value="quater">Quý</option>
                            <option value="year">Năm</option>
                        </select>
                        <div><img src={down} alt="" /></div>
                    </div>
                    <div className='w-36 flex justify-between py-2 px-4 rounded bg-[#1AAF76] text-white mt-4 font-semibold items-center cursor-pointer'>
                        <div><img src={download} alt="" /></div>
                        <div>Xuất dữ liệu</div>
                    </div>
                </div>
            </div>
            {loading ? 'Loading...' : <div className='mt-8'>
                <div className='w-full mt-4 min-w-[991px] overflow-x-auto'>
                    <div className='flex w-full'>
                        <div className='w-1/4'></div>
                        <div className='flex justify-between w-3/4 pl-6'>
                            {series.col.map((item) => <div className='w-24'>{item}</div>)}
                        </div>
                    </div>
                    <div>
                        {series.data.map((item) =>
                            <div className='my-2'>
                                {item.child && item.child.map((itemChild) =>
                                    <div className='my-2' id='level1'>
                                        <div className='pl-2 flex border-b border-t text-blue-500 py-2 bg-white font-semibold'>
                                            <div className='w-1/4 flex cursor-pointer' onClick={(e) => hiddenChild(e, '#level2')}>
                                                {itemChild.child !== [] && <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.2828 8.62629C13.7709 9.11444 13.7709 9.9059 13.2828 10.3941L8.92259 14.7543C8.39762 15.2792 7.5 14.9074 7.5 14.165L7.5 4.85535C7.5 4.11293 8.39762 3.74112 8.92259 4.26609L13.2828 8.62629Z" fill="#2B7FFF" />
                                                </svg>}
                                                {itemChild.label}
                                            </div>
                                            <div className='flex justify-between w-3/4 pl-4'>
                                                {itemChild.value.map((itemChild1) => <div className='w-24'>{(itemChild1 / 1000000000).toFixed(1)}</div>)}
                                            </div>
                                        </div>
                                        {itemChild.child && itemChild.child.map((itemChild2) =>
                                            <div className='my-2' id='level2'>
                                                <div className='flex pl-8 my-2 font-medium'>
                                                    <div className='w-1/4 flex cursor-pointer' onClick={(e) => hiddenChild(e, '#level3')}>
                                                        {itemChild2.child !== [] && <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.2828 8.62629C13.7709 9.11444 13.7709 9.9059 13.2828 10.3941L8.92259 14.7543C8.39762 15.2792 7.5 14.9074 7.5 14.165L7.5 4.85535C7.5 4.11293 8.39762 3.74112 8.92259 4.26609L13.2828 8.62629Z" fill="#2B7FFF" />
                                                        </svg>}
                                                        {itemChild2.label}
                                                    </div>
                                                    <div className='flex justify-between w-3/4 -pl-8'>
                                                        {itemChild2.value.map((itemChild3) => <div className='w-24'>{(itemChild3 / 1000000000).toFixed(1)}</div>)}
                                                    </div>
                                                </div>
                                                {itemChild2.child && itemChild2.child.map((itemChild4) =>
                                                    <div className='my-2 hidden' id='level3'>
                                                        <div className='flex pl-10 my-2 font-medium'>
                                                            <div className='w-1/4'>{itemChild4.label}</div>
                                                            <div className='flex justify-between w-3/4 -pl-10'>
                                                                {itemChild4.value.map((itemChild5) => <div className='w-24'>{(itemChild5 / 1000000000).toFixed(1)}</div>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className='pl-2 flex border-b border-t text-blue-500 py-2 bg-white font-semibold'><div className='w-1/4'>{item.label}</div>
                                    <div className='flex justify-between w-3/4 pl-4'>
                                        {item.value.map((item) => <div className='w-24'>{(item / 1000000000).toFixed(1)}</div>)}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>}
        </div>
    );
};

export default FinancialFigures;
