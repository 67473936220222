import { useState, useEffect } from "react";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import currency from "currency.js";
import { connect } from "react-redux";
import { getListCommodity } from "../../../../store/reducers/homepage/actions";
import StockItem from "./StockItem";

const StockPanel = (props) => {
  const {getListCommodity,
  dataListCommodity,
  dataListIndex,
} = props
  const {listStock = [], listLogo = [], changeSelect, changeSelectCom, mode, listCom = [], listIndex = []} = props
  const [data, setData] = useState([])
  const [dataCom, setDataCom] = useState([])
  const [selected, setSelected] = useState(0)
  const [selectedCom, setSelectedCom] = useState(0)
  const onSelect = (el,index) => {
    setSelected(index)
    changeSelect(el)
  }

  const onSelectCom = (el,index) => {
    if(mode === 'hanghoa'){
      setSelected(0)
      setSelectedCom(index)
      changeSelectCom(el)
    }else{
      setSelectedCom(index)
      changeSelectCom(el)
    }
  }

  useEffect(()=>{
    if(listIndex?.length !== 0){
      setDataCom(listIndex)
    }
    setSelectedCom(0)
  }, listIndex)

  useEffect(()=>{
    if(mode === 'hanghoa'){
      setSelectedCom(1)
      setSelected(0)
    } else{
      setSelectedCom(0)
    }
  },[mode])

  return(
    <div className="h-full flex flex-col gap-10">
      <div className={`flex flex-col flex-auto
      ${
        mode === 'hanghoa'? 'h-1/2' : 'h-full'
      }
      `}>
        <div className="font-semibold">{mode === 'hanghoa' ? 
        (
          <div className="">Hàng hoá</div>
        ) 
        :
        (
          <div className="">Cổ phiếu</div>
        )}</div>
        {mode === 'hanghoa'?(
          <div className="StockPanel">
            {listCom.map((el,index)=>{
              return(
                <StockItem
                isSelected={index===selectedCom}
                logo={el?.url} 
                name={el?.name} 
                close={el?.close}
                change={el?.price_change}
                pct={el?.pct_change}
                key={index}
                changeSelect={()=>onSelectCom(el,index)}
                />
              )
            })}
          </div>
        ):(
          <div className="StockPanel">
            {listIndex.map((el,index)=>{
              return(
                <StockItem 
                isSelected={index===selectedCom}
                logo={el?.url} 
                name={el?.name} 
                close={el?.close}
                change={el?.index_change}
                pct={el?.pct_change}
                changeSelect={()=>onSelectCom(el,index)}
                key={index}
                />
              )
            })}
          </div>
        )}
      </div>
      <div className={`flex flex-col flex-auto
      ${
        mode === 'hanghoa'? 'h-1/2' : 'hidden'
      }
      `}>
        <div className="font-semibold">Cổ phiếu có liên quan</div>
        {listStock.length === 0 ? (
          <div className="StockPanel"></div>
        ):(

        <div className="StockPanel">
          {listStock.map((el,index)=>{
            return <StockItem 
              isSelected={index===selected}
              name={el?.StockCode}
              logo={el?.url}
              close={el?.ClosePrice}
              change={el?.Change}
              pct={el?.PerChange}
              key={index}
              changeSelect={() => onSelect(el,index)}
            />
            })}
        </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return{
    dataListCommodity: state.homePage.dataListCommodity,
    dataListIndex: state.homePage.dataListIndex,
  }
}

export default connect(mapStateToProps,{
  getListCommodity
})(StockPanel)