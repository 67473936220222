import { useState, useEffect } from "react";
import { connect } from "react-redux";
import ModeActions from "../../../tools/ModeActions";
import VolChart from "../../../recharts/VolChart";
import ValueChart from "../../../recharts/ValueChart";

const TransactionInformation = (props) => {
  const [modeNdttn, setModeNdttn] = useState('1d')
  const [modetd, setModetd] = useState('1d')
  const listMode = ['1d', '1w', '1m']

  useEffect(()=>{
  },[modeNdttn])

  return(
    <div className="w-full Overview">
      <div className="w-full mb-10">
        <div className="flex justify-between items-center mb-6">
          <span className="font-bold text-base">
            Giao dịch của NDTNN
          </span>
          <ModeActions listMode={listMode} onChangeMode={(el)=>setModeNdttn(el)}/>
        </div>
        <div className="flex w-full gap-10 Overview-item">
          <div className="w-1/2">
            <div className="w-full text-center font-medium mb-6">Giá trị giao dịch( tỷ )</div>
            <ValueChart mode={modeNdttn} typeMode={'NDTTN'}/>
          </div>
          <div className="w-1/2">
            <div className="w-full text-center font-medium mb-6">Khối lượng giao dịch( triệu cổ ) </div>
            <VolChart mode={modeNdttn} typeMode={'NDTTN'}/>
          </div>
        </div>
      </div>
      <div className="mb-10">
        <div className="flex justify-between items-center mb-6">
          <span className="font-bold text-base">
            Giao dịch của Tự Doanh
          </span>
          <ModeActions listMode={listMode} onChangeMode={(el)=>setModetd(el)}/>
        </div>
        <div className="flex w-full gap-10 Overview-item">
          <div className="w-1/2">
            <div className="w-full text-center font-medium mb-6">Giá trị giao dịch( tỷ )</div>
            <ValueChart mode={modetd} typeMode={'TD'}/>
          </div>
          <div className="w-1/2">
            <div className="w-full text-center font-medium mb-6">Khối lượng giao dịch( triệu cổ )</div>
            <VolChart mode={modetd} typeMode={'TD'}/>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return{}
}

export default connect(mapStateToProps, {

})(TransactionInformation)