import { useEffect, useReducer, useState } from 'react';
import { historicalBalance, historicalBiz } from '../../../common/api/finance';
import Loading from '../../loading/Loading';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import HistoricalBalance from './HistoricalBalance';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        ret: action.payload[0],
        income: action.payload[1],
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const KeyStats = () => {
  const [{ loading, ret, income }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const [type1, setType1] = useState('year');
  const [type2, setType2] = useState('year');
  const [number1, setNumber1] = useState(5);
  const [number2, setNumber2] = useState(5);
  const [tickerDefault] = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const ticker = searchParams.get('ticker') ?? tickerDefault;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [historicalBizRes, historicalBalanceRes] = await Promise.all([
          historicalBiz(ticker, type1, number1),
          historicalBalance(ticker, type2, number2),
        ]);
        const historicalBizData = historicalBizRes?.data || [];
        const historicalBalanceData = historicalBalanceRes?.data || [];
  
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: [historicalBizData, historicalBalanceData],
        });
      } catch (err) {
      }
    };
  
    fetchData();
  }, [ticker, type1, type2, number1, number2]);
  return (
    <div>
      {loading ? (
        <Loading></Loading>
      ) : (
        <div className="w-full sm:px-4 lg:px-20 xl:px-0">
          <div className="w-full xl:w-2/3">
            <div className="min-[450px]:flex justify-between my-4 sm:my-12">
              <div className="title-chart"> Thống kê chính </div>
            </div>
          </div>
          <div className="xl:flex justify-between">
            <div className="w-full xl:w-1/2 mr-5">
              <div className="sm:flex justify-between mb-3">
                <div className="text-xl font-bold">Kết quả kinh doanh</div>
                <div className="flex">
                  <div
                    className={`border rounded-md px-2 py-1 sm:ml-2 cursor-pointer ${
                      type1 === 'year' ? 'bg-[#1AAF76] text-white' : ''
                    }`}
                    onClick={() => setType1('year')}
                  >
                    Năm
                  </div>
                  <div
                    className={`border rounded-md px-2 py-1 ml-2 cursor-pointer ${
                      type1 === 'quarter' ? 'bg-[#1AAF76] text-white' : ''
                    }`}
                    onClick={() => setType1('quarter')}
                  >
                    Quý
                  </div>
                  <div className="ml-2 flex">
                    <div className='w-32 flex justify-center items-center'>{type1 === 'year' ? 'Chọn số năm' : 'Chọn số quý'}:</div>
                    <select
                      id="countries"
                      onChange={(e) => setNumber1(e.target.value)}
                      class="bg-gray-50 w-20 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                    >
                      <option value="4">4</option>
                      <option value="5" selected>5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                    </select>
                  </div>
                </div>
              </div>
              <div>
                <HistoricalBalance data={ret} />
              </div>
            </div>
            <div className="w-full xl:w-1/2 xl:ml-5 mt-4 xl:mt-0">
              <div className="sm:flex justify-between mb-3">
                <div className="text-xl font-bold">Cân đối kế toán</div>
                <div className="flex">
                  <div
                    className={`border rounded-md px-2 py-1 sm:ml-2 cursor-pointer ${
                      type2 === 'year' ? 'bg-[#1AAF76] text-white' : ''
                    }`}
                    onClick={() => setType2('year')}
                  >
                    Năm
                  </div>
                  <div
                    className={`border rounded-md px-2 py-1 ml-2 cursor-pointer ${
                      type2 === 'quarter' ? 'bg-[#1AAF76] text-white' : ''
                    }`}
                    onClick={() => setType2('quarter')}
                  >
                    Quý
                  </div>
                  <div className="ml-2 flex">
                    <div className='w-32 flex justify-center items-center'>{type2 === 'year' ? 'Chọn số năm' : 'Chọn số quý'}:</div>
                    <select
                      id="countries"
                      onChange={(e) => setNumber2(e.target.value)}
                      class="bg-gray-50 w-16 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                    >
                      <option value="4">4</option>
                      <option value="5" selected>5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                    </select>
                  </div>
                </div>
              </div>
              <div>
                <HistoricalBalance data={income} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default KeyStats;
