import { useEffect, useReducer, useState } from 'react';
import { analyzeMarket, analyzeStockNewsDetail, analyzeStockNewsList, analyzeStockTechnical, getStockFundamentalInfo, analyzeStock } from '../common/api/chat';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MessageIcon from '@mui/icons-material/Message';
import Menu from '../assets/png/landingpage/Menu.png';
import './DefaultPage.css';

const reducer = (state, action) => {
	switch (action.type) {
	  case 'FETCH_REQUEST':
		return { ...state, loadingData: true };
	  case 'FETCH_SUCCESS':
		return {
		  ...state,
		  history: action.payload,
		  loadingData: false,
		};
	  case 'FETCH_FAIL':
		return { ...state, loadingData: false, error: action.payload };
	  default:
		return state;
	}
};

const DefaultPage = () => {
	const [{ history, loadingData }, dispatch] = useReducer(reducer, {
		loadingData: true,
		error: '',
	  });
	  
	const template = [
		'Phân tích cơ bản cổ phiếu',
		'Nhận định thị trường',
		'Phân tích kỹ thuật cổ phiếu',
	];
	const [ticker, setTicker] = useState("");
	const [question, setQuestion] = useState("");
	const [data, setData] = useState([{ type: '', val: '' }]);
	const [loading, setLoading] = useState(false);
	const [type, setType] = useState(1); // 1: phòng thủ, 2: trung bình, 3: tấn công
	const [id, setId] = useState(0);
	const setInput = (e) => {
		const template = document.getElementById('template');
		const input = document.getElementById('inputTicker');
		if (template && input) {
			template.textContent = e;
			setQuestion(e);
			if(e !== 'Nhận định thị trường') {
				input.readOnly = false
				input.placeholder = 'Nhập mã cổ phiếu'
				input.focus();
				setTicker('');
			} else {
				setTicker('ngày hôm nay');
				input.readOnly = true
			}
		}
	}

	useEffect(() => {
		const fetchData = async () => {
		  try {
			const res = await analyzeStockNewsList();
			console.log(res);
			dispatch({ type: 'FETCH_SUCCESS', payload: res });
		  } catch (err) {}
		};
		fetchData();
	  }, [question, id]);

	useEffect(() => {
		const footer = document.getElementById('footer');
		if (footer) {
		footer.style.display = 'none';
		}
	});

	async function send() {
		if (ticker) {
			setLoading(true);
			setData([...data, {type: 'Q', val: question + ' ' + ticker }]);
			var res;
			console.log(question);
			switch (question) {
				case 'Phân tích cơ bản cổ phiếu':
					console.log(1);
					res = await analyzeStock(question + ' ' + ticker);
				  break;
				case 'Nhận định thị trường':
					res = await analyzeStock(question);
				  break;
				case 'Phân tích kỹ thuật cổ phiếu':
					console.log(3);
					res = await analyzeStock(question + ' ' + ticker);
				  break;
				default:
					res = await analyzeStock(question+ ' ' + ticker);
			}
			if(res)
				setData([...data, {type: 'Q', val: question + ' ' + ticker }, {type: 'A', val: res.answer ? res.answer : 'Không tìm thấy thông tin' }]);
			if(!id)
				setId(res.id)
			setLoading(false);
		}
		else {
			alert('Vui lòng nhập mã cổ phiếu')
		}
	}

	async function setIdConversation(id) {
		setId(id);
		const conversation = await analyzeStockNewsDetail(id);
		const newData = [];
		conversation[0].data.forEach(e => {
			newData.push({type: 'Q', val: e.question});
			newData.push({type: 'A', val: e.answer});
		});
		setData(newData);
		setQuestion('');
		setTicker('');
		setInput('');
		closeMenu();
	}

	const newConversation = () => {
		setData([{ type: '', val: '' }]);
		setQuestion('');
		setTicker('');
		setInput('');
		setId(0);
		closeMenu();
	}

	const setMenu = () => {
		const navLeft = document.getElementsByClassName('nav-left')[0];
		const contentRight = document.getElementsByClassName('content-right')[0];
		if (navLeft && contentRight) {
			if (navLeft.style.display === 'none') {
				navLeft.style.display = 'block';
			} else {
				navLeft.style.display = 'none';
			}
		}
	}
	const closeMenu = () => {
		const navLeft = document.getElementsByClassName('nav-left')[0];
		const contentRight = document.getElementsByClassName('content-right')[0];
		const screenWidth = window.innerWidth;
		if (navLeft && contentRight) {
			if (navLeft.style.display === 'block') {
				if(screenWidth < 1280)
					navLeft.style.display = 'none';
			}
		}
	}
	return (
		<div className='relative'>
			<div className='absolute top-2 left-2 xl:hidden'>
				<img src={Menu} alt="" onClick={() => setMenu()}  className='w-7 h-5'/>
			</div>
			<div className='overflow-hidden'>
				{!loadingData &&
					<div className='flex'>
						<div className='w-1/6 bg-[#1AAF76] text-white p-4 nav-left relative'>
							<div className='border-white border rounded-lg p-4 mt-6 cursor-pointer' onClick={newConversation}> 
								<AddCircleOutlineIcon className='mr-2'/> Đặt câu hỏi mới
							</div>
							<div className='my-4'>Lịch sử</div>
							<div className='h-full overflow-y-auto'>
								{history?.slice().reverse().map((e, index) => (
									<div key={index} 
										className={`mt-2 p-4 cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap ${e.id === id ? 'bg-[#169363] rounded-lg' : ''}`}
										onClick={() => setIdConversation(e.id)}
									> 
										<MessageIcon className='mr-2'/> {e.question}
									</div>
								))}
							</div>
						</div>
						<div className='flex w-5/6 flex-row content-right' onClick={() => closeMenu()}>
							<main className='main flex flex-col flex-grow md:ml-0 transition-all duration-150 ease-in'>
								<div className='main-content flex flex-col flex-grow p-4'>
									<div className='content-top first-line:mx-auto overflow-hidden'>
										<div className='h-full overflow-y-auto'>
											<div>
												<h1 className='text-secondaryGreen text-7xl font-black text-center title-logo py-5'>
													GoStock
												</h1>
												<h5 className='text-center font-medium text-2xl text-[#7A7A7A] mt-5 h-[15vh] title-content'>
													Trợ lý phân tích chứng khoán Việt Nam, cập nhật thông tin
													chứng khoán theo thời gian thực. Cánh tay phải đắc lực của
													bạn trên con đường đầu tư{' '}
												</h5>
												<div className='mx-auto text-center w-fit'>
													<div className='font-bold'>Hãy vui lòng chọn khẩu vị đầu tư</div>
													<div className='flex p-2 rounded-lg shadow-lg text-center items-center mt-4 cursor-pointer'>
														<div className={`px-6 py-2 rounded ${type === 1 ? 'bg-[#1AAF76] text-white' : ''}`} onClick={() => setType(1)}>Phòng thủ</div>
														<div className={`px-6 py-2 rounded ${type === 2 ? 'bg-[#1AAF76] text-white' : ''}`} onClick={() => setType(2)}>Trung bình</div>
														<div className={`px-6 py-2 rounded ${type === 3 ? 'bg-[#1AAF76] text-white' : ''}`} onClick={() => setType(3)}>Tấn công</div>
													</div>
												</div>
											</div>
											<div className="max-h-80% mb-20" id="scroll-container">
												<div id="message-list">
													{data.map((e, index) => (
														e.type !== '' && 
														<div key={index} className={`flex ${e.type === 'A' ? 'justify-start' : 'justify-end'} items-center mt-4 max-w-[1300px]`}>
															<div className={`px-4 py-4 border rounded-lg break-words break-keep overflow-hidden ${e.type === 'A' ? 'mr-40 bg-[#f3f2f2] answers' : 'bg-[#1AAF76] text-white'}`}>
																{e.val.split('\n').map((line, i) => (
																	<p key={i}>
																		{line}
																	</p>
																))}
															</div>
														</div>
													))}
												</div>
											</div>
											{loading &&
												<div className='flex mb-6'>
													<div
														className="bg-gray-600 p-1 m-1 w-2 h-2 rounded-full animate-bounce blue-circle"
													></div>
													<div
														className="bg-gray-600 p-1 m-1 w-2 h-2 rounded-full animate-bounce green-circle"
													></div>
													<div
														className="bg-gray-600 p-1 m-1 w-2 h-2 rounded-full animate-bounce red-circle"
													></div>
												</div>
											}
										</div>
									</div>
									<div className='content-botton mx-auto'>
										<div className='my-4'>
											<div className='grid grid-cols-3 gap-4'>
												{template &&
													template.map((e, index) => (
														<div
															key={index}
															onClick={() => { setInput(e) }}
															className={`type-ques rounded-lg cursor-pointer border-solid border-2 border-[#1AAF76] text-center p-8 hover:bg-[#1AAF76] hover:text-white ${e === question ? 'bg-[#1AAF76] text-white' : ''}`}
														>
															{e}
														</div>
													))}
											</div>
											<div className='mb-2 rounded border-solid border-1 border-[#7A7A7A] bg-[#F4F4F4] flex justify-between items-center p-2 mt-6'>
												<div className='flex w-5/6 items-center'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='36'
														height='34'
														viewBox='0 0 36 34'
														fill='none'
													>
														<path
															d='M16.1427 0.853612C7.76101 0.853612 0.942749 7.30822 0.942749 15.2429C0.942749 23.1775 7.76101 29.6321 16.1427 29.6321C18.705 29.6321 21.2238 29.0566 23.3518 27.9465C23.5223 28.1407 23.7112 28.3196 23.9164 28.481L28.2592 32.5922C28.6602 33.0194 29.1492 33.3644 29.6962 33.6062C30.2432 33.848 30.8366 33.9814 31.44 33.9982C32.0434 34.015 32.644 33.9149 33.2051 33.704C33.7661 33.4931 34.2757 33.1759 34.7026 32.7718C35.1294 32.3677 35.4645 31.8853 35.6873 31.3541C35.9101 30.823 36.0158 30.2544 35.9981 29.6832C35.9803 29.1119 35.8394 28.5502 35.584 28.0324C35.3286 27.5146 34.9641 27.0517 34.5129 26.6721L30.1701 22.5608C29.9585 22.3605 29.7257 22.1814 29.4752 22.0264C30.6478 20.0119 31.3861 17.6685 31.3861 15.2018C31.3861 7.26711 24.5678 0.8125 16.1861 0.8125L16.1427 0.853612ZM16.1427 4.96483C22.1792 4.96483 26.9998 9.52828 26.9998 15.2429C26.9998 17.9563 25.9575 20.4641 24.1335 22.3142C24.0901 22.3553 24.0467 22.3964 24.0032 22.4375C23.7981 22.5989 23.6091 22.7778 23.4387 22.972C21.5278 24.6164 18.9221 25.562 16.0993 25.562C10.0627 25.562 5.24216 20.9986 5.24216 15.284C5.24216 9.56939 10.0627 5.00594 16.0993 5.00594L16.1427 4.96483Z'
															fill='#7A7A7A'
														/>
													</svg>
													<div className="flex items-start w-full h-full">
														<div className="ml-4 min-w-fit" id="template"></div>
														<input
															className='w-full bg-inherit ml-1 outline-none'
															type='text'
															placeholder='Nhận định thị trường ngày hôm nay'
															id="inputTicker"
															value={ticker}
															
															onChange={(e) => setTicker(e.target.value)}
														/>
													</div>
												</div>
												<button
													className='btn-submit rounded-lg border-solid border-2 bg-[#1AAF76] text-center text-white inline-block px-14 font-semibold py-2'
													onClick={send}
												>
													Gửi
												</button>
											</div>
										</div>
									</div>
								</div>
							</main>
						</div>
					</div>
				}
			</div>
		</div>
	);
};

export default DefaultPage;
