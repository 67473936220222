import React from 'react';
import { Outlet } from 'react-router-dom';

const Analysis = () => {
  return (
    <div className="w-full flex justify-center bg-[#F7FAFF]">
      <div className="w-full max-w-[1390px]">
        {' '}
        <div className="bg-tintMain mx-2 sm:mx-4 lg:mx-20 mb-10">Phân tích</div>
      </div>
    </div>
  );
};

export default Analysis;
