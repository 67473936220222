import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import { connect } from 'react-redux';
import { getStockTop } from '../../store/reducers/homepage/actions';
import Loading from '../loading/Loading';
import CustomizedShape from '../homepage/components/MarketMap/CustomizedShape';

const BarCharts = (props) => {
  const { getStockTop, data } = props
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [max, setMax] = useState(0)
  const [min, setMin] = useState(0)
  useEffect(()=> {
    if(data.length !== 0){
      let tmpMax = (data[0]?.InfluenceIndex + 0.2).toFixed(2)
      setMax((data[0]?.InfluenceIndex + 0.2))
      setMin((data[data.length-1]?.InfluenceIndex - 0.2))
      setDatas(data)
    }
  }, [data])

  useEffect(() => {
    getStockTop()
  }, []);

  return (
    <div id="chart" className='w-full h-[500px] mb-10'>
      {loading ? (
        <Loading />
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={150}
            height={40}
            data={datas}
            margin={
              {
              }
            }
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="StockCode" />
            <YAxis domain={[min, max]} tickFormatter={(param)=>param.toFixed(2)}/>
            <Tooltip content={(value, name, props)=>{
              let tmpPC = ''

              datas.map(el=>{
                if(el.StockCode === value.label){
                  tmpPC = el.PerChange
                }
              })

              return (<div className='bg-white p-2 flex flex-col border-0'>
                <span>{value.label}</span>
                <span>Phần trăm thay đổi: {tmpPC}%</span>
              </div>)
            }} />
            <ReferenceLine y={0} stroke="#000" />
            <Bar dataKey="InfluenceIndex" label={{ position: 'top' }}>
                {data.map((entry,index) =>{
                  let fill = ''
                  if(entry.InfluenceIndex > 0){
                    fill = "#1AAF76"
                  }else{
                    fill = "#D72F2F"
                  }
                  return(  
                    <Cell fill={fill} key={index}/>
                  )
                }
              )}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    data: state.homePage.dataStockTop
  }
}

export default connect(mapStateToProps, {
  getStockTop,
})(BarCharts);
