import React from 'react';
import { TechnicalAnalysis } from 'react-tradingview-embed';

const AnalystRating = (props) => {
    return (
      <div className='w-11/12'>
        <div className='font-bold text-center text-md mb-2'>AnalystRating</div>
        {/* <TechnicalAnalysis
          className='w-full'
          colorTheme="light"
          hide_legend
          hide_top_toolbar
        /> */}
      </div>
    )
}

export default AnalystRating
