import { useState, useEffect } from "react";
import './Tools.css';
const ModeActions = (props) => {
  const {listMode, onChangeMode} = props
  const [mode, setMode] = useState(listMode[0])

  const changeMode = (el) => {
    setMode(el)
    onChangeMode(el)
  }

  return(
    <div className="flex gap-x-2">
      {listMode.map((el,index) => {
        let title = ''
        switch (el) {
          case '1d':
            title='1D'
            break;
        
          case '1w':
            title='1W'
            break;
        
          case '1m':
            title='1M'
            break;
        
          case '3m':
            title='3M'
            break;
          
          case '1y':
            title='1Y'
            break;
        
          case '3y':
            title='3Y'
            break;
          
          case '5y':
            title='5Y'
            break;
          
          case 'YTD':
            title='YTD'
            break;
          
          case 'all':
            title='Tất cả'
            break;
          default:
            break;
        }

        return(
          <div className={`cursor-pointer mode-action font-bold text-sm flex justify-center items-center 
            ${
              el === mode ? 'bg-color-primary !text-white' : 'bg-white'
            }
          `} onClick={() => changeMode(el)} key={index}>{title} </div>
        )
      })}
    </div>
  )
}

export default ModeActions