const initState = {
  logoTopStockIncrease: [],
  logoTopStockDecrease: [],
  logoTopTDBuy: [],
  logoTopTDSell: [],
  logoTopForeignBuy: [],
  logoTopForeignSell: [],
  logoTopFundBuy: [],
  logoTopFundSell: [],
  topPropBuy: [],
  topPropSell: [],
  topFundBuy: [],
  topFundSell: [],
}

const movermentReducer = (state = initState, action) => {

  switch (action.type) {
    case 'FETCH-LOGO-TOP-STOCK-INCREASE':
      return {...state, logoTopStockIncrease: action.payload}
    case 'FETCH-LOGO-TOP-STOCK-DECREASE':
      return {...state, logoTopStockDecrease: action.payload}
    case 'FETCH-LOGO-TOP-TD-BUY':
      return {...state, logoTopTDBuy: action.payload}
    case 'FETCH-LOGO-TOP-TD-SELL':
      return {...state, logoTopTDSell: action.payload}
    case 'FETCH-LOGO-TOP-BUY':
      return {...state, logoTopForeignBuy: action.payload}
    case 'FETCH-LOGO-TOP-SELL':
      return {...state, logoTopForeignSell: action.payload}
    case 'FETCH-LOGO-TOP-FUND-BUY':
      return {...state, logoTopFundBuy: action.payload}
    case 'FETCH-LOGO-TOP-FUND-SELL':
      return {...state, logoTopFundSell: action.payload}
    case 'FETCH-TOP-PROP-BUY':
      return {...state, topPropBuy: action.payload}
    case 'FETCH-TOP-PROP-SELL':
      return {...state, topPropSell: action.payload}
    case 'FETCH-TOP-FUND-BUY':
      return {...state, topFundBuy: action.payload}
    case 'FETCH-TOP-FUND-SELL':
      return {...state, topFundSell: action.payload}
    default:
      break;
  }

  return state
}

export default movermentReducer