import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getSectorColor, getSectorChangeFromDate } from "../../../../store/reducers/homepage/actions";
import ModeActions from "../../../tools/ModeActions";
import LineCharts2 from "../../../recharts/LineCharts2";
const ProfitRate = (props) => {
  const { getSectorColor, getSectorChangeFromDate } = props
  const [mode, setMode] = useState('3m')
  const listMode = ['1m', '3m', '1y', '3y']

  useEffect(() => {
    getSectorColor()
  }, [])

  useEffect(() => {
    getSectorChangeFromDate(mode, 'all', 2)
  }, [mode])

  return (
    <div className="w-full Profit">
      <div className="font-extrabold text-[24px] text-title-color mb-5">
        Tỉ suất lợi nhuận
      </div>
      <div className="mb-7">
        <div className="flex justify-between mb-5">
          <div className="">
            {/* <RangePicker onChange={onChangeDate} /> */}
          </div>
          <div className="flex">
            <ModeActions listMode={listMode} onChangeMode={(el) => { setMode(el) }} />
            {/* <div className="ml-6 cursor-pointer mode-action text-sm flex justify-center items-center bg-white">
              <div className=""><Customization /></div>
              Tuỳ Chỉnh
            </div> */}
          </div>
        </div>
        <div className="w-full">
          <LineCharts2 />
          {/* <LineCharts /> */}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    data: state.homePage.dataSectorChangeFromDate,
  }
}

export default connect(mapStateToProps, {
  getSectorColor,
  getSectorChangeFromDate,
})(ProfitRate)