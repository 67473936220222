import axios from 'axios';
import { baseURL1, header } from '../const';

const axiosInstance = axios.create({
  baseURL: baseURL1,
	headers: header,
});
export default axiosInstance;

export async function analyzeStock(question) {
  try {
    const res = await axiosInstance.post(`/multi-agent/analyze_stock/?question=${question}`, {
      "question": question
    });
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function getStockFundamentalInfo(symbol, id) {
  try {
    const res = await axiosInstance.get(`/analytics/analyze_stock_fundamental/${symbol}/${id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    });
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function analyzeStockTechnical(symbol, id) {
  try {
    const res = await axiosInstance.get(`/analytics/analyze_stock_technical/${symbol}/${id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    });
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function analyzeStockNewsList() {
  try {
    const res = await axiosInstance.get(`/analytics/analyze_stock_news_list`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    });
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function analyzeStockNewsDetail(id) {
  try {
    const res = await axiosInstance.get(`/analytics/analyze_stock_news_detail/${id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    });
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function analyzeMarket(id) {
  try {
    const res = await axiosInstance.get(`/analytics/analyze_market/${id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    });
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}