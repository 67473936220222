import { useEffect, useState } from 'react';
import icon from '../../assets/png/logo_company.png';
import { ReactComponent as Increase } from '../../assets/svg/homepage/reduce.svg';
import { topGiamGia, topMuaNuocNgoai } from '../../common/api';
import { connect } from 'react-redux';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { 
  getTopStockBuyForeign,
  getTopStockSellForeign ,
} from '../../store/reducers/homepage/actions';
import { getLogoTop } from './actions/actions';
import Loading from '../loading/Loading';
const TopForeign = (props) => {
  const { mode, getTopStockBuyForeign, dataBuy, dataSell, 
    getTopStockSellForeign, 
    getLogoTop,
    logoBuy,
    logoSell,
    type } = props;
  const [data,setData] = useState([])
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState('#1AAF76')
  useEffect(() => {
    getTopStockBuyForeign(mode)
    getTopStockSellForeign(mode)
  }, [mode]);

  useEffect(()=>{
    if(dataBuy?.length !== 0){
      let listCode = dataBuy[0]?.StockCode
      for (let i = 1; i < dataBuy.length; i++) {
        listCode = listCode + ',' + dataBuy[i]?.StockCode
      }
      getLogoTop(listCode, 'FETCH-LOGO-TOP-BUY')
    }
    if(type === 1){
      setData(dataBuy)
    }
  },[dataBuy])

  useEffect(()=>{
    if(dataSell?.length !== 0){
      let listCode = dataSell[0]?.StockCode
      for (let i = 1; i < dataSell.length; i++) {
        listCode = listCode + ',' + dataSell[i]?.StockCode
      }
      getLogoTop(listCode, 'FETCH-LOGO-TOP-SELL')
    }
    if(type === 2){
      setData(dataSell)
    }
  },[dataSell])

  useEffect(()=>{
    if(type === 1){
      setData(dataBuy)
      setColor('#1AAF76')
    }else{
      setData(dataSell)
      setColor('#D72F2F')
    }
  }, [type]) 

  return (
    <div className="w-full h-full flex flex-col">
      <div className="font-bold text-sm mb-4 flex">
        {type === 1 ? 'Top khối ngoại mua ròng' : "Top khối ngoại bán ròng"}
        {
          data.length !== 0 && <div className="">
            ({moment(data[0].TradingDate,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')})
          </div>
        }
      </div>
      <div className="h-[460px] flex flex-col w-full grow bg-white items overflow-visible rounded-lg p-[16px]">
        <div className="flex justify-between font-medium text-[13px] h-10">
          <div className="">Mã Cổ phiếu</div>
          <div className="">Giá trị giao dịch( tỷ )</div>
        </div>
        <div className="flex justify-between h-full">
          <div className="flex flex-col justify-around py-1 w-1/3 min-w-[80px]">
            {
              data.map((el,index)=>{
                return(
                  <div className="flex items-center" key={index}>
                    <img className="w-8 h-8 mr-2 object-contain" src={type === 1? logoBuy[index] : logoSell[index]} alt="" />
                    <div className="font-bold text-[13px]">
                      {el.StockCode}
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="w-7/12 h-full">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                layout="vertical"
                barCategoryGap={7}
                data={data}
              >
                <XAxis type="number" hide="true"/>
                <YAxis type="category" dataKey="name" hide/>
                <Bar  dataKey= {(el)=>{
                  let data
                  if(type === 1){
                    data = el.DiffAvgVal
                  }else{
                    data = -el.DiffAvgVal
                  }
                  return data
                }} fill={color}
                maxBarSize={20}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="flex flex-col justify-around py-1 flex-auto">
            {
              data.map((el,index)=>{
                let price = el.DiffAvgVal/1000000000
                return(
                  <div className={`flex justify-end font-bold
                    ${
                      type === 1? 'text-[#1AAF76]' : 'text-[#D72F2F]'
                    }
                  `} key={index}>{price.toFixed(2)}</div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return{
    dataBuy: state.homePage.topStockBuyForeign,
    dataSell: state.homePage.topStockSellForeign,
    logoBuy: state.moverment.logoTopForeignBuy,
    logoSell: state.moverment.logoTopForeignSell
  }
}

export default connect(mapStateToProps, {
  getTopStockBuyForeign,
  getTopStockSellForeign,
  getLogoTop,
})(TopForeign)
