import '../Overview.css';
import { useEffect, useReducer, useState } from 'react';
import { stockInformation } from '../../../common/api/tools';
import Loading from '../../../components/loading/Loading';
import ChangePrice from '../../recharts/tools/ChangePrice';
import { useOutletContext, useSearchParams } from 'react-router-dom';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        stockInfo: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const PriceMovements = () => {
  const [{ loading, stockInfo }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  const [modeChangePrice, setModeChangePrice] = useState('all');
  const [tickerDefault] = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const ticker = searchParams.get('ticker') ?? tickerDefault;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await stockInformation(ticker);
        dispatch({ type: 'FETCH_SUCCESS', payload: res.data });
      } catch (err) {}
    };
    fetchData();
  }, [ticker]);

  function formatNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <>
      <div className="w-full xl:flex lg:px-20 xl:px-0">
        <div className="w-full xl:w-2/3">
          <div className="min-[450px]:flex justify-between mb-12">
            <div className="title-chart">Diễn biến giá</div>
            <div className="flex">
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === '1d'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('1d')}
              >
                1D
              </div>
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === '1m'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('1m')}
              >
                1M
              </div>
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === '3m'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('3m')}
              >
                3M
              </div>
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === '6m'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('6m')}
              >
                6M
              </div>
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === '1y'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('1y')}
              >
                1Y
              </div>
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === '3y'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('3y')}
              >
                3Y
              </div>
              <div
                className={`box-time ml-1 cursor-pointer ${
                  modeChangePrice === 'all'
                    ? 'bg-green text-white border-0'
                    : 'bg-white border-gray-100 border'
                } `}
                onClick={() => setModeChangePrice('all')}
              >
                ALL
              </div>
            </div>
          </div>
          {loading ? (
            <Loading></Loading>
          ) : (
            <div className="w-full">
              <ChangePrice timeline={modeChangePrice} context={[ticker]} />
            </div>
          )}
        </div>
        {loading ? (
          <Loading></Loading>
        ) : (
          <div className="w-full xl:w-1/3 mt-6 xl:mt-0 px-4 py-6 box-info-right xl:ml-10">
            <div className="title">Thông tin doanh nghiệp</div>
            <div className="w-full text-ssm">
              <div className="flex w-full mt-4">
                <div className="text-left w-1/3 font-normal">Tên công ty:</div>
                <div className="text-right w-2/3 font-extrabold text-green">
                  {stockInfo[0].company_name}
                </div>
              </div>
              <div className="flex w-full mt-4">
                <div className="text-left w-1/3 font-normal">Ngành cấp 1:</div>
                <div className="text-right w-2/3 text-blue font-medium">
                  {stockInfo[0].sector_level_1}
                </div>
              </div>
              <div className="flex w-full mt-4">
                <div className="text-left w-1/3 font-normal">Ngành cấp 2:</div>
                <div className="text-right w-2/3 text-blue font-medium">
                  {stockInfo[0].sector_level_2}
                </div>
              </div>
              <div className="flex w-full mt-4">
                <div className="text-left w-1/3 font-normal">Ngành cấp 3:</div>
                <div className="text-right w-2/3 text-blue font-medium">
                  {stockInfo[0].sector_level_3}
                </div>
              </div>
              <div className="flex w-full mt-4">
                <div className="text-left w-1/3 font-normal">
                  Sàn giao dịch:
                </div>
                <div className="text-right w-2/3 font-extrabold text-green">
                  {stockInfo[0].exchange}
                </div>
              </div>
              <div className="flex w-full mt-4">
                <div className="text-left w-1/3 font-normal">NGD đầu tiên:</div>
                <div className="text-right w-2/3 font-normal">
                  {stockInfo[0].first_trading_date}
                </div>
              </div>
              <div className="flex w-full mt-4">
                <div className="text-left w-1/3 font-normal">Vốn hóa:</div>
                <div className=" justify-end w-2/3 flex">
                  <div className="font-extrabold text-green">
                    {formatNumber((stockInfo[0].market_capital/1000000000).toFixed(2))}
                  </div>
                  <div className="font-normal ml-1">tỷ đồng</div>
                </div>
              </div>
              <div className="flex w-full mt-4">
                <div className="text-left w-1/2 font-normal">
                  Địa chỉ liên hệ:
                </div>
                <div className="text-right w-1/2 font-normal ml-4">
                  {stockInfo[0].address}
                </div>
              </div>
              <div className="flex w-full mt-4">
                <div className="text-left w-1/3 font-normal">Website:</div>
                <a
                  href={stockInfo[0].website}
                  target="_blank"
                  className="text-right w-2/3 text-blue font-medium"
                  rel="noreferrer"
                >
                  {stockInfo[0].website}
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      {loading ? (
        <Loading></Loading>
      ) : (
        <div className="mt-10">
          <div className="title-chart">Giới thiệu</div>
          <div className="mt-4 sm:mt-6">{stockInfo[0].introduction}</div>
        </div>
      )}
    </>
  );
};

export default PriceMovements;
