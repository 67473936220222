import axios from 'axios';
import { baseURL2 } from '../const';

const axiosInstance = axios.create({
  baseURL: baseURL2 + '/stock',
});
export default axiosInstance;

export async function stockInformation(ticker) {
  try {
    const res = await axiosInstance.get(`/stock_information?ticker=${ticker}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    });
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function currentFinanceInformation(ticker) {
  try {
    const res = await axiosInstance.get(
      `/current_finance_information?tickers=${ticker}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function stockPriceHistory(timeline, ticker, type) {
  try {
    const res = await axiosInstance.get(
      `/stock_price_history?ticker=${ticker}&mode=${timeline}&type=${type}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function dividendHistory(ticker) {
  try {
    const res = await axiosInstance.get(
      `/dividend_history_by_stock?ticker=${ticker}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function stockValuation(ticker) {
  try {
    const res = await axiosInstance.get(`/stock_valuation?ticker=${ticker}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    });
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function historicalPe(ticker) {
  try {
    const res = await axiosInstance.get(
      `/historical_pe?ticker=${ticker}&mode=all`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function historicalPb(ticker) {
  try {
    const res = await axiosInstance.get(
      `/historical_pb?ticker=${ticker}&mode=all`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function historicalIncome(ticker) {
  try {
    const res = await axiosInstance.get(
      `/stock_historical_income?ticker=${ticker}&n_years=5&type=row`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function historicalBiz(ticker, type, number) {
  try {
    const res = await axiosInstance.get(
      `/stock_historical_biz_result?ticker=${ticker}&n_histories=${number}&type_time=${type}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function historicalBalance(ticker, type, number) {
  try {
    const res = await axiosInstance.get(
      `/stock_historical_balance_sheet?ticker=${ticker}&n_histories=${number}&type_time=${type}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function historicalReturn(ticker) {
  try {
    const res = await axiosInstance.get(
      `/stock_historical_return?ticker=${ticker}&n_years=5&type=row`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function propTradingVolume(ticker) {
  try {
    const res = await axiosInstance.get(
      `/prop_trading_volume_by_stock?ticker=${ticker}&nodays=10`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function foreignTradeVolume(ticker) {
  try {
    const res = await axiosInstance.get(
      `/foreign_trading_volume_by_stock?ticker=${ticker}&nodays=10`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function holderTradingVolume(ticker) {
  try {
    const res = await axiosInstance.get(
      `/major_holder_trading_volume_by_stock?ticker=${ticker}&n=10`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function stockLogo(ticker) {
  try {
    const res = await axiosInstance.get(`/stock_logo?tickers=${ticker}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    });
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function relatedStocks(ticker) {
  try {
    const res = await axiosInstance.get(
      `/related_stocks?ticker=${ticker}&k=10`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}
export async function stockOrderStatistics(ticker, date) {
  try {
    const res = date ? await axiosInstance.get(
      `/stock_order_statistics?ticker=${ticker}&date=${date}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    ) : await axiosInstance.get(
      `/stock_order_statistics?ticker=${ticker}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}
export async function stockOrderDetails(ticker, date) {
  try {
    const res = date ? await axiosInstance.get(
      `/stock_order_details?ticker=${ticker}&date=${date}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    ) : await axiosInstance.get(
      `/stock_order_details?ticker=${ticker}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}
