import { callApiget } from "../../../common/fetch"
import { baseURL2 } from '../../../common/const';

// const server_url = process.env.REACT_APP_API_URL
const server_url = baseURL2 + '/'

export const getStockPrice = (ticker, mode) => {
  return dispatch => {
    let url = server_url + `data/stock_price_history`

    const params = new URLSearchParams()
  
    params.append('ticker', ticker)
    params.append('mode', mode)
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-STOCK-PRICE',
          payload: res.data
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}

export const getStockTop = () => {
  return dispatch => {
    let url = server_url + 'analytics/top_influence_stocks'
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, null, headers).then(res => {
      return res
    })
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-STOCK-TOP-INFLUENCE',
          payload: res.data
        })
      })
      .catch((res)=>{
        reject(res)
        console.log("ERROR");
      })
    })
  
    return promise
  }
}

export const getTopStockVolume = (mode) => {
  return dispatch => {
    let url = server_url + 'analytics/top_stock_by_volume/' + mode
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, null, headers).then(res => {
      return res
    })
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-TOP-STOCK-VOLUME',
          payload: res.data,
        })
      })
      .catch(res => {
        reject(res)
        console.log("ERROR");
      })
    })

    return promise
  }
}

export const getTopStockBuyForeign = (mode) => {
  return dispatch => {
    let url = server_url + 'analytics/top_stock_buy_foreign/' + mode
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, null, headers).then(res => {
      return res
    })
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-TOP-STOCK-BUY-FOREIGN',
          payload: res.data,
        })
      })
      .catch(res => {
        reject(res)
        console.log("ERROR");
      })
    })

    return promise
  }
}

export const getTopStockSellForeign = (mode) => {
  return dispatch => {
    let url = server_url + 'analytics/top_stock_sell_foreign/' + mode
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, null, headers).then(res => {
      return res
    })
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-TOP-STOCK-SELL-FOREIGN',
          payload: res.data,
        })
      })
      .catch(res => {
        reject(res)
        console.log("ERROR");
      })
    })

    return promise
  }
}

export const getTopStockIncreaseRate = (mode) => {
  return dispatch => {

    let url = server_url + `analytics/top_stock_by_increase_rate/` + mode

    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, null, headers).then(res => {
      return res
    })
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-TOP-STOCK-INCREASE-RATE',
          payload: res.data
        })
      })
      .catch((res)=>{
        reject(res)
        console.log("ERROR");
      })
    })
  
    return promise
  }
}

export const getTopStockDecreaseRate = (mode) => {
  return dispatch => {
    let url = server_url + 'analytics/top_stock_by_decrease_rate/' + mode
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, null, headers).then(res => {
      return res
    })
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-TOP-STOCK-DECREASE-RATE',
          payload: res.data,
        })
      })
      .catch(res => {
        reject(res)
        console.log("ERROR");
      })
    })

    return promise
  }
}

export const getStockMarketPulse = (level) => {
  return dispatch => {
    let url = server_url + 'analytics/stock_market_pulse'
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const params = new URLSearchParams()
  
    params.append('level', level)

    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-STOCK-MARKET-PULSE',
          payload: res.data,
        })
      })
      .catch(res => {
        reject(res)
        console.log("ERROR");
      })
    })

    return promise
  }
}

export const getForeignTradingResult = (mode) => {
  return dispatch => {
    let url = server_url + 'analytics/foreign_trading_result/' + mode
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }

    const req = callApiget(url, null, headers).then(res => {
      return res
    })
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-FOREIGN-TRADING-RESULT',
          payload: res.data,
        })
      })
      .catch(res => {
        reject(res)
        console.log("ERROR");
      })
    })

    return promise
  }
}

export const getSectorValuation = (mode) => {
  return dispatch => {
    let url = server_url + `sector/sector_valuation`

    const params = new URLSearchParams()
  
    params.append('sector', mode)
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        let tmpData = []
        Object.entries(res.data).map(el => {
          tmpData.push({
            name: el[0],
            value: el[1],
          })
        })
        return dispatch({
          type: 'FETCH-SECTOR-VALUATION',
          payload: tmpData
        })
      })
      .catch((res)=>{
        reject(res)
        console.log("ERROR");
      })
    })
  
    return promise
  }
}

export const getPropTradingresult = (mode) => {
  return dispatch => {
    let url = server_url + 'analytics/prop_trading_result/' + mode
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }

    const req = callApiget(url, null, headers).then(res => {
      return res
    })
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-PROP-TRADING-RESULT',
          payload: res.data,
        })
      })
      .catch(res => {
        reject(res)
        console.log("ERROR");
      })
    })

    return promise
  }
}

export const getSectorColor = () => {
  return dispatch => {
    let url = server_url + 'sector/sector_color'
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, null, headers).then(res => {
      return res
    })
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-SECTOR-COLOR',
          payload: res.data
        })
      })
      .catch((res)=>{
        reject(res)
        console.log("ERROR");
      })
    })
  
    return promise
  }
}

export const getSectorChangeFromDate = (mode, sector_id, level) => {
  return dispatch => {
    let url = server_url + `sector/sector_change_from_date/` + mode

    const params = new URLSearchParams()
  
    params.append('sector_id', sector_id)
    params.append('type', 'line')
    params.append('level', level)
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-SECTOR-CHANGE-FROM-DATE',
          payload: res.data.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}

export const getListIndex = () => {
  return dispatch => {
    let url = server_url + `index/current_indices_info`;
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, null, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-LIST-INDEX',
          payload: res.data.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}

export const getListSector = () => {
  return dispatch => {
    let url = server_url + `sector/list_sectors`

    const params = new URLSearchParams()
  
    params.append('level', 2)
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-LIST-SECTOR',
          payload: res.data.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}

//commodity

export const getListCommodity = () => {
  return dispatch => {
    let url = server_url + `commodity/current_commodity_info`
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, null, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-LIST-COMMODITY',
          payload: res.data.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}

export const getCommodityPriceHistory = (ticker, mode) => {
  return dispatch => {
    let url = server_url + `commodity/commodity_price_history`

    const params = new URLSearchParams()
  
    params.append('ticker', ticker)
    params.append('mode', mode)
    params.append('type', 'line')
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-COMMODITY-PRICE-HISTORY',
          payload: res.data.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}

export const getStockByCommodity = (ticker) => {
  return dispatch => {
    let url = server_url + `commodity/stocks_by_commodity`

    const params = new URLSearchParams()
  
    params.append('ticker', ticker)
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-STOCK-BY-COMMODITY',
          payload: res.data.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}

export const getCurrentFinancaImformation= (listTicker) => {
  return dispatch => {
    let url = server_url + `stock/current_finance_information`

    const params = new URLSearchParams()
  
    params.append('tickers', listTicker)
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-CURRENT-FINANCE-IMFORMATION',
          payload: res.data.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}

//index
export const getIndexHistory = (ticker, mode) => {
  return dispatch => {
    let url = server_url + `index/index_history`

    const params = new URLSearchParams()
  
    params.append('ticker', ticker)
    params.append('mode', mode)
    params.append('type', 'candle')
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-INDEX-HISTORY',
          payload: res.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}

export const getSectorIndexChange = () => {
  return dispatch => {
    let url = server_url + `sector/sector_index_change`

    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, null, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-SECTOR-INDEX-CHANGE',
          payload: res.data.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}


//logo

export const getIndexLogo = (tickers) => {
  return dispatch => {
    let url = server_url + `index/index_logo`

    const params = new URLSearchParams()
  
    params.append('tickers', tickers)
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-INDEX-LOGO',
          payload: res.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}

export const getCommodityLogo = (tickers) => {
  return dispatch => {
    let url = server_url + `commodity/commodity_logo`

    const params = new URLSearchParams()
  
    params.append('tickers', tickers)
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-COMMODITY-LOGO',
          payload: res.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}

export const getStockCommodityPrice = (stock, commodity, mode) => {
  return dispatch => {
    let url = server_url + `stock/stock_commodity_price`

    const params = new URLSearchParams()
  
    params.append('stock_ticker', stock)
    params.append('commodity_ticker', commodity)
    params.append('mode', mode)
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    }
    const req = callApiget(url, params, headers).then(res => {
      return res
    })
    
    const promise = new Promise((resolve, reject) => {
      req
      .then(res => {
        resolve(res)
        return dispatch({
          type: 'FETCH-STOCK-COMMODITY-PRICE',
          payload: res.data.data,
        })
      })
      .catch((res)=>{
        reject(res)
        console.log(res);
      })
    })
  
    return promise
  }
}
