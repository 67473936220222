import React from 'react';
import { Outlet } from 'react-router-dom';

const Suggest = () => {
  return (
    <div className="w-full flex justify-center bg-[#F7FAFF]">
      <div className="w-full max-w-[1390px]">
        {' '}
        <div className="bg-tintMain mx-2 sm:mx-4 lg:mx-20 mb-10">
          Gợi ý
          <div class="has-tooltip">
            <span class="tooltip rounded shadow-lg p-1 bg-gray-100 text-red-500 -mt-8">
              Some Nice Tooltip Text
            </span>
            Custom Position (above)
          </div>
        </div>
      </div>
    </div>
  );
};

export default Suggest;
