import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Header, Footer } from './layout';

const Home = () => {
  const [ticker, setTicker] = useState('VCB');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') navigate('/home', { replace: true });
  }, [location.pathname, navigate]);

  return (
    <div className="w-full">
      <Header setTicker={setTicker} />
      <div className="bg-tintMain">
        <Outlet context={[ticker]} />
      </div>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
