import { useState } from "react";
import { Table } from "antd";

const columns = [
    {
        title: 'Col1',
        dataIndex: 'Col1',
        key: '1'
    },
    {
        title: 'Col2',
        dataIndex: 'Col2',
        key: '2'
    },
    {
        title: 'Col3',
        dataIndex: 'Col3',
        key: '3'
    },
    {
        title: 'Col4',
        dataIndex: 'Col4',
        key: '4'
    },
    {
        title: 'Col5',
        dataIndex: 'Col5',
        key: '5'
    },
    {
        title: 'Col6',
        dataIndex: 'Col6',
        key: '6'
    },
    {
        title: 'Col7',
        dataIndex: 'Col7',
        key: '7'
    },
    {
        title: 'Col8',
        dataIndex: 'Col8',
        key: '8'
    },
    {
        title: 'Col9',
        dataIndex: 'Col9',
        key: '9'
    },
    {
        title: 'Col10',
        dataIndex: 'Col10',
        key: '10'
    },
]

const ResultTable = (props) => {

    const data = [
        {
            key: '1',
            Col1: 'John Brown',
            Col2: 32,
            Col3: 'New York No. 1 Lake Park',
            Col4: ['nice', 'developer'],
        },
    ];

    return (
        <div className=""><Table columns={columns} dataSource={data} /></div>
    )
}

export default ResultTable

