import { createAsyncThunk } from '@reduxjs/toolkit';
import { login, register,loginWithGoogle } from '../../../common/api/auth';
export const registerUser = createAsyncThunk(
	'auth/register',
	async ({ email, password, phoneNumber }, { rejectWithValue }) => {
		const res = await register({ username: email, password, phone: phoneNumber });
        if (res.success) {
			// store user's token in local storage
			localStorage.setItem('userToken', res.data);
			const userInfo = {
				username: email,
			};
			localStorage.setItem('userInfo', JSON.stringify(userInfo));
			return {
				userInfo,
				userToken: res.data,
			};
        }
		// return custom error message from backend if present
		else return rejectWithValue(res.message);
	},
);

export const userLogin = createAsyncThunk(
	'auth/login',
	async ({ email, password }, { rejectWithValue }) => {
		const res = await login({ username: email, password });
		if (res.success) {
			// store user's token in local storage
			localStorage.setItem('userToken', res.data);
			const userInfo = {
				username: email,
			};
			localStorage.setItem('userInfo', JSON.stringify(userInfo));
			return {
				userInfo,
				userToken: res.data,
			};
		} else {
			return rejectWithValue(res.message);
		}
	},
);

export const userLoginWithGoogle = createAsyncThunk(
	'auth/login-with-google',
	async ({ accessToken }, { rejectWithValue }) => {
		const res = await loginWithGoogle(accessToken);
		if (res.success) {
			// store user's token in local storage
			localStorage.setItem('userToken', res.data.token);
			const userInfo = {
				username: res.data.username,
			};
			localStorage.setItem('userInfo', JSON.stringify(userInfo));
			return {
				userInfo,
				userToken: res.data.email,
			};
		} else {
			return rejectWithValue(res.message);
		}
	},
);

export const userLogout = createAsyncThunk('auth/logout', () => {
	localStorage.removeItem('userInfo');
	localStorage.removeItem('userToken');
	return true;
});

