import ReactApexChart from 'react-apexcharts';

const series = [
  {
    name: 'Lợi nhuận khác',
    data: [74, 64, 81, 74, 64, 111, 77, 69, 77, 69, 74, 64, 81, 77, 69],
  },
  {
    name: 'Lợi nhuận khác',
    data: [19, 22, 22, 22, 28, 21, 22, 29, 32, 22, 28, 21, 22, 28, 21],
  },
  {
    name: 'Lợi nhuận khác',
    data: [1, 7, 5, 1, 7, 5, 5, 2.1, 7, 5, 5, 2, 5, 2],
  },
  {
    name: 'Lợi nhuận khác',
    data: [1, 2, 2, 1, 1, 1, 2, 2, 1, 1, 2, 1, 1, 2, 2],
  },
];
const options = {
  colors: ['#2B7FFF', '#EA9127', '#1AAF76', '#ED2858'],
  chart: {
    type: 'bar',
    height: 350,
    stacked: true,
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
  },
  responsive: [
    {
      breakpoint: 480,
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 0,
    },
  },
  xaxis: {
    type: 'datetime',
    categories: [
      '01/01/2011 GMT',
      '01/02/2011 GMT',
      '01/03/2011 GMT',
      '01/04/2011 GMT',
      '01/05/2011 GMT',
      '01/06/2011 GMT',
      '01/07/2011 GMT',
      '01/08/2011 GMT',
      '01/09/2011 GMT',
      '01/10/2011 GMT',
      '01/11/2011 GMT',
      '01/12/2011 GMT',
      '01/13/2011 GMT',
      '01/14/2011 GMT',
    ],
  },

  yaxis: {
    opposite: true,
    labels: {
      formatter: (value) => {
        return value.toFixed(2) + 'T';
      },
    },
  },

  fill: {
    opacity: 1,
  },

  dataLabels: {
    enabled: false,
  },

  legend: {
    fontSize: '14px',
    markers: {
      radius: 50,
    },
    height: 60,
  },
};

const ProfitStructure = () => {
  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={450}
      />
    </div>
  );
};
export default ProfitStructure;
