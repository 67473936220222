import React, { useEffect, useReducer } from 'react';
import ReactApexChart from 'react-apexcharts';
import { stockPriceHistory } from '../../../common/api/tools';
import { useOutletContext, useSearchParams } from 'react-router-dom';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        series: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const ChangePrice = (props) => {
  const [{ loading, series }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  let chartData = [];
  let dates = [];
  const { timeline } = props;
  const [tickerDefault] = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const ticker = searchParams.get('ticker') ?? tickerDefault;
  if (series) {
    for (let i = 0; i < series.length; i++) {
      chartData = [...chartData, series[i].close];
      let d = series[i].tradingdate;
      dates = [...dates, d.replaceAll('-', '/').slice(0, 10)];
    }
  }
  const seriesData = [
    {
      name: 'Giá',
      data: chartData,
    },
  ];
  const options = {
    chart: {
      id: 'area-datetime',
      type: 'area',
      height: 350,
      width: '100%',
      zoom: {
        autoScaleYaxis: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return (val / 1000).toFixed(0) + 'K';
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: dates,
      tickAmount: 6,
      tickPlacement: 'between',
    },
    stroke: {
      color: '#2B7FFF',
      width: 2,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.5,
        stops: [0, 100],
      },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await stockPriceHistory(timeline, ticker, 'line');
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: res.data,
        });
      } catch (err) {}
    };
    fetchData();
  }, [timeline, ticker]);

  return (
    <div id="chart" className="w-full">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div id="chart-timeline" className="w-full">
          <ReactApexChart
            options={options}
            series={seriesData}
            type="area"
            height={350}
          />
        </div>
      )}
    </div>
  );
};
export default ChangePrice;
