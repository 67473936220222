import { ReactComponent as DropDown } from '../../assets/svg/tools/DropDown.svg';
import { ReactComponent as Calendar } from '../../assets/svg/tools/Calendar.svg';
import './FinancialChart.css';
import ProfitStructure from './financial/ProfitStructure';
import AssetStructure from './financial/AssetStructure';
import CashFlowStructure from './financial/CashFlowStructure';

const FinancialChart = () => {
  const data = [
    {
      title: "Tăng trưởng YoY",
      data: [
        {
          title: "Thu nhập lãi thuần",
          data1: 16.85,
          data2: 25.58,
        },
        {
          title: "Lãi thuần từ hoạt động dịch vụ",
          data1: 12.10,
          data2: -7.67,
        },
        {
          title: "Lợi nhuận sau thuế của Cổ đông công ty mẹ",
          data1: 18.79,
          data2: 36.38,
        },
        {
          title: "Tổng tài sản",
          data1: 6.67,
          data2: 28.24,
        },
        {
          title: "Cho vay khách hàng",
          data1: 14.40,
          data2: 19.18,
        },
        {
          title: "Tiền gửi khách hàng",
          data1: 10.00,
          data2: 9.53,
        }
      ]
    },
    {
      title: "Hiệu quả hoạt động",
      data: [
        {
          title: "Biên lãi ròng (NIM) (%)",
          data1: 3.16,
          data2: 3.40,
        },
        {
          title: "Tỷ suất tài sản sinh lãi (YEA) (%)",
          data1: 5.27,
          data2: 5.63,
        },
        {
          title: "Chi phí sử dụng vốn (CoF) (%)",
          data1: 2.30,
          data2: 2.45,
        },
        {
          title: "Tỷ lệ chi phí hoạt động (CIR) (%)",
          data1: 30.98,
          data2: 31.23,
        },
        {
          title: "Biên lợi nhuận ròng (%)",
          data1: 38.68,
          data2: 43.94,
        },
        {
          title: "ROE LTM (%)",
          data1: 21.59,
          data2: 24.21,
        },
        {
          title: "ROA LTM (%)",
          data1: 1.60,
          data2: 1.85,
        }
      ]
    },
    {
      title: "Chỉ số an toàn vốn",
      data: [
        {
          title: "Tổng tài sản / Vốn chủ sở hữu",
          data1: 13.49,
          data2: 13.07,
        },
        {
          title: "Tỷ lệ Cho vay / Huy động (LDR) (%)",
          data1: 76.10,
          data2: 76.27,
        },
      ]
    },
    {
      title: "Chất lượng tài sản",
      data: [
        {
          title: "Tỷ lệ CASA",
          data1: 32.34,
          data2: 32.34,
        },
        {
          title: "Tỷ lệ nợ xấu (%)",
          data1: 0.64,
          data2: 0.68,
        },
        {
          title: "Dự phòng rủi ro tín dụng / Nợ xấu (%)",
          data1: 424.36,
          data2: 317.36,
        },
      ]
    },
  ]
  return (
    <div className="w-full text-sm">
      <div className="xl:flex px-16 lg:px-0">
        <div className="xl:w-3/5 lg:w-5/6">
          <div className="w-full flex justify-between xl:justify-around items-center">
            <div className="px-4 py-3 bg-[#1AAF76] text-white rounded cursor-pointer">
              Tổng hợp
            </div>
            <div className="px-4 py-3 border bg-white rounded cursor-pointer">
              Chỉ số tài chính
            </div>
            <div className="px-4 py-3 border bg-white rounded cursor-pointer">
              Cân đối kế toán
            </div>
            <div className="px-4 py-3 border bg-white rounded cursor-pointer">
              Báo cáo thu nhập
            </div>
            <div className="px-4 py-3 border bg-white rounded cursor-pointer">
              Lưu chuyển tiền tệ
            </div>
          </div>
        </div>
        <div className="xl:pl-12 xl:w-2/5 mt-4 xl:mt-0 lg:w-1/2 w-3/4">
          <div className="flex items-center justify-around">
            <div>Báo cáo</div>
            <div className="w-28">
              <div className="w-24 flex px-4 py-3 border bg-white rounded outline-none items-center cursor-pointer">
                <div className="mr-5">Năm</div>
                <DropDown />
              </div>
            </div>
            <div>Thời gian</div>
            <div className="w-28">
              <div className="w-24 flex px-4 py-3 border bg-white rounded outline-none items-center cursor-pointer">
                <div className="mr-3">2022</div>
                <Calendar />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:flex px-4 sm:px-10 lg:px-0">
        <div className="lg:w-3/5">
          <div>
            <div className="mt-6">
              <div className="title-chart">Cơ cấu lợi nhuận</div>
              <div className="mt-6">
                <ProfitStructure />
              </div>
              <div>
                Xem chi tiết các chỉ tiêu doanh thu, lợi nhuận trong quá khứ
                theo chuỗi thời gian ở mục{' '}
                <a className="text-blue-500 font-medium" href="/">
                  Báo cáo thu nhập.
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-6">
              <div className="title-chart">Cơ cấu dòng tiền</div>
              <div className="flex justify-between items-center lg:w-5/6 mt-4">
                <div className="py-3 px-4 border-b-4 text-green-600 border-green-600">
                  Tất cả
                </div>
                <div className="py-3 px-4">Hoạt động kinh doanh</div>
                <div className="py-3 px-4">Hoạt động đầu tư</div>
                <div className="py-3 px-4">Hoạt động tài chính</div>
              </div>
              <div className="mt-6">
                <AssetStructure />
              </div>
              <div>
                Xem chi tiết các chỉ tiêu doanh thu, lợi nhuận trong quá khứ
                theo chuỗi thời gian ở mục{' '}
                <a className="text-blue-500 font-medium" href="/">
                  Lưu chuyển tiền tệ.
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-6">
              <div className="title-chart">Cơ cấu Tài sản - Nguồn vốn</div>
              <div className="mt-6">
                <AssetStructure />
              </div>
              <div className="text-left">
                Xem chi tiết các chỉ tiêu doanh thu, lợi nhuận trong quá khứ
                theo chuỗi thời gian ở mục{' '}
                <a className="text-blue-500 font-medium" href="/">
                  Bảng cân đối kế toán.
                </a>
              </div>
              <div className="text-left mt-3">
                Chú ý: Cơ cấu tài chính của doanh nghiệp chỉ phản ánh tình trạng
                tài chính tại 1 thời điểm. Để hiểu rõ hơn về chất lượng tài
                chính của doanh nghiệp khi so sánh với các doanh nghiệp khác
                cùng ngành, bạn nên xem thêm mục{' '}
                <a className="text-blue-500 font-medium" href="/">
                  Phân tích.
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:pl-12 lg:w-2/5">
          <div className="p-6 bg-white content-left mt-6">
            <div className="flex font-bold mb-4">
              <div className="font-extrabold text-base w-1/2">
                Chỉ tiêu tài chính
              </div>
              <div className="w-1/4 text-end">2022</div>
              <div className="w-1/4 text-end">2023</div>
            </div>
            {data.map(item => (
              <div className="border-b-2 mb-3">
                <div className="font-medium mb-3">{item.title}</div>
                {item.data.map(e => (
                    <div className="flex font-bold mb-5">
                      <div className="font-normal w-1/2">{e.title}</div>
                      <div className={`w-1/4 text-end ${e.data1 < 0 ? 'text-red-500' : ''}`}>
                        {e.data1}%
                      </div>
                      <div className={`w-1/4 text-end ${e.data2 < 0 ? 'text-red-500' : ''}`}>
                        {e.data2}%
                      </div>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialChart;
