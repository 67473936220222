import { useState } from 'react';
import { Modal, Checkbox } from 'antd';
import { AiOutlinePlus, AiFillSave } from 'react-icons/ai';

const AddConditionModal = (props) => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const listOption = {
        basic: ['Sàn niêm yết', 'Lĩnh vực hoạt động'],
        holder: [
            'Tỷ lệ sở hữu của ban lãnh đạo',
            'Tỷ lệ sở hữu của tổ chức',
            'Tỷ lệ sở hữu của tổ chức nước ngoài',
            'Số lượng cổ đông là tổ chức',
            'Số lượng cổ đông là tổ chức nước ngoài',
        ],
        price: [
            'Giá hiện tai',
            'Sức mạnh giá - RS',
            'Beta',
            'Giá tăng vượt đỉnh',
            'Giá giảm thủng đáy',
            'Thay đổi giá hôm nay (%)',
            'Thay đổi giá 1 tuần (%)',
            'Thay đổi giá 1 tháng (%)',
            'Thay đổi giá 3 tháng (%)',
            'Thay đổi giá 6 tháng (%)',
            'Thay đổi giá 1 năm (%)',
            'Số phiên tăng giá liên tục',
            'Số phiên tăng giá liên tục trước khi đảo chiều giảm',
            'Số phiên giảm giá liên tục',
            'Số phiên giảm giá liên tục trước khi đảo chiều tăng',
        ],
    }

    const modalStyle = {
        header: {
        },
        mask: {
            backdropFilter: 'blur(10px)',
        },
    }

    return (
        <>
            <div className="flex items-center px-4 py-3 gap-2 bg-[#1AAF76] text-white rounded cursor-pointer" onClick={showModal}>
                <AiOutlinePlus size={24} />
                <span className="font-bold">Thêm điều kiện</span>
            </div>
            <Modal title="Thêm điều kiện" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} width={950} styles={modalStyle}>
                <div className="flex flex-col gap-6 pt-6">
                    <div className="">
                        <div className="font-bold py-[10px]">Thông tin cơ bản</div>
                        <div className="grid grid-cols-3 p-4 bg-[#F6FAFF] rounded gap-4">
                            {
                                listOption.basic.map((el, index) => {
                                    return (
                                        <Checkbox key={index} className='flex items-center'> {el} </Checkbox>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="">
                        <div className="font-bold py-[10px]">Thông tin cổ đông</div>
                        <div className="grid grid-cols-3 p-4 bg-[#F6FAFF] rounded gap-4">
                            {
                                listOption.holder.map((el, index) => {
                                    return (
                                        <Checkbox key={index}> {el} </Checkbox>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="">
                        <div className="font-bold py-[10px]">Thống kê giá</div>
                        <div className="grid grid-cols-3 p-4 bg-[#F6FAFF] rounded gap-4">
                            {
                                listOption.price.map((el, index) => {
                                    return (
                                        <Checkbox key={index} className='flex items-center'> {el} </Checkbox>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="flex justify-end gap-4">
                        <div className="px-10 py-3 border cursor-pointer font-bold rounded" onClick={handleCancel} >Hủy</div>
                        <div className="px-4 py-3 border cursor-pointer flex font-bold gap-2 text-white bg-[#1AAF76] rounded" onClick={handleOk}>
                            <AiFillSave size={24} />
                            Lưu lại</div>
                    </div>
                </div>
            </Modal >
        </>
    )
}

export default AddConditionModal;