import { useEffect, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, CircularProgress } from '@mui/material';
import { resetPassword } from '../common/api/auth';

const ResetPassword = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const [loading, setLoading] = useState();
	const [showSuccessMsg, setShowSuccessMsg] = useState(false);
	const [showErrorMsg, setShowErrorMsg] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();
	};

	const disabledAction = useMemo(() => {
		if (password === '' || confirmPassword !== password) return true;
		else return '';
	}, [password, confirmPassword]);

	const handleOnClick = async () => {
		setLoading(true);
        setShowSuccessMsg(false)
        setShowErrorMsg(false)
		const res = await resetPassword({
			access_token: searchParams.get('access_token'),
			new_password: password,
		});
		if (res.success) {
			setShowSuccessMsg(true);
			setLoading(false);
		} else {
			setShowErrorMsg(true);
			setLoading(false);
		}
	};

	return (
		<>
			<div classNameName='w-full flex justify-center bg-[#F7FAFF]'>
				<section className='bg-gray-50 dark:bg-gray-900'>
					<div className='flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-[65vh] lg:py-0'>
						<div className='w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700'>
							<div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
								<h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white'>
									Đặt lại mật khẩu
								</h1>
								<div className='space-y-4 md:space-y-6'>
									{showSuccessMsg && (
										<div>
											<div
												class='bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative'
												role='alert'
											>
												<span class='block sm:inline'>
													Đặt lại mật khẩu thành công!
												</span>
												<p>
													Quay trở lại{' '}
													<a
														href='/home'
														className='cursor-pointer tracking-tighter text-green hover:text-green-500 hover:border-b-2 hover:border-green-400 ml-2 text-bold'
													>
														<span>Trang chủ</span>
													</a>
												</p>
											</div>
										</div>
									)}
									{showErrorMsg && (
										<div
											class='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
											role='alert'
										>
											<span class='block sm:inline'>
												Có lỗi xảy ra. Vui lòng thử lại.
											</span>
										</div>
									)}

									<div className='mb-5'>
										<label
											className='block text-gray-700 text-sm font-bold mb-2'
											for='password'
										>
											Mật khẩu (*)
										</label>
										<div className='relative'>
											<input
												type={
													showPassword
														? 'text'
														: 'password'
												}
												value={password}
												onChange={(e) =>
													setPassword(e.target.value)
												}
												className='text-md block px-3 py-2  rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-green-600 focus:outline-none'
												id='password'
												placeholder='Mật khẩu'
											/>
											<div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5'>
												<IconButton
													aria-label='toggle password visibility'
													onClick={
														handleClickShowPassword
													}
													onMouseDown={
														handleMouseDownPassword
													}
												>
													{showPassword ? (
														<VisibilityOff />
													) : (
														<Visibility />
													)}
												</IconButton>
											</div>
										</div>
									</div>
									<div class='mb-5'>
										<label
											className='block text-gray-700 text-sm font-bold mb-2'
											for='password'
										>
											Xác nhận mật khẩu (*)
										</label>
										<div className='relative'>
											<input
												type={
													showConfirmPassword
														? 'text'
														: 'password'
												}
												value={confirmPassword}
												onChange={(e) =>
													setConfirmPassword(
														e.target.value,
													)
												}
												className='text-md block px-3 py-2  rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-green-600 focus:outline-none'
												id='password'
												placeholder='Xác nhận mật khẩu'
											/>
											<div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5'>
												<IconButton
													aria-label='toggle password visibility'
													onClick={() =>
														setShowConfirmPassword(
															(show) => !show,
														)
													}
													onMouseDown={
														handleMouseDownPassword
													}
												>
													{showConfirmPassword ? (
														<VisibilityOff />
													) : (
														<Visibility />
													)}
												</IconButton>
											</div>
										</div>
									</div>
									<button
										className='text-white bg-app-green p-2 rounded font-bold w-full disabled:bg-gray-300'
										disabled={disabledAction}
										onClick={handleOnClick}
									>
										{loading ? (
											<CircularProgress
												size='16px'
												sx={{
													color: '#ffffff',
												}}
											/>
										) : (
											'Đặt lại mật khẩu'
										)}
									</button>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default ResetPassword;
