import ReactApexChart from 'react-apexcharts';

const PriceDetails = (props) => {
  const { datas } = props;
  const series = [
    {
      name: 'KL mua vào',
      data: Object.values(datas).map(entry => entry.volume_buy),
    },
    {
      name: 'KL bán ra',
      data: Object.values(datas).map(entry => entry.volume_sell),
    },
  ];
  console.log(series);
  const options = {
    colors: ['rgba(26,175,118,0.85)', 'rgba(215,47,47,0.85)', 'gray'],
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
      },
    },
    xaxis: {
      categories: Object.keys(datas).map(entry => entry),
    },
  
    yaxis: {
      opposite: true,
      labels: {
        formatter: (value) => {
          return value.toFixed(0) + ' cp';
        },
      },
    },
  
    fill: {
      opacity: 1,
    },
  
    dataLabels: {
      enabled: false,
    },
  
    legend: {
      fontSize: '14px',
      markers: {
        radius: 50,
      },
      height: 60,
    },
  };
  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={450}
      />
    </div>
  );
};
export default PriceDetails;
