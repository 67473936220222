const initState = {
  sectorColor:[],
  dataStockPrice: [],
  dataStockTop: [],
  topStockVolume: [],
  topStockBuyForeign: [],
  topStockSellForeign: [],
  topStockIncreaseRate: [],
  topStockDecreaseRate: [],
  dataStockMarketPulse: [],
  dataForeignTradingResult: [],
  dataPropTradingResult: [],
  dataSectorValuation: [],
  dataSectorChangeFromDate: [],
  dataListSector: [],
  isShowMenu: false,
  dataListCommodity: [],
  dataCommodityPrice: [],
  dataStockByCommodity: [],
  dataSectorIndexChange: [],
  dataCurrentFinanceImformation: [],
  dataIndexHistory: [],
  dataListIndex: [],
  dataStockLogo: [],
  dataIndexLogo: [],
  dataCommodityLogo: [],
  dataStockCommodityPrice: [],
  dateIndexFromDate: [],
}

const homePageReducer = (state = initState, action) => {

  switch (action.type) {
    case 'FETCH-STOCK-TOP-INFLUENCE':
      return {...state, dataStockTop: action.payload.data}
    case 'FETCH-STOCK-PRICE':
      return {...state, dataStockPrice: action.payload}
    case 'FETCH-TOP-STOCK-VOLUME':
      return {...state, topStockVolume: action.payload.data}
    case 'FETCH-TOP-STOCK-BUY-FOREIGN':
      return {...state, topStockBuyForeign: action.payload.data}
    case 'FETCH-TOP-STOCK-SELL-FOREIGN':
      return {...state, topStockSellForeign: action.payload.data}
    case 'FETCH-TOP-STOCK-INCREASE-RATE':
      return {...state, topStockIncreaseRate: action.payload.data}
    case 'FETCH-TOP-STOCK-DECREASE-RATE':
      return {...state, topStockDecreaseRate: action.payload.data}
    case 'FETCH-STOCK-MARKET-PULSE':
      return {...state, dataStockMarketPulse: action.payload.data}
    case 'FETCH-FOREIGN-TRADING-RESULT':
      return {...state, dataForeignTradingResult: action.payload.data}
    case 'FETCH-PROP-TRADING-RESULT':
      return {...state, dataPropTradingResult: action.payload.data}
    case 'FETCH-SECTOR-VALUATION':
      return {...state, dataSectorValuation: action.payload}
    case 'FETCH-SECTOR-COLOR':
      return {...state, sectorColor: action.payload}
    case 'FETCH-LIST-SECTOR':
      return {...state, dataListSector: action.payload}
    case 'FETCH-SECTOR-CHANGE-FROM-DATE':
      return {...state, dataSectorChangeFromDate: action.payload}
    case 'FETCH-LIST-COMMODITY':
      return{...state, dataListCommodity: action.payload}
    case 'FETCH-COMMODITY-PRICE-HISTORY':
      return{...state, dataCommodityPrice: action.payload}
    case 'FETCH-STOCK-BY-COMMODITY':
      return{...state, dataStockByCommodity: action.payload}
    case 'FETCH-CURRENT-FINANCE-IMFORMATION':
      return{...state, dataCurrentFinanceImformation: action.payload}
    case 'FETCH-LIST-INDEX':
      return{...state, dataListIndex: action.payload}
    case 'FETCH-INDEX-HISTORY':
      return{...state, dataIndexHistory: action.payload}
    case 'FETCH-SECTOR-INDEX-CHANGE':
      return{...state, dataSectorIndexChange: action.payload}
    case 'FETCH-STOCK-LOGO':
      return{...state, dataStockLogo: action.payload}
    case 'FETCH-INDEX-LOGO':
      return{...state, dataIndexLogo: action.payload}
    case 'FETCH-COMMODITY-LOGO':
      return{...state, dataCommodityLogo: action.payload}
    case 'FETCH-STOCK-COMMODITY-PRICE':
      return{...state, dataStockCommodityPrice: action.payload}
    case 'FETCH-INDEX-FROM-DATE':
      return{...state, dateIndexFromDate: action.payload}
    default:
      break;
  }
  return state
}

export default homePageReducer