import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import icon from '../../assets/png/logo_company.png';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Loading from '../loading/Loading';
import { getTopStockBuyFund, getTopStockSellFund, getLogoTop } from './actions/actions';


const TopInvest = (props) => {
  const {mode, dataSell, dataBuy, getTopStockBuyFund, 
    getTopStockSellFund, 
    getLogoTop,
    logoBuy,
    logoSell,
    type} = props
  const [data,setData] = useState([])
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState('#1AAF76')
  // const [type, setType] = useState(1)

  useEffect(() => {
    getTopStockBuyFund(mode)
    getTopStockSellFund(mode)
  }, [mode]);

  useEffect(()=>{
    if(dataBuy?.length !== 0){
      let listCode = dataBuy[0]?.StockCode
      for (let i = 1; i < dataBuy.length; i++) {
        listCode = listCode + ',' + dataBuy[i]?.StockCode
      }
      getLogoTop(listCode, 'FETCH-LOGO-TOP-FUND-BUY')
    }
    if(type === 1){
      setData(dataBuy)
    }
  },[dataBuy])

  useEffect(()=>{
    if(dataSell?.length !== 0){
      let listCode = dataSell[0]?.StockCode
      for (let i = 1; i < dataSell.length; i++) {
        listCode = listCode + ',' + dataSell[i]?.StockCode
      }
      getLogoTop(listCode, 'FETCH-LOGO-TOP-FUND-SELL')
    }
    if(type === 2){
      setData(dataSell)
    }
  },[dataSell])


  useEffect(()=>{
    if(type === 1){
      setData(dataBuy)
      setColor('#1AAF76')
    }else{
      setData(dataSell)
      setColor('#D72F2F')
    }
  }, [type]) 

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex-none font-bold text-sm mb-4">
        {type === 1 ? 'Hoạt dộng quỹ mua ròng' : "Hoạt động quỹ bán ròng"}
      </div>
      <div className="h-[460px] flex flex-col w-full grow bg-white items overflow-visible rounded-lg p-[16px]">
        <div className="flex justify-between font-medium text-[13px] h-10">
          <div className="">Mã Cổ phiếu</div>
          <div className="">Khối lượng giao dịch ( Triệu cổ )</div>
        </div>
        <div className="flex justify-between grow">
          <div className="flex flex-col justify-around py-1 w-1/3 min-w-[80px]">
            {
              data.map((el,index)=>{
                return(
                  <div className="flex items-center" key={index}>
                    <img className="w-8 h-8 mr-2 object-contain" src={type === 1? logoBuy[index] : logoSell[index]} alt="" />
                    <div className="font-bold text-[13px]">
                      {el.StockCode}
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="w-7/12">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                layout="vertical"
                barCategoryGap={5}
                data={data}
              >
                <XAxis type="number" hide="true"/>
                <YAxis type="category" dataKey="name" hide/>
                <Bar  dataKey= {(el)=>{
                  let data
                  if(type === 1){
                    data = el.TotalVolTrade
                  }else{
                    data = -el.TotalVolTrade
                  }
                  return data
                }} fill={color}
                maxBarSize={20}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="flex flex-col justify-around py-1 flex-auto">
            {
              data.map((el,index)=>{
                let price = el.TotalVolTrade/1000000
                return(
                  <div className={`flex justify-end font-bold
                    ${
                      type === 1? 'text-[#1AAF76]' : 'text-[#D72F2F]'
                    }
                  `} key={index}>{price.toFixed(2)}</div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return{
    dataBuy: state.moverment.topFundBuy,
    dataSell: state.moverment.topFundSell,
    logoBuy: state.moverment.logoTopFundBuy,
    logoSell: state.moverment.logoTopFundSell,
  }
}

export default connect(mapStateToProps, {
  getTopStockBuyFund,
  getTopStockSellFund,
  getLogoTop
})(TopInvest)
