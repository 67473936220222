import axios from 'axios';
import { baseURL2 } from '../const';

const axiosInstance = axios.create({
  baseURL: baseURL2,
});

const level = 2;

export async function listSectors() {
  try {
    const res = await axiosInstance.get(`/sector/list_sectors?level=${level}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    });
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function listSectorsByLevel(levels) {
  try {
    const res = await axiosInstance.get(`/sector/list_sectors?level=${levels}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    });
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function sectorChange({ day }) {
  try {
    const res = await axiosInstance.get(
      `/index/sector_change_from_date?sector=all&from_date=${day}&level=${level}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function sectorIndex({ day }) {
  try {
    const res = await axiosInstance.get(
      `/index/sector_change_by_date?sector=all&dt=${day}&level=${level}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function sectorColor() {
  try {
    const res = await axiosInstance.get(`/sector/sector_color`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    });
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}

export async function getSectorByCompany(ticker) {
  try {
    const res = await axiosInstance.get(
      `/sector/get_sector_by_company?ticker=${ticker}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log('err', error);
  }
}
