import './Header.css';
import Logo from '../../assets/png/landingpage/logo.png';
import LogoText from '../../assets/png/landingpage/logoText.png';
import Ellipse from '../../assets/png/tools/Ellipse.png';
import Menu from '../../assets/png/landingpage/Menu.png';
import { useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { autocompleteSearch } from '../../common/api/search';
import icon from '../../assets/png/logo_company.png';
import AuthDialog from '../auth/AuthDialog';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../../store/reducers/auth/authAction';
import { AiFillFilter } from "react-icons/ai";
import type { MenuProps } from 'antd';
import { Button, Dropdown } from 'antd';

const reducer = (state, action) => {
	switch (action.type) {
		case 'FETCH_REQUEST':
			return { ...state, loading: true };
		case 'FETCH_SUCCESS':
			return {
				...state,
				tickers: action.payload,
				loading: false,
			};
		case 'FETCH_FAIL':
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};

const Header = (props) => {
	const [menu, setMenu] = useState(false);
	const [query, setQuery] = useState('A');
	const [openDialog, setOpenDialog] = useState(false);
	const { setTicker } = props;
	const location = useLocation();
	const { pathname } = location;
	const navigate = useNavigate();

	const [{ loading, error, tickers }, dispatch] = useReducer(reducer, {
		loading: true,
		error: '',
	});

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await autocompleteSearch(query);
				dispatch({ type: 'FETCH_SUCCESS', payload: res.data ?? [] });
			} catch (err) { }
		};
		fetchData();
	}, [query]);

	const toggleModal = (e) => {
		document.getElementById('modal').classList.toggle('hidden');
		document.getElementById('search').classList.toggle('hidden');
		e.preventDefault();
	};

	const items: MenuProps['items'] = [
		{
			key: '1',
			label: (
				<a
					onClick={() => navigate('/filter-tool')}
					className={`hidden lg:flex mr-8 xl:mr-16 py-2 cursor-pointer ${pathname === '/filter-fool'
						? 'menu-item-active'
						: ' menu-item'
						}`}
				>
					<div className="flex">
						<AiFillFilter size={24} color='red' />
						<div className="">
							<div className="font-medium text-[18px]">
								Bộ lọc cổ phiếu
							</div>
							<div className="">
								Tìm kiếm các "siêu" cổ phiếu dự trên nhiều chỉ số và tiêu chí
							</div>
						</div>
					</div>
				</a>
			),
		},
	]
	// const checkPage = () => {
	// 	const currentPath = window.location.pathname;
	// 	return currentPath.startsWith('/tools');
	// };
	const handleSearch = (e, value) => {
		setTicker(value);
		toggleModal(e);
		// if (!checkPage()) {
		// 	navigate('/tools/overview');
		// }
		navigate(`/tools/overview?ticker=${value}`);
	};

	const handleClickOpen = () => {
		setOpenDialog(true);
	};

	const authDispatch = useDispatch()
	const handleLogout = () => {
		authDispatch(userLogout())
	}

	// const userInfo = null

	const { userInfo } = useSelector((state) => state.auth);
	return (
		<>
			<AuthDialog
				open={openDialog}
				handleClose={() => setOpenDialog(false)}
			/>
			<div className='header'>
				{loading ? (
					'Loading...'
				) : (
					<>
						<div className='flex justify-between items-center px-2 xl:px-10 py-3 relative bg-white min-[420px]:bg-transparent z-10 shadown-header'>
							<div
								className='flex justify-center items-center cursor-pointer'
								onClick={() => navigate('/dashboard')}
							>
								<div>
									<img src={Logo} alt='' />
								</div>{' '}
								<div className='ml-4 w-28 sm:w-40'>
									<img src={LogoText} alt='' />
								</div>
							</div>
							<div className='flex'>
								<button
									onClick={() => navigate('/home')}
									className={`hidden lg:flex mr-2 xl:mr-16 py-4 cursor-pointer ${pathname === '/home'
										? 'menu-item-active'
										: ' menu-item'
										}`}
								>
									{' '}
									Home{' '}
								</button>
								<button
									onClick={() => navigate('/tools/overview')}
									className={`hidden lg:flex mr-2 xl:mr-16 py-4 cursor-pointer ${pathname.includes('/tools')
										? 'menu-item-active'
										: ' menu-item'
										}`}
								>
									{' '}
									Phân tích{' '}
								</button>
								<button
									onClick={() => navigate('/chat-app')}
									target='_blank'
									rel='noreferrer'
									className={`hidden lg:flex mr-2 xl:mr-16 py-4 cursor-pointer ${pathname.includes('/chat-app')
										? 'menu-item-active'
										: ' menu-item'
										}`}
								>
									{' '}
									Chatbot GoAI{' '}
								</button>
								<button
									onClick={() => navigate('/candlestick-chart')}
									className={`hidden lg:flex mr-2 xl:mr-16 py-4 cursor-pointer ${pathname === '/candlestick-chart'
										? 'menu-item-active'
										: ' menu-item'
										}`}
								>
									{' '}
									Biểu đồ kỹ thuật {' '}
								</button>
								<Dropdown menu={{ items }} placement="bottomCenter" arrow={{ pointAtCenter: true }} className='cursor-pointer'>
									<div className="flex items-center">Công cụ</div>
								</Dropdown>
							</div>
							<div className='flex justify-center items-center'>
								{/* {checkPage() && ( */}
								<div className='mr-3 lg:mr-11 cursor-pointer flex items-center'>
									<div
										id='search'
										class='mx-auto md:max-w-xl'
										onClick={toggleModal}
									>
										<div class='css-1o7t28p bg-gray-100'>
											<div class='css-3o0h5k'>
												<svg
													class='css-1rxe8v1'
													width='16'
													height='16'
													viewBox='0 0 16 16'
													fill='#999999'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														fill-rule='evenodd'
														clip-rule='evenodd'
														d='M2.4375 6.75C2.4375 4.36827 4.36827 2.4375 6.75 2.4375C9.13173 2.4375 11.0625 4.36827 11.0625 6.75C11.0625 9.13173 9.13173 11.0625 6.75 11.0625C4.36827 11.0625 2.4375 9.13173 2.4375 6.75ZM6.75 1C3.57436 1 1 3.57436 1 6.75C1 9.92564 3.57436 12.5 6.75 12.5C8.06958 12.5 9.28534 12.0555 10.2557 11.308L13.7686 14.8209C14.0347 15.0871 14.5101 15.0433 14.7762 14.7771C15.0424 14.5109 15.0879 14.0339 14.8217 13.7678L11.3087 10.2548C12.0558 9.28459 12.5 8.06918 12.5 6.75C12.5 3.57436 9.92564 1 6.75 1Z'
													></path>
												</svg>
											</div>
											<span className='ml-2 text-gray-400 text-ellipsis hidden md:block'>
												Tìm kiếm mã cổ phiếu hoặc từ
												khóa
											</span>
										</div>
									</div>
								</div>
								{/* )} */}
								{userInfo ? (
									<>
										<div className='hidden sm:flex font-bold mr-4'>
											{userInfo.username}{' '}
										</div>
										{/* <div className='mr-5 xl:mr-0 cursor-pointer'>
											<img src={Ellipse} alt='' />
										</div> */}
										<button className='text-white bg-app-green p-2 rounded font-bold ml-2' onClick={handleLogout}>Đăng xuất</button>
									</>
								) : (
									<>
										<button className='text-white bg-app-green p-2 rounded font-bold text-sm sm:text:base' onClick={handleClickOpen}>Đăng nhập</button>
									</>
								)}
								<div className='ml-2 lg:hidden mr-5'>
									<img
										src={Menu}
										alt=''
										onClick={() => setMenu(!menu)}
									/>
								</div>
								<div
									id='dropdown'
									className={`lg:hidden top-14 right-3 z-20 bg-white divide-y rounded-md shadow absolute ${menu ? '' : 'hidden'
										}`}
								>
									<ul
										class='py-2 text-sm text-gray-700 bg-white'
										aria-labelledby='dropdownDefaultButton'
									>
										<li>
											<button
												onClick={() => navigate('/home')}
												className='text-md block mx-6 py-1 font-bold hover:border-b-2 hover:border-green-500 hover:text-green-500'
											>
												Home
											</button>
											<button
												onClick={() => navigate('/tools/overview')}
												className='text-md block mx-6 py-1 font-bold hover:border-b-2 hover:border-green-500 hover:text-green-500'
											>
												Phân tích
											</button>
											<button
												onClick={() => navigate('/chat-app')}
												className='text-md block mx-6 py-1 font-bold hover:border-b-2 hover:border-green-500 hover:text-green-500'
											>
												Chatbot GoAI
											</button>
											<button
												onClick={() => navigate('/candlestick-chart')}
												className='text-md block mx-6 py-1 font-bold hover:border-b-2 hover:border-green-500 hover:text-green-500'
											>
												Biểu đồ kỹ thuật
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div
							className='fixed z-10 top-0 w-full left-0 hidden'
							id='modal'
						>
							<div className='relative mx-auto w-96 mt-2'>
								<input
									type='search'
									class='peer cursor-pointer relative z-10 h-8 border rounded-full bg-transparent pl-12 outline-none w-full focus:cursor-text focus:border-blue-300 focus:pl-16 pr-4 bg-white'
									onChange={(e) => setQuery(e.target.value)}
									placeholder='Tìm kiếm mã cổ phiếu hoặc từ khóa'
								/>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									class='absolute z-10 inset-y-0 my-auto h-8 w-12 border-r border-transparent stroke-gray-500 px-3.5 peer-focus:border-blue-300 peer-focus:stroke-blue-500'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'
									stroke-width='2'
								>
									<path
										stroke-linecap='round'
										stroke-linejoin='round'
										d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
									/>
								</svg>
							</div>
							<div className='flex items-center justify-center min-height-100vh px-4 text-center sm:block'>
								<div className='fixed inset-0 transition-opacity'>
									<div
										className='absolute inset-0 bg-gray-900 opacity-75'
										onClick={toggleModal}
									/>
								</div>
								<div
									className='inline-block align-center bg-white rounded-lg text-left h-[496px] shadow-xl transform transition-all sm:my-1 sm:align-middle sm:max-w-lg sm:w-96'
									role='dialog'
									aria-modal='true'
									aria-labelledby='modal-headline'
								>
									<div className='gird grid-cols-2 gap-4 w-full overflow-y-auto h-full hover:overflow-auto'>
										{tickers.map((t) => (
											<div className='bg-white m-2 px-4 sm:p-1 cursor-pointer hover:bg-gray-100'>
												<div className='w-full'>
													<div
														className='blocktext-sm font-medium text-gray-900'
														onClick={(e) =>
															handleSearch(
																e,
																t.ticker,
															)
														}
													>
														<div className='flex justify-center items-center'>
															<div className='w-8 h-8 mr-4'>
																<img
																	src={t.logo}
																	alt=''
																/>
															</div>
															<div className='font-bold mr-4'>
																{t.ticker}
															</div>
															<div className='w-5/6'>
																{t.company_name}
															</div>
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default Header;
