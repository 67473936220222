import './Overview.css';
import RRGChart from './RRGChart';
import { useEffect, useReducer, useState } from 'react';
import { Slider } from '@mui/material';
import { listSectorsByLevel } from '../../../../common/api/sector';
import { topSectorsByStatistics, topStocksByStatistics } from '../../../../common/api/search';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        series: action.payload[0],
        topSectors: action.payload[1],
        topStocks: action.payload[2],
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function StockTrend(props) {
  let [{ loading, series, topSectors, topStocks}, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const initialSelectedSectors = [1, 3, 4, 5, 7];
  const [mode, setMode] = useState('daily');
  const [window, setWindow] = useState(5);
  const [timeframe, setTimeframe] = useState(30);
  const [maxTimeframe, setMaxTimeframe] = useState(30);
  const [rrg, setRrg] = useState('');
  const [selectedSectors, setSelectedSectors] = useState(initialSelectedSectors);
  const [hoveredSector, setHoveredSector] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const [seriesRes, topSectorsRes, topStocksRes] = await Promise.all([
          await listSectorsByLevel(1),
          await topSectorsByStatistics(2, 5),
          await topStocksByStatistics(5),
        ]);
        const seriesData = seriesRes.data || [];
        const topSectorsData = topSectorsRes.data || [];
        const topStocksData = topStocksRes.data || [];
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: [seriesData, topSectorsData, topStocksData],
        });
      } catch (err) { }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setRrg(selectedSectors.join('%2C'));
  }, [selectedSectors]);

  const toggleSector = (sectorId) => {
    if (selectedSectors.includes(sectorId)) {
      setSelectedSectors(selectedSectors.filter((id) => id !== sectorId));
    } else {
      setSelectedSectors([...selectedSectors, sectorId]);
    }
  };

  const handleSectorHover = (sectorId) => {
    setHoveredSector(sectorId);
  };

  return (
    <div className="px-4 w-full lg:px-20 xl:px-0 pb-20">
      <div className="w-full xl:w-2/3">
        {/* <div className="min-[450px]:flex justify-between my-12">
          <div className="title-chart"> Xu hướng cổ phiếu </div>
        </div> */}
      </div>
      <div className="md:w-[700px] w-full flex text-sm justify-between">
        <div className="flex border rounded mr-5 h-1/2">
          <div 
            className={`px-8 py-2 flex justify-center items-center cursor-pointer ${ mode === 'daily' ? 'rounded-s border-r filter-active' : ''}`}
            onClick={() => setMode('daily')}  
          >
            Ngày
          </div>
          <div 
            className={`px-8 py-2 flex justify-center items-center cursor-pointer ${ mode === 'weekly' ? 'filter-active' : ''}`}
            onClick={() => setMode('weekly')}
          >
            Tuần
          </div>
          
        </div>
        <div className="pl-5">
          <div>Lịch sử: {timeframe} kỳ</div>
          <div>
            <Slider
              value={timeframe}
              aria-label="Default"
              valueLabelDisplay="auto"
              onChange={(e) => setTimeframe(e.target.value)}
              max={maxTimeframe}
              min={1}
            />
          </div>
        </div>
      </div>
      <div className="2xl:flex justify-between mt-4 overflow-auto">
        <div className='2xl:w-3/4 xl:flex'>
          {loading ? <div className='min-w-[50%]'></div> : <div className='flex'>
            <div class="p-1 w-5 text-center whitespace-nowrap flex flex-col justify-center">
              <span class="block transform -rotate-90 origin-center mt-20">Sức mạnh (S-Strength)</span>
            </div>
            <div className='flex flex-col justify-between mb-10 mr-1 text-center'>
              <div>109</div>
              <div>105</div>
              <div>101</div>
              <div>97</div>
              <div>93</div>
            </div>
            <div>
              <div> <RRGChart rrg={rrg} load={loading} time={timeframe} win={window} setTime={setTimeframe} setMaxTime={setMaxTimeframe} mode={mode}/></div>
              <div className='flex justify-between mt-1 text-center'>
                <div>93</div>
                <div>97</div>
                <div>101</div>
                <div>105</div>
                <div>119</div>
              </div>
              <div className='text-center'>Xu hướng (S-Trend)</div>
            </div>
          </div>}
          <div className="xl:w-1/2 lg:ml-2 my-2 lg:my-0 rounded-md bg-white shadow-md p-2 max-h-[650px]">
            <div className="px-4 py-2">
              <div className="text-base font-extrabold">Nhóm ngành so sánh</div>
            </div>
            {loading ? (
              'Loading...'
            ) : (
              <div className='mb-2'>
                <div className="h-full overflow-x-auto">
                    {series.map((item) => (
                      <div
                        key={item.sector_id}
                        className={`flex justify-between border cursor-pointer rounded p-2 mt-2 ${(selectedSectors.includes(item.sector_id)) ? "bg-[#1AAF76]" : "bg-gray-200"
                          }`}
                        onMouseEnter={() => {
                          handleSectorHover(item.sector_id);
                          const subItem = document.getElementById(`sub-item-${item.sector_id}`);
                          if (subItem) {
                            subItem.classList.remove("hidden");
                          }
                        }}
                        onMouseLeave={() => {
                          handleSectorHover(null);
                          const subItem = document.getElementById(`sub-item-${item.sector_id}`);
                          if (subItem) {
                            subItem.classList.add("hidden");
                          }
                        }}
                        onClick={() => {
                          handleSectorHover(null);
                          toggleSector(item.sector_id);
                        }}
                      >
                        <div className="flex">
                          <div className="font-extrabold text-white mr-4">{item.name}</div>
                          <div
                            id={`sub-item-${item.sector_id}`}
                            className="absolute hidden right-40 bg-white shadow-xl p-3 min-w-[320px] z-50 rounded-md"
                            onMouseEnter={() => handleSectorHover(item.sector_id)}
                            onMouseLeave={() => handleSectorHover(null)}
                          >
                            {item.sub_sectors.map((sub) => (
                              <div
                                key={sub.sector_id}
                                className={`flex justify-between border cursor-pointer rounded p-2 mt-2 ${selectedSectors.includes(sub.sector_id) ? "bg-[#1AAF76]" : ""
                                  }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleSector(sub.sector_id);
                                }}
                              >
                                <div className="flex">
                                  <div className={`font-extrabold ${selectedSectors.includes(sub.sector_id) ? "text-white" : "text-[#1AAF76]"} mr-4`}>
                                    {sub.sector_name}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='w-full 2xl:w-1/4 2xl:ml-4 md:flex 2xl:block'>
          <div className='md:w-1/2 2xl:w-full md:mr-2 2xl:mr-0 rounded-md bg-white shadow-md px-2 py-4 mb-2'>
            <div className='text-md font-medium mb-4'>Top ngành và cổ phiếu mạnh nhất</div>
            <div className='rounded-lg font-medium text-blue-800 bg-blue-100 px-1 w-fit'>Top cổ phiếu mạnh nhất</div>
            <div className='flex text-center mt-2 border-b-2 mb-2 font-bold'>
              <div className='w-1/6 text-left'>Mã</div>
              <div className='w-1/6'>SMG</div>
              <div className='w-1/6'>%D</div>
              <div className='w-2/6'>%W</div>
              <div className='w-1/6'>%M</div>
            </div>
            {topStocks && 
              <div className='text-center'>
                {topStocks.map((item) =>
                  <div className='flex text-center mt-2'>
                    <div className='w-1/6 font-medium mt-1 text-left'>
                      {item.ticker}
                    </div>
                    <div className='w-1/6 flex justify-center'>
                      <div className='w-8 h-8 text-center pt-1 rounded-full border-4 border-green-600 text-green-600 font-extrabold text-[12px] items-center'>{item.rs}</div>
                    </div>
                    <div className={`w-1/6 mt-1 ${item.change_1d > 0 ? 'text-green-500' : (item.change_1d < 0 ? 'text-red-500' : 'text-orange-300')}`}>{item.change_1d}%</div>
                    <div className={`w-2/6 mt-1 ${item.change_1w > 0 ? 'text-green-500' : (item.change_1w < 0 ? 'text-red-500' : 'text-orange-300')}`}>{item.change_1w}%</div>
                    <div className={`w-1/6 mt-1 ${item.change_1m > 0 ? 'text-green-500' : (item.change_1m < 0 ? 'text-red-500' : 'text-orange-300')}`}>{item.change_1m}%</div>
                  </div>
                )}
              </div>
            }
          </div>
          <div className='md:w-1/2 2xl:w-full md:ml-2 2xl:ml-0 rounded-md bg-white shadow-md px-2 py-4 mb-4'>
            <div className='rounded-lg font-medium text-blue-800 bg-blue-100 px-1 w-fit'>Top ngành mạnh nhất</div>
            <div className='flex text-center mt-2 border-b-2 font-bold'>
              <div className='w-1/3 text-left'>Tên ngành</div>
              <div className='w-1/6'>SMG</div>
              <div className='w-1/6'>%D</div>
              <div className='w-1/6'>%W</div>
              <div className='w-1/6'>%M</div>
            </div>
            {topSectors && 
              <div className='text-center'>
                {topSectors.map((item) =>
                  <div className='flex text-center mt-2 mb-2'>
                    <div className="w-1/3 text-left font-medium mt-1 truncate hover:text-clip has-tooltip cursor-pointer">
                      {item.sector_name}
                      {item.sector_name.length > 19 && (
                        <div className="tooltip rounded shadow-lg p-3 bg-gray-100 -mt-8 relative">
                          {item.sector_name}
                        </div>
                      )}
                    </div>
                    <div className='w-1/6 flex justify-center'>
                      <div className='w-8 h-8 text-center pt-1 rounded-full border-4 border-green-600 text-green-600 font-extrabold text-[12px] items-center'>{item.rs}</div>
                    </div>
                    <div className={`w-1/6 mt-1 ${item.change_1d > 0 ? 'text-green-500' : (item.change_1d < 0 ? 'text-red-500' : 'text-orange-300')}`}>{item.change_1d}%</div>
                    <div className={`w-1/6 mt-1 ${item.change_1w > 0 ? 'text-green-500' : (item.change_1w < 0 ? 'text-red-500' : 'text-orange-300')}`}>{item.change_1w}%</div>
                    <div className={`w-1/6 mt-1 ${item.change_1m > 0 ? 'text-green-500' : (item.change_1m < 0 ? 'text-red-500' : 'text-orange-300')}`}>{item.change_1m}%</div>
                  </div>
                )}
              </div>
            }
          </div>
        </div>
        
      </div>
    </div>
  );
}
