import { useState } from 'react';
import { Modal } from 'antd';
import { AiFillStar } from "react-icons/ai";

const modalStyle = {
    header: {
    },
    mask: {
        backdropFilter: 'blur(10px)',
    },
}

const AddToCategoryModal = (props) => {
    
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return(
        <>
            <div className="flex gap-2 px-4 py-3 font-bold rounded cursor-pointer border bg-white" onClick={showModal}>
                <AiFillStar size={24} />
                Thêm vào danh mục
            </div>
            <Modal title="Thêm vào danh mục" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} width={750} styles={modalStyle}>
                <div className="flex flex-col gap-6 pt-6">
                    <div className="">
                        <div className="">CTCP Hàng tiêu dùng Masan</div>
                        <div className="">CTCP Hàng tiêu dùng Masan</div>
                        <div className="">CTCP Hàng tiêu dùng Masan</div>
                        <div className="">CTCP Hàng tiêu dùng Masan</div>
                    </div>
                    <div className="flex justify-between">
                        <button className="border px-4 py-2 rounded font-semibold">
                            Tạo Watchlist mới
                        </button>
                        <div className="flex gap-2">
                            <button className="border px-4 py-2 rounded font-semibold">Hủy</button>
                            <button className="border px-4 py-2 rounded bg-color-primary text-white font-semibold">Thêm vào Watchlist</button>
                        </div>
                    </div>
                </div>
            </Modal >
        </>
    )
}

export default AddToCategoryModal
